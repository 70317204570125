import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';

import { AppConstants } from '../../app.constants';
import * as CryptoJS from 'crypto-js';
import * as Forge from 'node-forge';
// import { JSEncrypt } from 'jsencrypt';
import * as moment from 'moment';
var Buffer = require('buffer/').Buffer; // note: the trailing slash is important!
import * as cryptoB from 'crypto-browserify';

/**
 * @fileOverview This file is responsible to manage encrypt decrypt of requested data.
 */
@Injectable()
export class CryptoProvider {
  $encrypt: any;

  /**
   * @property secretKey for
   */
  private secretKey: string;
  /**
   * This is constructor method automatically invoked at the time of class intialization
   * and set the default properties values.
   */

  ciphertext_tx = '';
  sessionKey = '';
  environment = AppConstants?.ENVIRONMENT;
  url = '/assets/uidai_auth_stage.cer';
  constructor() {
    // this.$encrypt = new JSEncrypt();
    this.secretKey = AppConstants.ENC_KEY;
    // this.adharEncrypt(140857, '6949d887-4186-412b-a300-6s6958a02270').then(res=>console.log(res))
    console.log("env crypto", this.environment)

  }

  async adharEncrypt(otp, uuid) {
    let ts = moment().format('YYYY-MM-DD[T]HH:mm:ss');
    let pid_block =
      '<Pid ts="' + ts + '" ver="2.0" wadh=""><Pv otp="' + otp + '"/></Pid>';
    // console.log(ts);
    console.log("pid block", pid_block);
    //  PID block encryption
    let enc_pid = await this.encrypt_data(pid_block, ts, uuid, false);
    // console.log(enc_pid);

    // Generate hash of pid block
    let pid_hash = this.gethash(pid_block);
    pid_hash = this.CryptJsWordArrayToUint8Array(pid_hash);

    // Encrypt the hash
    let hmac_value = await this.encrypt_data(pid_hash, ts, uuid, true);
    // console.log(hmac_value);

    // Encrypt the session key
    let encrypted_skey = await this.RSA_Encrypt();
    // console.log(encrypted_skey);

    return {
      enc_pid,
      hmac_value,
      encrypted_skey,
      ts,
      sessionKey: this.sessionKey
    }

  }

  sessionByte: any;

  /**
   * Encrypts data
   * @param value
   * @param timestamp
   * @param uuid
   * @param hmac
   * @returns
   */
  async encrypt_data(value, timestamp, uuid, hmac) {
    let UUID = uuid;
    let ts = timestamp; //moment().format('YYYY-MM-DD[T]HH:mm:ss'); //'2022-01-31T12:53:05';
    let password = UUID + ts;
    console.log(password);

    let aadT = ts.slice(ts.length - 16);
    let iv = ts.slice(ts.length - 12);

    const buffer = Buffer.from(password, 'utf-8');
    this.sessionKey = buffer.toString('base64', 0, 32);//'pz8A1k6EHgG7VTB3sGVEArL+Fgev2rxAy3N/x0tYOsU='; //buffer.toString('base64', 0, 32);
    // this.sessionKey = 'pz8A1k6EHgG7VTB3sGVEArL+Fgev2rxAy3N/x0tYOsU=';
    console.log(this.sessionKey);

    const keyArray = Buffer.from(this.sessionKey, 'base64');
    this.sessionByte = keyArray;
    var nonce = new TextEncoder().encode(iv);

    var aad = new TextEncoder().encode(aadT);

    var plaintext = hmac ? value : new TextEncoder().encode(value);

    var keyImported = await await crypto.subtle.importKey(
      'raw',
      keyArray,
      { name: 'AES-GCM' },
      true,
      ['decrypt', 'encrypt']
    );

    var ciphertext = await await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: nonce, additionalData: aad },
      keyImported,
      plaintext
    );

    let ciphertext_tx;

    if (!hmac) {
      var arr = [Buffer.from(ts, 'utf-8'), Buffer.from(ciphertext, 'utf-8')];
      //Join the array into one buffer object:
      var buf = Buffer.concat(arr);
      ciphertext_tx = buf.toString('base64');
    } else ciphertext_tx = Buffer.from(ciphertext, 'utf-8').toString('base64');
    return ciphertext_tx;
  }

  /**
   * Gethashs crypto provider
   * @param value
   * @returns
   */
  gethash(value) {
    let hash = CryptoJS.SHA256(value);
    return hash;
  }

  /**
   * Rsas encrypt
   * @returns
   */
  async RSA_Encrypt() {
    let url = this.environment?.env == 'prod' ? '/assets/uidai_auth_prod.cer' : '/assets/uidai_auth_stage.cer';
    console.log("url", url);
    let file = await fetch(url);
    let fileData = await file.text();

    const certPem = Forge.pki.certificateFromPem(fileData);

    // // convert public key from cert to PEM
    const publicKeyPem = Forge.pki.publicKeyToPem(certPem.publicKey);

    var encrypted = cryptoB.publicEncrypt(
      { key: publicKeyPem, padding: cryptoB.constants.RSA_PKCS1_PADDING },
      this.sessionByte
    );
    return encrypted.toString('base64');
  }



  /**
   * Crypts js word array to uint8 array
   * @param wordArray
   * @returns
   */
  CryptJsWordArrayToUint8Array(wordArray) {
    const l = wordArray.sigBytes;
    const words = wordArray.words;
    const result = new Uint8Array(l);
    var i = 0 /*dst*/,
      j = 0; /*src*/
    while (true) {
      // here i is a multiple of 4
      if (i == l) break;
      var w = words[j++];
      result[i++] = (w & 0xff000000) >>> 24;
      if (i == l) break;
      result[i++] = (w & 0x00ff0000) >>> 16;
      if (i == l) break;
      result[i++] = (w & 0x0000ff00) >>> 8;
      if (i == l) break;
      result[i++] = w & 0x000000ff;
    }
    return result;
  }

  /**
   * This method is responsible for encrypt string data.
   * @param {string} message The string message to encrypt.
   * @return {string} The encrypted data as string.
   */
  encrypt(message: string): string {
    const cipherText: string = CryptoJS.AES.encrypt(message, this.secretKey);
    return cipherText.toString();
  }
  /**
   * This method is is responsible for decrypt of cipher (encrypted) data.
   *  @param {string} cipherMessage The cipher (encrypted) message.
   *  @return {string} The decrypted data as string.
   */
  decrypt(cipherMessage: string): string {
    const bytes = CryptoJS.AES.decrypt(cipherMessage, this.secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  /**
   * This method is responsible for encrypt JSON data.
   * @param message The JSON object as message to encrypt.
   * @return {string} The encrypted data as string.
   */
  encryptObj(message: any): string {
    const cipherText: string = CryptoJS.AES.encrypt(
      JSON.stringify(message),
      this.secretKey
    );
    return cipherText.toString();
  }
  /**
   * This method is is responsible for decrypt of cipher (encrypted) JSON data.
   * @param {string} cipherMessage The cipher (encrypted) message.
   * @return {string} The decrypted data as JSON object.
   */
  decryptObj(cipherMessage: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(cipherMessage, this.secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      // this.router.navigate(['/login']);
    }
  }

  clear(item: string): any {
    localStorage.removeItem(item);
  }

  getKeyMaterial() {
    const password = '6949d887-4186-412b-a300-d87958a022152022-01-11T04:37:05';
    const enc = new TextEncoder();
    return window.crypto.subtle.importKey(
      'raw',
      enc.encode(password),
      'PBKDF2',
      false,
      ['deriveBits', 'deriveKey']
    );
  }

  async encrypts(plaintext, salt, iv) {
    const keyMaterial = await this.getKeyMaterial();
    const key = await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations: 100000,
        hash: 'SHA-256'
      },
      keyMaterial,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );

    const encoder = new TextEncoder();
    console.log(encoder.encode(new Date().toUTCString()));

    const tes = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv
      },
      key,
      encoder.encode('2022-01-11T04:37:05')
    );


    return btoa(String.fromCharCode(...new Uint8Array(tes)));
    // btoa(String.fromCharCode(...new Uint8Array(tes)));
  }

}
