import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CryptoProvider } from '../../services/providers/crypto';

@Injectable()
export class SessionGuard implements CanActivate {
  userInfo: any;
  userInfoStorage: any;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private crypto: CryptoProvider,
    private toaster: ToastrService,
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot) {
    let userInfo,
      userInfoStorage = localStorage.getItem('USER_INFO');
    let userDetails = localStorage.getItem('USER_DETAILS');
    let roles = localStorage.getItem('ROLES');
    let userDetailsDec;
    if (userInfoStorage && userDetails) {
      userInfo = this.crypto.decryptObj(userInfoStorage);
      userDetailsDec = this.crypto.decryptObj(userDetails);
      userDetailsDec['roles'] = this.crypto.decryptObj(roles);
    }
    if (userInfo && userInfo.access_token) {

      // if (route.data.role && route.data.role.indexOf(userDetailsDec.roles[0].name.toLowerCase()) === -1) {
      //   this.toaster.error(`Access Denied you don't have permission to access the page`)
      //   return false
      // }
      return true;
    } else {
      console.log(this.router.url);
      if (this.router.url != '/') {
        this.toaster.error(this.translate.instant('ts_changes.login_token_has_been_expired'), "Error");
      }
      return this.router.createUrlTree(['/login']);
    }
  }
}
