<div class="main-container">
  <div class="login_form forgot-otp">
    <label (click)="goBack()" class="goBack"
      ><img src="assets/images/leftArrow.svg" alt="" class="backArrowImage"
    /></label>
    <div class="logo-container">
      <img src="../../../assets/images/logo.png" alt="logo" />
    </div>
    <hr />
    <h3 class="text-center">{{ "login.header" | translate }}</h3>

    <div class="" *ngIf="!otpVerified">
      <small class="small-head"
        >{{ "otp_send.text" | translate }} {{ loginInput }}</small
      >
      <form [formGroup]="userForm" class="w-100">
        <otp
          class="otpinput"
          [setting]="settings"
          (onValueChange)="onInputChange($event)"
          #otpbox
        ></otp>
        <button
          class="submit-btn"
          mat-raised-button
          color="primary"
          [ngClass]="activateButton ? 'active_btn' : 'disabled_div'"
          (click)="submitOtp(userForm)"
        >
          {{ "common.submit" | translate }}
        </button>
      </form>
    </div>
    <div *ngIf="otpVerified">
      <p class="fs-16">
        {{ "otp_send.text2" | translate }}
      </p>
      <strong
        class="text-blue fs-14 cursor-pointer"
        [routerLink]="['/login']"
        >{{ "otp_send.go_back_to_log_in" | translate }}</strong
      >
    </div>
  </div>
  <div></div>
</div>
