import { DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './account/login/login.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { SetPasswordComponent } from './account/set-password/set-password.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { FirstTimeSigninComponent } from './account/first-time-signin/first-time-signin.component';
import { SecurityQuestionComponent } from './account/security-question/security-question.component';
import { EmailOrNumberComponent } from './account/email-or-number/email-or-number.component';
import { MatRadioModule } from '@angular/material/radio';
import { OtpSendComponent } from './account/otp-send/otp-send.component';
import { AngularOtpLibModule } from 'angular-otp-box';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { TaskService } from './services/apis/task.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DEFAULT_TIMEOUT, HttpConfigInterceptor } from './core/interceptor/httpconfig.interceptor';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule } from 'angular2-toaster';
import { CryptoProvider } from './services/providers/crypto';
import { HeaderProvider } from './services/providers/headers';
import { ToastrService } from './services/providers/toaster';
import { SessionGuard } from './core/guard/auth.guard';

import { ToastrModule } from 'ngx-toastr';
import { LottieModule } from 'ngx-lottie';

import { ComponentsModule } from './components/components.module';
import { AllowExitGuard } from './core/guard/allow-exit.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DynamicPermissionComponent } from './account/dynamic-permission/dynamic-permission.component';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MQTTService } from './services/providers/mqtt.service';
import { MqttModule } from 'ngx-mqtt';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    SetPasswordComponent,
    FirstTimeSigninComponent,
    SecurityQuestionComponent,
    EmailOrNumberComponent,
    OtpSendComponent,
    ForgotPasswordComponent,
    DynamicPermissionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatRadioModule,
    AngularOtpLibModule,
    MatPasswordStrengthModule.forRoot(),
    MatExpansionModule,
    MatDialogModule,
    MatSelectModule,
    MatNativeDateModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),

    NoopAnimationsModule,
    ToasterModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 1,
      preventDuplicates: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MqttModule.forRoot(environment.MQTT_SERVICE_OPTIONS),


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 },
    TaskService,
    CryptoProvider,
    HeaderProvider,
    ToastrService,
    SessionGuard,
    AllowExitGuard,
    MQTTService
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
