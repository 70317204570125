<div class="otp__container p-2">
  <div class="w-100">
    <div class="onboarding_template text-center">
      <div class="close mt-2 pr-3" aria-label="Close" (click)="close()">
        <!-- <span aria-hidden="true">&times;</span> -->
        <img src="assets/images/Close_Square.svg" class="custom-cursor" alt="" mat-dialog-close role="button" />
      </div>

      <div class="page-header popup-sideline-inside m-2">
        <h2 class="modal-title m-0" id="modal-basic-title" mat-dialog-title>
          <span class="fs-20 pl-3">Authentication through OTP</span>
        </h2>
      </div>
    </div>
    <div class="content forTransact mt-4" mat-dialog-content>
      <form class="w-100">
        <div class="for-otp text-center">
          <otp class="otpinput" [setting]="settings" (onValueChange)="onInputChange($event)"></otp>
        </div>
        <div class="hr"></div>
        <div class="text-right justify-content-end" mat-dialog-actions>
          <button class="submit-btn mt-2 m-3 d-flex align-items-center" [ngClass]="[ activateButton ? 'btn-grey' : 'custom-text-blue']"
            mat-raised-button color="primary" [disabled]="activateButton" (click)='confirmAuth()'>
            authenticate
          </button>
        </div>
      </form>
    </div>
    <div class="text-center"></div>
  </div>
</div>
