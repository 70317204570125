import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/app.constants';
import { AnnouncementComponent } from 'src/app/pages/transact/transct-on-behalf-customer/shared/announcement/announcement.component';
import { CrudService } from 'src/app/services/apis/Crud.service';

@Component({
  selector: 'app-authenticate-otp-dialog',
  templateUrl: './authenticate-otp-dialog.component.html',
  styleUrls: ['./authenticate-otp-dialog.component.scss']
})
export class AuthenticateOtpDialogComponent implements OnInit {

  settings = {
    length: 6,
    numbersOnly: true,
    timer: 60,
    timerType: 1
  }
  OTP_value: string;
  activateButton: boolean = true;
  requestID: string = ""

  constructor(public dialogRef: MatDialogRef<AuthenticateOtpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _service: CrudService, private _toaster: ToastrService, 
    private _dialog: MatDialog,) { }

  ngOnInit(): void {
    console.log(this.data)
     this.sendOTP()
  }

  public onInputChange(e) {
    //this.activateButton = false;
    // console.log(e);
    if (e.length == this.settings.length) {
      // e will emit values entered as otp and,
      console.log('otp is', e);
      this.OTP_value = e;
      this.activateButton = false;
    } else if (e == -1) {
      // if e == -1, timer has stopped
      console.log(e, 'resend button enables');
    } else if (e == -2) {
      // e == -2, button click handle
      console.log('Resend code');
      this.resendOTP();
    }
  }

  confirmAuth() {
    // console.log(this.settings,this.OTP_value);
    // this.dialogRef.close(this.OTP_value);
    this.verifyOTP()
  }


  close() {
    this.dialogRef.close()
  }

  verifyOTP() {
    this._service.postData(`${AppConstants.COMMON_PORTS.OTP_v2}${AppConstants.WEB_APP_APIS.OTP.verifyOTP}`, {
      "request_id": this.requestID,
      "otp": this.OTP_value
    }).subscribe(res => {
      if (res.status) {

        this._toaster.success(res.message, AppConstants.TOASTER_STATUS.SUCCESS)
        this.dialogRef.close(this.requestID)
      }
      else {
        this._toaster.error(res.message, AppConstants.TOASTER_STATUS.ERROR)
      }
    }, err => {
      console.log(err)
      // this._toaster.error(err.error.message, AppConstants.TOASTER_STATUS.ERROR)
    })


  }


  resendOTP() {
    this._service.postData(`${AppConstants.COMMON_PORTS.OTP_v2}${AppConstants.WEB_APP_APIS.OTP.resendOTP}`, {
      request_id: this.requestID
    }).subscribe(res => {
      if (res.status) {
        this._toaster.success(res.message, AppConstants.TOASTER_STATUS.SUCCESS)
      } else {
        if (res?.error_status?.error_code && 
            Number(res?.error_status?.error_code) === 30001 ||
            Number(res?.error_status?.error_code) === 30008 ) {
              this._toaster.error(res.message)
        } else if (res?.error_status?.error_code && 
          Number(res?.error_status?.error_code) === 30002 ||
          Number(res?.error_status?.error_code) === 30007 ||
          Number(res?.error_status?.error_code) === 30009 ||
          Number(res?.error_status?.error_code) === 30010 ||
          Number(res?.error_status?.error_code) === 30030 ) {
            this.openInfoDialog('sendOtp');
        } else if (res?.error_status?.error_code && Number(res?.error_status?.error_code) === 30029){
          // to many attempts go back to customers i.e close this dialog
          this.close();
        }
      }
    }, err => {
      console.log(err)
      // this._toaster.error(err.error.message, AppConstants.TOASTER_STATUS.ERROR)
    })
  }

  sendOTP() {
    let data = {
      "user_id": this.data?.user_id,
      "request_purpose": this.data?.request_purpose?this.data?.request_purpose:'transact'
    }
    this._service.postData(`${AppConstants.COMMON_PORTS.USER}${AppConstants.WEB_APP_APIS.USER.sendOTP}`, data).subscribe(res => {
      console.log(res)
      if (res?.status) {
        this.requestID = res.data?.request_id
        this._toaster.success(res.message, AppConstants.TOASTER_STATUS.SUCCESS)
      }
    }, err => {
      console.log(err)
      // this._toaster.error(err.error.message, AppConstants.TOASTER_STATUS.ERROR)
    })

  }

  openInfoDialog(key, event?: any, msg?: any) {
    let header, message, img:any = null, buttonText = null;
    if (key == 'sendOtp') {
      header = 'Oops! Something went wrong';
      message = 'Apologies, looks like we’ve run into an error processing your request. Please try again later while we investigate the issue.';
      buttonText = 'Resend OTP';
    } 
    const dialog = this._dialog.open(AnnouncementComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        header: header,
        message: message,
        buttonText: buttonText,
        imgSrc: img ? img : 'warning.svg',
        key: key,
      }, disableClose: true
    })
    dialog.afterClosed().subscribe((res) => {
      console.log(res);
      if (key == 'sendOtp') {
        this.sendOTP();
      }
    })
  }

}
