import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { HttpCancelService } from './services/httpcancel.service';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'appreciate';
  showNavBar: boolean = false;
  supportedLanguages = ['en', 'hin'];
  constructor(private translate: TranslateService, private rout: Router, private swUpdate: SwUpdate, private httpCancelService: HttpCancelService,
    private breadcrumbService: BreadcrumbService) { }
  ngOnInit() {
    if (window.location.pathname == '/login' ||
      window.location.pathname == '/set-password' ||
      window.location.pathname == '/forgot-password' ||
      window.location.pathname == '/otp' ||
      window.location.pathname == '/email-or-number' ||
      window.location.pathname == '/security-question') {
      debugger
    }
    else {
      this.translate.addLangs(this.supportedLanguages);
      this.translate.setDefaultLang('en');
    }
    this.rout.events.subscribe((event) => {
      // console.log('url',event);
      if (event instanceof NavigationEnd) {
        //cancel pending calls after navigation
        this.httpCancelService.cancelPendingRequests();
        if (event.url.match('/login') ||
          event.url.match('/set-password') ||
          event.url.match('/forgot-password') ||
          event.url.match('/otp') ||
          event.url.match('/email-or-number') ||
          event.url.match('/security-question')
        ) {
          this.showNavBar = false;
        } else {
          this.showNavBar = true;
        }
      }
    })

    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(() => {
       alert("reload works")
        // if(confirm("New version available. Load New Version?")) {

        window.location.reload();
        // }
      });
    }
  }
}
