import { Injectable } from '@angular/core';
import { IMqttMessage, IMqttServiceOptions, MqttService, IPublishOptions } from 'ngx-mqtt';
import { IClientSubscribeOptions } from 'mqtt-browser';
import { Subscription } from 'rxjs';
import { HeaderProvider } from './headers';
import { environment } from 'src/environments/environment';

@Injectable()
export class MQTTService {

    constructor(private headerProvider: HeaderProvider,) {
    }
    public curSubscription: Subscription | undefined;
    private curMultiSubscription: Subscription | undefined;

    public connection = {
        hostname: environment.mqttBaseUrl,
        port: 8083,
        path: '',
        clean: true, // Retain session
        connectTimeout: 10000, // Timeout period
        reconnectPeriod: 10000, // Reconnect period
        // Authentication information
        clientId: '',//userID
        username: this.headerProvider.fetchHeaders().ACCESS_TOKEN,//token
        password: '',//userId
        protocol: 'ws',
    } as IMqttServiceOptions
    subscription = {
        topic: 'ric/AAPL.BAT',
        qos: 0,
    };
    publish = {
        topic: 'topic/browser',
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
    };
    receiveNews = '';
    qosList = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
    ];
    client: MqttService | undefined;
    isConnection = false;
    subscribeSuccess = false;

    createConnection(user_id) {
     
        // if (!this.isConnection) {
            this.client = new MqttService(environment.MQTT_SERVICE_OPTIONS)

        
        // Connection string, which allows the protocol to specify the connection method to be used
        // ws Unencrypted WebSocket connection
        // wss Encrypted WebSocket connection
        // mqtt Unencrypted TCP connection
        // mqtts Encrypted TCP connection
        this.connection.clientId = user_id
        this.connection.password = user_id
        console.log(this.connection, "connection config");

        try {
            this.connection.username = this.headerProvider.fetchHeaders().ACCESS_TOKEN,
                this.client?.connect(this.connection as IMqttServiceOptions)
        } catch (error) {
            console.log('mqtt.connect error', error);
            this.connection.username = this.headerProvider.fetchHeaders().ACCESS_TOKEN
            this.client?.connect(this.connection as IMqttServiceOptions)
        }
        this.client?.onConnect.subscribe(() => {
            this.isConnection = true
            console.log('Connection succeeded!');
                // this.doSubscribe()

        });
        this.client?.onError.subscribe((error: any) => {
            this.isConnection = false
            console.log('Connection failed', error);
                // this.connection.username=this.headerProvider.fetchHeaders().ACCESS_TOKEN
                // new this.client.onReconnect()
                // this.connection.username=this.headerProvider.fetchHeaders().ACCESS_TOKEN
                // this.client?.connect(this.connection as IMqttServiceOptions)
        });
        this.client?.onMessage.subscribe((packet: any) => {
            if (packet.payload != null && !packet.payload.toString()) {
                this.receiveNews = this.receiveNews.concat(packet.payload.toString())
                console.log(`Received message ${packet.payload.toString()} from topic ${packet.topic}`)
            }

        })
        // }
    }


    doSubscribe() {
        const { topic, qos } = this.subscription
        return this.client?.observe(topic, { qos } as IClientSubscribeOptions);
    }

    doUnSubscribe() {
        this.curSubscription?.unsubscribe()
        this.subscribeSuccess = false
    }

    doPublish() {
        const { topic, qos, payload } = this.publish
        console.log(this.publish)
        this.client?.unsafePublish(topic, payload, { qos } as IPublishOptions)
    }



    doMultiSubscribe(list) {
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            let qos = 0
            this.curMultiSubscription = this.client?.observe(element.top, { qos } as IClientSubscribeOptions).subscribe((message: IMqttMessage) => {
                this.subscribeSuccess = true
                console.log('Subscribe to topics res', message.payload.toString())
            })

        }
    }

    doMutliUnSubscribe(list) {
        this.curMultiSubscription?.unsubscribe()
    }






}
