import { Injectable } from '@angular/core';
import { AppConstants} from 'src/app/app.constants';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class TaskService {
  // apiUrl = AppConstants.API_BASE_URL+':5019';
  // usersApiUrl = AppConstants.API_BASE_URL+':5021';
  apiUrl = AppConstants.WEB_APP_APIS.DEV[5019];
  usersApiUrl = AppConstants.WEB_APP_APIS.DEV[5021];
  reqHeaders = {
    "cache-control": "no-cache",
    "accept": "application/json",
  };
  constructor(private http: HttpClient) {

  }

  fetchCategories() {    
    const url: string = `${this.apiUrl}/v1/categories/`;
    return this.http.get<any>(url, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  fetchUsers() {    
    const url: string = `${this.usersApiUrl}/v1/users/`;
    return this.http.get<any>(url, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  fetchStatuses() {    
    const url: string = `${this.apiUrl}/v1/status/`;
    return this.http.get<any>(url,
      { 
        headers: this.reqHeaders,
      }).toPromise();
  }

  fetchPriorities() {    
    const url: string = `${this.apiUrl}/v1/priorities/`;
    return this.http.get<any>(url,
      {
        headers: this.reqHeaders,
      }).toPromise();
  }

  fetchTaskList(data) { 
    const url: string = `${this.apiUrl}/v1/tasks/`;
    return this.http.get(url, {
      headers: this.reqHeaders,
      params: data
    }).toPromise();
  }

  createTask(data){
    const url: string = `${this.apiUrl}/v1/tasks/`;
    return this.http.post(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  editTask(data){
    const url: string = `${this.apiUrl}/v1/task/${data.id}/`;
    return this.http.put(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  editTaskStatus(data){
    const url: string = `${this.apiUrl}/v1/task/${data.id}/update-status/`;
    return this.http.patch(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  deleteTask(data){
    const url: string = `${this.apiUrl}/v1/task/${data.id}/`;
    return this.http.delete(url, {
      headers: this.reqHeaders,
    }).toPromise();
  }

}
