<div class="main-container">
  <div class="login_form">
    <label (click)="goBack()"
      ><img src="assets/images/leftArrow.svg" alt="" class="backArrowImage"
    /></label>
    <div class="logo-container">
      <!-- <img src="../../../assets/images/appretiate_logo.png" alt="" /> -->
      <img src="../../../assets/images/appreciateLogoHorizontal.png" alt="" />
    </div>
    <hr />
    <!-- <h3 class="text-center">Distributor Portal</h3> -->
    <small class="fs-14 text-indigo">
      {{
        "forgot_password.where_would_you_like_to_recieve" | translate
      }}?</small
    >
    <form [formGroup]="userForm" class="w-100">
      <mat-radio-group aria-label="Select an option">
        <mat-radio-button class="radio-button" value="1" [checked]="true">
          {{ "forgot_password.via_email" | translate }}</mat-radio-button
        >
        <mat-radio-button class="radio-button mob-num ml-5" value="2">
          {{
            "forgot_password.via_mobile_number" | translate
          }}</mat-radio-button
        >
      </mat-radio-group>

      <button
        [ngClass]="{ active_btn: userForm.valid }"
        class="submit-btn"
        mat-raised-button
        color="primary"
        [routerLink]="['/send-otp']"
      >
        {{ "forgot_password.send_otp" | translate }}
      </button>
    </form>
  </div>
  <div></div>
</div>
