import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-first-time-signin',
  templateUrl: './first-time-signin.component.html',
  styleUrls: ['./first-time-signin.component.scss']
})
export class FirstTimeSigninComponent implements OnInit {

  constructor(private translate: TranslateService, private formBuilder: FormBuilder,) { }
  userForm = this.formBuilder.group({
    // email:['',[Validators.required,Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$")]],
    // password:['',[Validators.required,Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")]]
  });
  defaultZone: any;
  lang: any;
  zones: any = [
    {
      value: 'en', viewValue: 'English'
    },
    {
      value: 'hin', viewValue: 'Hindi'
    }
  ]
  ngOnInit(): void {
    let zone: any
    if (!localStorage.getItem('langSelected')) {
      debugger;
      zone = 'en'
      this.lang = 'en'
      localStorage.setItem('langSelected', 'en')
    } else {
      zone = (localStorage.getItem('langSelected'))
      this.lang = zone
      debugger
    }
    this.defaultZone = zone
    this.translate.addLangs(['en', 'hin']);
    this.translate.setDefaultLang(zone);
  }
  signin: FormGroup = new FormGroup({
    // email: new FormControl('', [Validators.email, Validators.required ]),
    // password: new FormControl('', [Validators.required, Validators.min(3) ])
  });
  hide = true;
  // get emailInput() { return this.signin.get('email'); }
  // get passwordInput() { return this.signin.get('password'); }
  get form() {
    return this.userForm.controls;
  }

}
