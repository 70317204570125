import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-alert-box',
  templateUrl: './custom-alert-box.component.html',
  styleUrls: ['./custom-alert-box.component.scss']
})
export class CustomAlertBoxComponent implements OnInit {
  executed: boolean = false;

  constructor(public dialogRef: MatDialogRef<CustomAlertBoxComponent>, private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {


  }
  close1() {
    this.route.navigate(['/transact/transact-behalf-of-customer/goals/create-goals/goal-setup/create']);
    this.dialogRef.close()

  }
  close() {
    this.dialogRef.close()

  }
  onNo(){
    this.dialogRef.close(false)
  }

  onOk() {
    this.dialogRef.close(true)

  }


}
