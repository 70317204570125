import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { CryptoProvider } from './crypto';

@Injectable({ providedIn: 'root' })
export abstract class StorageService {
  abstract getItem<T>(key: string): Promise<T>;
  abstract setItem<T>(key: string, value: any): Promise<void>;
  abstract removeItem(key: string): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class SessionStorageService implements StorageService {





  constructor(private _crypto: CryptoProvider){

  }

  getItem<T>(key: string){
    return this._crypto.decryptObj(sessionStorage.getItem(key));

  }

  setItem<T>(key: string, value: T): Promise<void> {
    const result = sessionStorage.setItem(key,this._crypto.encryptObj(value));
    return Promise.resolve(result);
  }

  removeItem(key: string): Promise<void> {
    return Promise.resolve(sessionStorage.removeItem(key));
  }

  getItemLocal<T>(key: string){
    return this._crypto.decryptObj(localStorage.getItem(key));

  }

  setItemLocal<T>(key: string, value: T): Promise<void> {
    const result = localStorage.setItem(key,this._crypto.encryptObj(value));
    return Promise.resolve(result);
  }

  removeItemLocal(key: string): Promise<void> {
    return Promise.resolve(localStorage.removeItem(key));
  }




}
