<div class="main" *ngIf="loggedIn">
  <div class="last-item">
    <!-- <label class="cursor-pointer mb-0">
      <mat-icon class="material-icons-outlined">notifications</mat-icon>
    </label> -->
    <label class="cursor-pointer mb-0" (click)="globalSearch(popupDocument)">
      <img class="searchIcon" src="assets/images/search.svg" />
    </label>
    <span class="vl"></span>
    <div class="cursor-pointer mb-0 d-flex align-items-center" (click)="profile()">
      <p class="mb-0 mr-2">{{ userDetails?.name }}</p>
      <div class="profile"><img [src]="userDetails && userDetails?.profile_url ? userDetails?.profile_url : 'assets/images/blank-profile.svg'" /></div>
    </div>
  </div>
</div>