import { Injectable } from '@angular/core';
import {ToasterConfig, ToasterService, ToasterModule} from 'angular2-toaster';
import { AppConstants } from '../../app.constants';

@Injectable()
export class ToastrService {
  config: ToasterConfig;
  destroyByClick = true;
  duration = 2000;
  hasIcon = false;
  position: 'toast-top-right';
  preventDuplicates = false;
  status = 'primary';
  title = 'Coming Soon.';
  content = ``;
  constructor(
    private toastrService: ToasterService,
  ) {

  }

  showToast(type, title: string, body: string) {
    if (AppConstants.NOTIFICATION_TEXT_LENGTH_DISPLAY_TIME_CONFIG && AppConstants.NOTIFICATION_TEXT_LENGTH_DISPLAY_TIME_CONFIG.length > 0) {
      const notificationsTextConfig = AppConstants.NOTIFICATION_TEXT_LENGTH_DISPLAY_TIME_CONFIG;
      for (let i = 0; i < notificationsTextConfig.length; i++) {
        const lenghtDif = notificationsTextConfig[i].lengthkey.split('-');
        if (lenghtDif.length > 1) {
          if (title && (title.length >= Number(lenghtDif[0])) && (title.length < Number(lenghtDif[1]))) {
            this.duration = notificationsTextConfig[i].displaytime;
          }
        } else {
          if (lenghtDif.length === 1) {
            this.duration = notificationsTextConfig[i].displaytime;
          }
        }
      }
    }
    const titleContent = title ? `${title}` : '';
    const config = {
      type: type,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
      title: titleContent
    };
  
    this.toastrService.pop(config);
  }
}
