import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  public  params = new HttpParams().set('Hide-Loader', 'true');
  constructor(private _http: HttpClient) {

  }

  getData(url: string,params?): Observable<any> {
    return this._http.get(url,{params})
  }



  getFileData(url: string): Observable<any> {
    const headers= new HttpHeaders()
  .set('Accept','application/json, text/plain, */*')
    return this._http
    .get(
      url
    ,{ 'headers': headers })
  }

  getDataByQuery(url: string, params?: any): Observable<any> {
    return this._http.get(url, { params: params })
  }

  getDataByHeaders(url: string, headers: any,params?): Observable<any> {
    return this._http.get(url, { headers: headers,params })
  }

  postData(url: string, body: Object,params?): Observable<any> {
    let customHeaders;
    if (body && body['customHeaders']) {
      customHeaders = body['customHeader'];
      try { delete body['customHeaders']; } catch (e) { }
    }
    return this._http.post(url, body,{ headers: customHeaders,params })
  }

  postFormData(url: string, body: any,params?): Observable<any> {
    let customHeaders = {
      "mimeType": "multipart/form-data",
      "Accept": "*/*",
    }
    return this._http.post(url, body, { headers: customHeaders,params })
  }

  updateData(url: string, body: Object, params?): Observable<any> {
    return this._http.put(url, body, {params})
  }
  
  patchData(url: string, body: Object): Observable<any> {
    return this._http.patch(url, body)
  }


  deleteData(url: string): Observable<any> {
    return this._http.delete(url)
  }






}
