<div class="main-container">
  <div class="login_form">
    <label (click)="goBack()" class="goBack"><img src="assets/images/leftArrow.svg" alt=""
        class="backArrowImage" /></label>
    <div class="logo-container">
      <!-- <img src="../../../assets/images/appretiate_logo.png" alt="" /> -->
      <!-- <img src="../../../assets/images/appreciateLogoHorizontal.png" alt="" /> -->
      <img src="../../../assets/images/logo.png" alt="logo" />
    </div>
    <hr />
    <h3 class="text-center">
      {{ "forgot_password.admin_portal" | translate }}
    </h3>
    <form
      [formGroup]="forgotPasswordForm"
      class="w-100"
      (submit)="sendOtp(forgotPasswordForm)"
    >
      <small class="fs-14 text-indigo">
        {{
          "forgot_password.where_would_you_like_to_recieve" | translate
        }}?</small
      >
      <mat-radio-group
        aria-label="Select an option"
        (change)="emailOrMobile($event)"
      >
        <mat-radio-button class="radio-button" value="1" [disabled]="false">
          {{ "forgot_password.via_email" | translate }}</mat-radio-button
        >
        <mat-radio-button
          class="radio-button mob-num ml-5"
          value="2"
          [checked]="true"
        >
          {{
            "forgot_password.via_mobile_number" | translate
          }}</mat-radio-button
        >
      </mat-radio-group>
      <mat-form-field class="login_input" *ngIf="selectedInput === 'email'">
        <mat-label> {{ "common.email" | translate }}</mat-label>
        <input
          type="email"
          matInput
          placeholder="{{ 'common.enter' | translate }} {{
            'common.email' | translate
          }} {{ 'forgot_password.address' | translate }}"
          formControlName="email"
        />
        <small
          *ngIf="
            form.email.invalid &&
            form.email.touched &&
            form.email.errors.required
          "
          class="text-danger"
          >{{ "common.email" | translate }}
          {{ "common.is_required" | translate }}.</small
        >
        <small
          *ngIf="
            form.email.invalid &&
            form.email.touched &&
            form.email.errors.pattern
          "
          class="text-danger"
          >{{ "common.email_id_invalid" | translate }}</small
        >
      </mat-form-field>

      <mat-form-field class="login_input" *ngIf="selectedInput === 'phone'">
        <mat-label> {{ "forgot_password.phone_number" | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'common.enter' | translate }}  {{
            'forgot_password.phone_number' | translate
          }}"
          formControlName="phone"
          mask="0000000000"
        />
        <small
          *ngIf="
            form.phone.invalid &&
            form.phone.touched &&
            form.phone.errors.required
          "
          class="text-danger"
          >{{ "forgot_password.phone_number" | translate }}
          {{ "common.is_required" | translate }}</small
        >
        <small
          *ngIf="
            form.phone.invalid &&
            form.phone.touched &&
            form.phone.errors.pattern
          "
          class="text-danger"
          >{{ "common.phone_number_invalid" | translate }}</small
        >
        <small
          *ngIf="
            form.phone.invalid &&
            form.phone.touched &&
            form.phone.errors.minLength &&
            form.phone.errors.maxLength
          "
          class="text-danger"
          >{{
            "forgot_password.phone_number_should_have_10_digits" | translate
          }}</small
        >
      </mat-form-field>
      <button
        [ngClass]="forgotPasswordForm.valid ? 'active_btn' : 'disabled_div'"
        class="submit-btn"
        mat-raised-button
        color="primary"
      >
        {{ "forgot_password.send_otp" | translate }}
      </button>
    </form>
  </div>
  <div></div>
</div>