import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-email-or-number',
  templateUrl: './email-or-number.component.html',
  styleUrls: ['./email-or-number.component.scss']
})
export class EmailOrNumberComponent implements OnInit {
  userForm = this.formBuilder.group({

  });
  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private location: Location
  ) { }
  defaultZone: any;
  lang: any;
  zones: any = [
    {
      value: 'en', viewValue: 'English'
    },
    {
      value: 'hin', viewValue: 'Hindi'
    }
  ]
  ngOnInit(): void {
    let zone: any
    if (!localStorage.getItem('langSelected')) {
      debugger;
      zone = 'en'
      this.lang = 'en'
      localStorage.setItem('langSelected', 'en')
    } else {
      zone = (localStorage.getItem('langSelected'))
      this.lang = zone
      debugger
    }
    this.defaultZone = zone
    this.translate.addLangs(['en', 'hin']);
    this.translate.setDefaultLang(zone);
  }

  signin: FormGroup = new FormGroup({

  });

  goBack() {
    this.location.back();
  }

  get form() {
    return this.userForm.controls;
  }

}
