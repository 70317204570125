<div class="main-container">
  <div class="login_form">
    <!-- <label (click)="goBack()" class="goBack"><img src="assets/images/leftArrow.svg" alt="" class="backArrowImage"></label> -->
    <div class="logo-container">
      <img src="../../../assets/images/logo.png" alt="" />
      <!-- <img src="../../../assets/images/appreciateLogoHorizontal.png" alt="" /> -->
    </div>
    <hr />
    <!-- <h3 class="text-center">Admin Portal</h3> -->
    <p class="text-center">
      <!-- Signing in for the first time?  -->
      {{ "set_password.two_step_security" | translate }} <br>{{ "set_password.set_a_new_password" | translate }}
    </p>
    <form
      [formGroup]="userForm"
      class="w-100"
      (submit)="submitPassword(userForm)"
    >
      <mat-form-field style="width: 100%">
        <mat-label> {{ "set_password.password" | translate }}</mat-label>
        <mat-pass-toggle-visibility
          #toggle
          matSuffix
        ></mat-pass-toggle-visibility>
        <input
          matInput
          #password
          [type]="toggle.type"
          formControlName="password"
          required
          placeholder="  {{ 'set_password.password' | translate }}"
          (input)="changePassword($event)"
        />
      </mat-form-field>
      <div class="password_strength_section" *ngIf="showPasswordInfo">
        <mat-password-strength
          #passwordComponent
          [password]="password.value"
          (onStrengthChanged)="onStrengthChanged($event)"
        >
        </mat-password-strength>
        <mat-password-strength-info
          minCharsCriteriaMsg="Password should be minimum 8 characters long"
          upperCaseCriteriaMsg="Password should contain one uppercase character"
          lowerCaseCriteriaMsg="Password should contain one lowercase character"
          digitsCriteriaMsg="Password should contain numbers"
          specialCharsCriteriaMsg="Password should contain special characters"
          [passwordComponent]="passwordComponent"
        >
        </mat-password-strength-info>
      </div>

      <mat-form-field class="example-full-width">
        <input
          matInput
          placeholder="Confirm Password"
          [type]="toggle1.type"
          formControlName="confirmPassword"
          (input)="checkPassword(userForm)"
          required
        />
        <mat-pass-toggle-visibility
          #toggle1
          matSuffix
        ></mat-pass-toggle-visibility>
        <small
          *ngIf="!passwordMatched && form.confirmPassword.touched"
          class="text-danger"
          >{{ "set_password.passwords_are_not_same" | translate }}</small
        >
      </mat-form-field>

      <button
        class="submit-btn"
        mat-raised-button
        color="primary"
        [ngClass]="{ active_btn: userForm.valid && passwordMatched }"
      >
        {{ "set_password.set_new_password" | translate }}
      </button>
    </form>
  </div>
  <div></div>
</div>
