import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from 'src/app/app.constants';
import { HeaderProvider } from '../providers/headers';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // baseUrl: string = AppConstants.API_BASE_URL+':5020';
  baseUrl: string = AppConstants.WEB_APP_APIS.DEV[5020];
  // otpBaseUrl: string = AppConstants.API_BASE_URL+':5012';
  otpBaseUrl: string = AppConstants.WEB_APP_APIS.DEV[5012];
  userBaseUrl: string = AppConstants.API_BASE_URL+':5001';
  // roleBaseUrl: string = AppConstants.API_BASE_URL+':5017';
  roleBaseUrl: string = AppConstants.WEB_APP_APIS.DEV[5017];
  // resetPasswordUrl: string = AppConstants.API_BASE_URL+':5021';
  resetPasswordUrl: string = AppConstants.WEB_APP_APIS.DEV[5021];
  url: string;
  headers = this.headerProvider.fetchHeaders()

  reqHeaders = new HttpHeaders({
    "cache-control": "no-cache",
    'accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });
  otpReqHeaders = new HttpHeaders({
    'Accept': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  });
  constructor(
    private http: HttpClient,
    private headerProvider: HeaderProvider
    ) { }

  login(data) {
    this.url = `${this.resetPasswordUrl}/v1/staff${AppConstants.WEB_APP_APIS.USER.login}`
    return this.http.post(this.url, data);
  }

  resendOtp(data) {
    this.url = `${this.otpBaseUrl}/v2/resend_otp/`
    return this.http.post(this.url, data, {headers: this.otpReqHeaders})
  }

  refreshToken(token):Observable<any> {
    this.url = `${AppConstants.COMMON_PORTS.AUTH}${AppConstants.WEB_APP_APIS.USER.refresh}`
    return this.http.post(this.url, '', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  verifyUser(info):Observable<any> {
    this.url = `${AppConstants.COMMON_PORTS.AUTH}/verify/`
    return this.http.get(this.url, {
      headers: {
        'Authorization': `Bearer ${info.access_token}`
      }
    });
  }

  userDetails(info) {  
    // let isAgent = this.headerProvider.distributorStaffStatus();
    let params = {}
    // isAgent ? params['role'] = 'agent' : '';
    const url: string = `${AppConstants.COMMON_PORTS.DISTRIBUTOR}/user/`;
    return this.http.get<any>(url, {
      params: params,
      headers:{
        'access-token': `${info.access_token}`
        }
    });
  } 

  // to get user role
  getSelfRoles(info) {

    this.url = `${this.roleBaseUrl}/rbac/users/me/`
    return this.http.get(this.url,{
      headers:{
      'access-token': `${info.access_token}`
      }
    });
  }
  // to get user permissions
  getSelfPermissions(info) {
    let data = {
      'pageNumber': 1,
      'pageSize': 500
    }
    this.url = `${this.roleBaseUrl}/rbac/users/me/permissions/?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
    return this.http.get(this.url, {
      headers:{
        'access-token': `${info.access_token}`
        }
    });
  }

  // to get user config
  getUserConfig(info) {
    this.url = `${this.resetPasswordUrl}/v1/kyc-status/`
    return this.http.get(this.url,{
      headers:{
        'access-token': `${info.access_token}`
        }
      });
  }

  getUserGrant(url,info){
    return this.http.get(url,{
      headers:{
        'access-token': `${info.access_token}`
        }
      });
  }

  logout() {
    const { ACCESS_TOKEN } = this.headerProvider.fetchHeaders();
    this.url = `${AppConstants.COMMON_PORTS.AUTH}/logout/`
    return this.http.post(this.url, '', {
      headers: {
        'Authorization': `Bearer ${ACCESS_TOKEN}`
      }
    });
  }

  forgotPassword(data){
    this.url = `${this.resetPasswordUrl}/v1/staff/forgot-password/`
    return this.http.post(this.url, data);
  }

  getOtp(input, data){
    this.url = `${this.resetPasswordUrl}/v1/staff/get_otp/${input}/`
    return this.http.post(this.url, data);
  }

  verifyOtp(input, data){
    this.url = `${this.resetPasswordUrl}/v1/staff/verify/${input}/`
    return this.http.post(this.url, data);
  }

  setPassword(input, data){
    this.url = `${this.resetPasswordUrl}/v1/staff/reset_password/${input}/`
    return this.http.post(this.url, data);
  }

}
