import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constants';
import { CustomAlertBoxComponent } from 'src/app/components/custom-alert-box/custom-alert-box.component';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { CryptoProvider } from 'src/app/services/providers/crypto';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { HeaderProvider } from 'src/app/services/providers/headers';
import { AuthenticateOtpDialogComponent } from 'src/app/shared/dialog-components/authenticate-otp-dialog/authenticate-otp-dialog.component';
import { SearchCustomersComponent } from 'src/app/shared/search-customers/search-customers.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loggedIn: any;
  userDetails: any;
  closeResult = '';
  searchTerm: string = '';
  isAdmin: any;

  customers: any;
  routeParams: any;
  noapicall: boolean;
  nostaffbool: boolean = false;
  showtext: boolean = false;
  isLoading: boolean;
  isDistributor: any;
  isAgent: boolean;
  constructor(
    private crypto: CryptoProvider,
    private _header: HeaderProvider,
    private route: Router,
    private modalService: NgbModal,
    private headerProvider: HeaderProvider,
    private apiService: CrudService,
    private router: Router,
    private _storage: SessionStorageService,
    private _dialog: MatDialog,
    private toaster: ToastrService,
  ) {
    // this.routeParams = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    let user = localStorage.getItem('USER_DETAILS');
    this.userDetails = this.crypto.decryptObj(user);
    this.loggedIn = this.crypto.decryptObj(localStorage.getItem('USER_INFO'));
    this.isAdmin = this.headerProvider.isAdmin();
    this.isDistributor = this.headerProvider.isDistributor();
    this.isAgent = this.headerProvider.distributorStaffStatus();
  }
  
  profile() {
    let isAdmin = this._header.isAdmin();
    if (isAdmin) {
      this.route.navigate(['/profile-details']);
    } else this.route.navigate(['/profile']);
  }
  
  globalSearch(data) {
    let customerInfo = this._storage.getItem('CUSTOMER_INFO');
    console.log("cst", customerInfo);
    if (customerInfo && customerInfo?.user_id) {
      this.callPopup(data);
    } else {
      this._dialog.open(SearchCustomersComponent, {
        panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
        data: {
          content: 'You are about to exit from this customer’s profile, you will have to reauthenticate to access it.',
          title: 'Are you sure?',
          successButton:'okay',
          cancelButton:'cancel'
        }
      }).afterClosed().subscribe(res => {
        if (res) {
        }
      })
    }
  }

  callPopup(searchData) {
    this._dialog.open(CustomAlertBoxComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        content: 'You are about to exit from this customer’s profile, you will have to reauthenticate to access it.',
        title: 'Are you sure?',
        successButton:'okay',
        cancelButton:'cancel'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this._storage.removeItem('CUSTOMER_INFO')
        // this.globalSearch(searchData);
        this.router.navigate(['/transact/'])
      }
    })
  }

  private getDismissReason(reason: any): string {
    this.searchTerm = ''
    this.customers = ''
    this.showtext = false
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
