<div class="main-container">
  <div class="logo-container">
    <!-- <img src="../../../assets/images/appretiate_logo.png" alt="" /> -->
    <img src="../../../assets/images/appreciateLogoHorizontal.png" alt="" />
  </div>
  <div class="login_form">
    <h3 class="text-center">
      {{ "forgot_password.admin_portal" | translate }}
    </h3>
    <small class="small-head">{{
      "first_time.first_time_sentance" | translate
    }}</small>
    <form [formGroup]="userForm" class="w-100">
      <mat-form-field class="example-full-width login_password">
        <input
          matInput
          placeholder="New Password
          "
          [type]="hide ? 'password' : 'text'"
          required
        />
        <mat-icon matSuffix class="password_toggler" (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
      <mat-form-field class="example-full-width login_confirm_password">
        <input
          matInput
          placeholder="Confirm New Password"
          [type]="hide ? 'password' : 'text'"
          required
        />
        <mat-icon matSuffix class="password_toggler" (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>

      <button class="submit-btn" mat-raised-button color="primary">
        {{ "common.sign_in" | translate }}
      </button>
    </form>
  </div>
</div>
