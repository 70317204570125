import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppConstants } from 'src/app/app.constants';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListOfUsersService {
  url: string;
  // apiUrl = 'https://appreciate-keycloak.pacewisdom.in';
  // roleApiUrl = "https://appreciate-rbac.pacewisdom.in/rbac";
  // roleApiUrl = AppConstants.API_BASE_URL+':5017/rbac';
  userBaseUrl: string = AppConstants.API_BASE_URL+':5001';
  roleApiUrl = AppConstants.WEB_APP_APIS.DEV[5017]+'/rbac';
  reqHeaders = new HttpHeaders({
    "cache-control": "no-cache",


  });
  
  options = {
    headers: this.reqHeaders,
    observe: "response" as 'body', // to display the full response & as 'body' for type cast
    responseType: "json"
};
  constructor(private http: HttpClient) { }

  // to get user role
  getLoggedInUserRole() {
    this.url = `${this.userBaseUrl}/v1/user/user_details/`
    return this.http.get(this.url, { headers: this.reqHeaders });
  }

  // to get roles List

  getRolesList() {
    this.url = `${this.roleApiUrl}/roles/`
    return this.http.get(this.url, { headers: this.reqHeaders });
  }

  // to get module list with respect to pagenumber and pagesize

  getRolesListWithpagination(pagesize, pageNumber,searchTerm) {
    this.url = `${this.roleApiUrl}/roles/?pageSize=${pagesize}&pageNumber=${pageNumber}&name=${searchTerm}`
    return this.http.get(this.url, { headers: this.reqHeaders }).pipe(
      catchError(this.handleError)
    );;
  }

  //Add Roles 

  addRoles(data) {
    this.url = `${this.roleApiUrl}/roles/`
    return this.http.post(this.url, data, { headers: this.reqHeaders });
  }

  //Edit Roles 

  editRoles(roleid,data) {
   
    this.url = `${this.roleApiUrl}/roles/${roleid}/?name=${data.name}&description=${data.description}&group=${data.group}`;
    return this.http.patch(this.url, { headers: this.reqHeaders }).pipe(
     catchError(this.handleError)
    );
  }

  //remove roles

  removeRoles(roleId){
    this.url = `${this.roleApiUrl}/roles-additional/${roleId}/`;
    return this.http.delete(this.url, { headers: this.reqHeaders,}).pipe(
     catchError(this.handleError)
    );
  }

  // http params

  // removeRolesWithSecrete(roleId,confirmation_secret){
   
  //   this.url = `${this.roleApiUrl}/roles-additional/${roleId}/?confirmation_secret=${confirmation_secret}`;
 
  //   return this.http.delete(this.url, { headers: this.reqHeaders}).pipe(
  //    catchError(this.handleError)
  //   );
  // }


  //lis of modules

  moduleList() {

    this.url = `${this.roleApiUrl}/modules/?pageNumber=1&page=true`
    return this.http.get(this.url, { headers: this.reqHeaders }).pipe(
      retry(2),
      catchError(this.handleError)
    );;
  }

  //grant Permission

  GrantPermissionForUser(data, id) {
    this.url = `${this.roleApiUrl}/roles/${id}/`
    return this.http.put(this.url, data, { headers: this.reqHeaders }).pipe(
      catchError(this.handleError)
    );;
  }

  //Get Role grants
  getRoleGrants(id) {

    this.url = `${this.roleApiUrl}/roles/${id}/`
    return this.http.get(this.url, { headers: this.reqHeaders }).pipe(
      catchError(this.handleError)
    );;
  }


  handleError(error: HttpErrorResponse){
 
    return throwError(error);
    }


}
