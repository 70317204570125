

// Core imports
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, finalize, retry, retryWhen, concatMap, delay, takeUntil, timeout } from 'rxjs/operators';
import { HeaderProvider } from '../../services/providers/headers';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CryptoProvider } from 'src/app/services/providers/crypto';
import { ToastrService } from 'ngx-toastr';
import { CommonLoaderService } from 'src/app/components/common-loader/common-loader.service';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { AppConstants } from '../../app.constants';
import { AnnouncementComponent } from 'src/app/pages/transact/transct-on-behalf-customer/shared/announcement/announcement.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpCancelService } from '../../services/httpcancel.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  storageValue: any;
  sikpUrl: string[];
  userinformation: string;
  retryCount: number = 0;



  constructor(
    private headerProvider: HeaderProvider,
    private toastService: ToastrService,
    private route: Router,
    private _route: ActivatedRoute,
    private _CommonLoaderService: CommonLoaderService,
    private authService: AuthService,
    private _storage: SessionStorageService,
    private crypto: CryptoProvider,
    private _dialog: MatDialog,
    private httpCancelService:HttpCancelService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number

  ) {

  }
  addData() {


    this.sikpUrl = [
      `${AppConstants.COMMON_PORTS.RKD_USERS_API}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.watchList_pro}`,

      `${AppConstants.COMMON_PORTS.BYO_V2}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getIndicatorData}`,
      `${AppConstants.COMMON_PORTS.BYO_V2}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getAnalystRating}`,
      `${AppConstants.COMMON_PORTS.BYO_V2}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getHighlights}`,
      `${AppConstants.COMMON_PORTS.BYO_V2}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getAverageScore}`,
    ]


  }

  skiptoaster=[
    `${AppConstants.COMMON_PORTS.CONVERTION}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.company_stats}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getLatestHighlights}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getAnalystRating}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getAverageScore}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getPeerData}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getIndicatorData}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getPriceVolumeDataPoints}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.getEarningGraph}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.research_pdf}`,
    `${AppConstants.COMMON_PORTS.INSTRUMENT}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.PRO_FRACTIONS.financialTrend}`,
    `${AppConstants.COMMON_PORTS.DISTRIBUTOR}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.userDetails}`

  ]



  apisToSkip = [

    `${AppConstants.COMMON_PORTS.USER_V2}${AppConstants.WEB_APP_APIS.TRANSACT_ON_BEHALF.GOALS.getListOfGoalCategory}`,
    `${AppConstants.COMMON_PORTS.AUTH}${AppConstants.WEB_APP_APIS.USER.refresh}`,
    `${AppConstants.COMMON_PORTS.VIEWSTATEMENT}${AppConstants.WEB_APP_APIS.VIEWSTATEMENT.pdfgenerate}`,


  ]
  skipAuthEroor=[
    `${AppConstants.COMMON_PORTS.POSTS}${AppConstants.WEB_APP_APIS.RESEARCH.researchAndPerspective}`

  ]
  Mainurl = ""
  /*
        Method to display toast with a dynamic message
    */

  displayToast(status, content) {
    if (AppConstants.TOASTER_STATUS.ERROR) {
      this.toastService.error(content, status, { timeOut: 3000 });
    } else {
      this.toastService.success(content, status, { timeOut: 3000 });
    }
  }



  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    if (this._storage.getItem('dataforintercepter')) {
      this.storageValue = this._storage.getItem('dataforintercepter')


      this.addData();
    }
    const authorizationHeaders = request.headers.get('access-token');
    const hideLoader = request.params.get('Hide-Loader');
    let  spinnerSubscription:Subscription
    if(!hideLoader){
      spinnerSubscription = this._CommonLoaderService.spinner$.subscribe();

    }

    if(hideLoader){
      request.params.delete('Hide-Loader','true')
    }

    if (this.headerProvider.fetchHeaders() && !authorizationHeaders) {
      const { ACCESS_TOKEN } = this.headerProvider.fetchHeaders();
      if (
        ACCESS_TOKEN &&
        !this.route.url.includes('/login') &&
        !this.route.url.includes('/otp')
        &&
        request.url != `${AppConstants.COMMON_PORTS.AUTH}${AppConstants.WEB_APP_APIS.USER.refresh}`
      ) {
        this.Mainurl = this.route.url
        request = this.addToken(request, ACCESS_TOKEN);

      }
    }
    return next.handle(request).pipe(
      timeout(timeoutValueNumeric),
      takeUntil(this.httpCancelService.onCancelPendingRequests()),
      finalize(() => spinnerSubscription && spinnerSubscription.unsubscribe()),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 0 || error.status === 404) {
          this.toastService.error(
            AppConstants.TOASTER_STATUS.ERROR,
            `${AppConstants.API_RESPONSE_MESSAGES.SOMETHING_WENT_WRONG}`
          );
        } else if (error && error.status === 404) {
          // this.route.navigate(["/page-not-found"])
        } else if (error && error.status === 403) {
          console.log(error?.error);
          if(error?.error?.type==="authorization_error" || error?.error?.type =="Auth Error"){
            this.toastService.error("Insufficient permission")
          }else{
          let msg = error?.error?.message?.message ? error?.error?.message?.message : `${AppConstants.API_RESPONSE_MESSAGES.SOMETHING_WENT_WRONG}`
          this.toastService.error(msg)
          }
        } else if (
          error &&
          (error.status === 400 || error.status === 401) &&
          error.error &&
          error.error.message
        ) {
          if(error.status === 400 && this.skiptoaster?.includes(error.url.split('?')[0])){
            console.log('the error url',error.url.split('?')[0],this.skiptoaster?.includes(error.url.split('?')[0]));
          }else if (error.status === 401) {

            if (error?.error?.message.match("Login again")) {
              this.redirectWithDelete()
              this.toastService.error(
                AppConstants.TOASTER_STATUS.ERROR,
                `Token expired. Please Login again.`
              );
            }else if(error?.error?.type.match("authorization_error")){
              console.log(error?.error?.message);
              this.toastService.error("Insufficient permission")
              
            }
            else
              if (this.headerProvider.fetchHeaders()) {
                const { REFRESH_TOKEN } = this.headerProvider.fetchHeaders();
                if (REFRESH_TOKEN) {
                  return this.handleError(request, next, REFRESH_TOKEN);
                }
              } else {
                this.redirectWithDelete();
              }

          } else if (error && error?.error && error?.error?.message === 'Signature has expired' ||
            error && error.error && error.error.message && error.error.message.match('access-token missing in headers or is invalid')) {
            this.redirectWithDelete()
          }
          else {
            if(error.url.split('?')[0].includes('/goal/get/create/update/') && error.error.error_status.error_code == "70003" ){
              let [firstKey] = Object.keys(error.error.data)
              this.toastService.error(
                error.error.data[firstKey] ? error.error.data[firstKey] : AppConstants.ERROR_MESSAGE, `${AppConstants.TOASTER_STATUS.ERROR}`
              );
            }

            if(error.url.split('?')[0].includes('/messages/') ||error.url.split('?')[0].includes('/read-message/') ){

            } else if (error.url.includes('/watchlist/') && (error?.error?.message.toLocaleLowerCase()).match('no instruments added')) {
              // No instruments added
            } else if (!this.sikpUrl?.includes(error.url.split('?')[0]))
              this.toastService.error(
                error?.error?.message ? error?.error?.message : AppConstants.ERROR_MESSAGE,
                `${AppConstants.TOASTER_STATUS.ERROR}`
              );
          }

        } else if (error && (error.status === 502 || error.status === 503 || error.status === 504)) {
          // retry following APIs when click retry from a custom dialog if error is 503 or 504
          if (error?.url?.includes('/create_user/') ||
            error?.url?.includes('/verify_user_otp/') ||
            error?.url?.includes('/set_security_question/') ||
            error?.url?.includes('/user_main_details/') ||
            error?.url?.includes('/user_personal_details/') ||
            error?.url?.includes('/send_verification_link/') ||
            error?.url?.includes('/yes_bank_kyc/') ||
            error?.url?.includes('/resend_otp/')) {
              // retry until 3 times for above endpoints
              if (this.retryCount < 2) {
                this.retryCount++;
              } else {
                this.openInfoDialog('50x_error', request, next);
              }
          }
        } else if(error && error.status === 409){
          
         
        }
        else if(error && error.status === 429){
          this.toastService.error(
            AppConstants.TOASTER_STATUS.ERROR,
            error.error.message
          );

        }
        else {

          this.toastService.error(
            AppConstants.TOASTER_STATUS.ERROR,
            `${AppConstants.API_RESPONSE_MESSAGES.SOMETHING_WENT_WRONG}`
          );

        }
        return throwError(error);
      }),
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            if (count < this.retryCount && (error.status == 400 || error.status === 503 || error.status === 504)) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(500)
        )
      )
    );
  }

  addParams(params: HttpParams) {
    try {
      const { user_id } = this._storage.getItem('CUSTOMER_INFO')

      if (user_id) {
        params = params.append("user_id", user_id)
        params = params.append("application", "admin");
      }
    } catch (e) {

    }
    return params
  }

  private addToken(request: HttpRequest<any>, token: string) {

    let user_id = ""
    if (this._storage.getItem('CUSTOMER_INFO'))
      user_id = this._storage.getItem('CUSTOMER_INFO')?.user_id
    let reqBody = (request.method === "POST" || request.method == "PUT") && this.Mainurl.includes('/transact-behalf-of-customer') && !this.apisToSkip.includes(request.url) ? { ...request.body, user_id: user_id ? user_id : '', "application": "admin" } : request.body
    let reqParams = (request.method === "GET") && this.Mainurl.includes('/transact-behalf-of-customer') && !this.apisToSkip.includes(request.url) && !request.url.startsWith('https://d2tusfsbuyk9pk.') ? this.addParams(request.params) : request.params
    let newRequestBody= (request.method === "POST") &&  request.url.includes('/calculate/targets/')?{
      ...reqBody,
      timezone: 'Asia/Kolkata',
    }:reqBody

    if (request.url.startsWith('https://d2tusfsbuyk9pk.')|| request.url.startsWith('https://d2qswopkglay13.')) {
      return request.clone({
        body: reqBody,
        params: reqParams
      });
    } else {
      return request.clone({
        headers: request.headers.set('access-token', token),
        body: newRequestBody,
        params: reqParams
      });
    }
  }

  redirectWithDelete() {
    localStorage.clear();
    sessionStorage.clear();
    this.route.navigate(['/login'], { relativeTo: this._route });
  }


  private handleError(request: HttpRequest<any>, next: HttpHandler, token) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(token).pipe(
        switchMap((res: any) => {
          if (res && res['data'] && res['data']['access_token']) {
            const USER_INFO = localStorage.getItem('USER_INFO');
            let userInfo = this.crypto.decryptObj(USER_INFO);
            userInfo['access_token'] = res['data']['access_token'];
            localStorage.setItem('USER_INFO', this.crypto.encryptObj(userInfo));
            // this.toastService.success('Token has been refreshed successfully', 'Done');
            this.isRefreshing = false;
            this.refreshTokenSubject.next(res['data']['access_token']);
            return next.handle(
              this.addToken(request, res['data']['access_token'])
            );
          } else {
            this.redirectWithDelete()
          }
        }),
        catchError(err => {
          console.log("errrrrrr")
          if (err.status === 401) {
            this.redirectWithDelete()
            return throwError(err)
          } else {
            this.redirectWithDelete();
          }
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((tk) => {
          return next.handle(this.addToken(request, tk));
        })
      );
    }
  }

  openInfoDialog(key, request: HttpRequest<any>, next?: HttpHandler) {
    this.retryCount = 0;
    let header, message, buttonText, recall;
    if (key == '50x_error') {
      header = 'Network Error :('
      message = `We’ve encountered a network error. Please try again to continue.`
      buttonText = 'try again'
      recall = true;
    }
    const dialog = this._dialog.open(AnnouncementComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        onboard: false,
        header: header,
        message: message,
        buttonText: buttonText,
        recall: recall ? true : false
      }, disableClose: true
    })

    dialog.afterClosed().subscribe((res) => {
      console.log(res);
      if (res && recall) {
        // add retry key in the request payload data for yes bank kyc
        if (request.url.includes('/yes_bank_kyc/')) {
          let reqBody = request.body;
          reqBody['retry'] = true;
          request.clone({
            body: reqBody
          });
        }
        this.intercept(request, next).subscribe();
      }
    })
  }

}
