import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { AppConstants } from 'src/app/app.constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { HeaderProvider } from 'src/app/services/providers/headers';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordComponent implements OnInit {
  passwordMatched: boolean;
  private routeSub: Subscription;
  routeParams: any;
  isLoading: boolean;
  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private loader: NgxUiLoaderService,
    private sessionStorage: SessionStorageService,
    private header: HeaderProvider,
    private apiService: CrudService
    ) {
    }
  userForm = this.formBuilder.group({
    password: ['', [Validators.required, Validators.pattern(AppConstants?.REG_EXP?.PASSWORD)]],
    confirmPassword: ['', [Validators.required,]]
  });
  ngOnInit(): void {
    this,this.translate.setDefaultLang('en');
    try {
      this.routeParams = this.router.getCurrentNavigation().extras.state;
    } catch (e) { }
    console.log("route params", this.routeParams);
    // this.route.queryParams.forEach((params: any) => {
    //   // console.log(params);
    //   this.routeParams = params;
    // });
    let fpData = this.sessionStorage.getItem('forgotPasswordData');
    console.log("fp data", fpData)
    if (!fpData || !fpData['1'] || !fpData['1']?.request_id){
      this.router.navigateByUrl('/login');
    }
  }
  get form() {
    return this.userForm.controls;
  }

  checkPassword(form: any) {
    if (form && form?.value?.password == form?.value.confirmPassword) {
      this.passwordMatched = true;
    } else {
      this.passwordMatched = false;
    }
  }

  submitPassword(form: any){
    let fpData = this.sessionStorage.getItem("forgotPasswordData");
    if (form && form.valid) {
      if (this.passwordMatched && (fpData && fpData['1'] && fpData['1']?.request_id)) {
        let payload = {};
        payload['calling_sequence'] = 4;
        payload['one_fa_mode'] = fpData['1']?.one_fa_mode;
        payload['auth_type'] = fpData['1']?.auth_type;
        payload['request_id'] = fpData['1']?.request_id;
        payload['secret_question1'] = fpData['2']?.security_questions[0]?.question_id;
        payload['secret_question2'] = fpData['2']?.security_questions[1]?.question_id;
        payload['answer1'] = fpData['3']?.answer1;
        payload['answer2'] = fpData['3']?.answer2;
        payload['password'] = form?.value?.password;
        payload['confirm_password'] = form?.value?.confirmPassword;
        this.apiService.postData(`${AppConstants.COMMON_PORTS.DISTRIBUTOR_V2}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.forgotPassword}`, payload)
        .pipe(tap(() => this.isLoading = false))
        .subscribe(res => {
          console.log(res)
          if (res && res.status) {
            this.sessionStorage.removeItem("forgotPasswordData");
            this.header.fpOpenInfoDialog('passwordSet');
          } else {
            if (res?.error_status?.error_code == '30002') {
              this.toaster.error(res?.message);
              this.sessionStorage.removeItem('forgotPasswordData');
              this.router.navigateByUrl('/forgot-password');
            } else {
              this.toaster.error(res?.message);
            }
          }
        }, err => {
          try {
            if (err && err?.error?.error_status?.error_code == '11429') {
              this.header.fpOpenInfoDialog('fpManyAttempts');
            } else {
              this.toaster.error(err.error.message, 'Error');
            }
          } catch (e) {
            console.log("error", err);
          }
        })

      } else {
        this.passwordMatched = false;
        this.toaster.error(this.translate.instant('ts_changes.something_went_wrong'), 'Error');
      }
    }
  }

  showPasswordInfo: boolean;
  onStrengthChanged(event) {
    if (event && event == 100) {
      this.showPasswordInfo = false;
    } else {
      this.showPasswordInfo = true;
    }
  }
  changePassword(e: any) {
    this.showPasswordInfo = true;
    this.checkPassword(this.userForm);
  }
  goBack() {
    this.location.back();
  }
}
