import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  declarations: boolean;
  goalcal: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<AnnouncementComponent>,
    private _dialog: MatDialog,
    private router: Router,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data && this.data.header == 'declarations') {
      this.declarations = true;
      if (this.data && this.data.message == 'political') {
        this.data['header'] = 'PEP Declaration';
        this.data['message'] = `Politically exposed persons are individuals who are or have been entrusted with prominent public functions in a domestic / foreign country. Eg. Head of States / Central Government, Senior Politicians, Senior government / Judicial / military officers, Senior executives of state owned corporations, important political party official etc.`;
      } else if (this.data && this.data.message == 'citizen') {
        this.data['header'] = 'Indian Taxable Declaration';
        this.data['message'] = `I herby declare that I am a Citizen & National of India and only taxable in India as per the prevailing tax laws. I hereby declare that the information provided by me is true and I will be liable to inform the bank (YES Bank Ltd) within 30 days in case of any changes.`;
      } else if (this.data && this.data.message == 'yesb') {
        this.data['header'] = 'YES Bank Terms & Conditions';
      } else if (this.data && this.data.message == 'drivewealth') {
        this.data['header'] = 'DriveWealth Terms & Conditions';
      } else if (this.data && this.data.message == 'vkyc') {
        this.data['header'] = 'VKYC Terms & Conditions';
        this.data['message'] = `I hereby agree that I have no objection in authenticating myself with video call-based authentication for the purpose of completing my KYC in relation to opening of a bank account with YES BANK. By entering the OTP, I hereby provide my irrevocable consent to YES BANK for completing the KYC through Video Call.`;
      } else if (this.data && this.data.message == 'yesbOD') {
        this.data['header'] = 'YES BANK Onboarding declaration';
      }
    }
    if (this.data['from'] == 'goalcalculator') {
      this.goalcal = true;

    }
    console.log("declarations", this.declarations);
    console.log("config", this.data?.config);

  }

  close() {
    if (this.data?.oauth || this.data?.key == 'maxResendAttempt') {
      this.router.navigateByUrl('/customers');
      this._dialogRef.close(true);
      return;
    }
    if (this.data && this.data?.skipNominee || this.data?.recall) {
      this._dialogRef.close(true);
      return;
    }
    this._dialogRef.close()
  }
  success() {
    this._dialogRef.close(true)
  }

  exit() {
    if (this.data?.oauth) {
      this.router.navigateByUrl('/customers');
    }
    this._dialogRef.close()
  }

  supportTicket() {
    if (this.data && this.data.reupload) {
      // this.router.navigateByUrl('/support-tickets');
      // this.close();
      this._dialogRef.close('support-ticket')
    }
  }

  openInfoDialog(terms?:any) {
    let header, message, buttonText, img;
    header =  terms?.terms_title;
    message =  terms?.terms_content;
    console.log("terms", terms)
    const dialog = this._dialog.open(AnnouncementComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        onboard: false,
        header: header,
        message: message,
        buttonText: buttonText,
        imgSrc: img ? img : null,
        terms: terms
      }, disableClose: true
    })
  }

  forgotPasswordForm = this._formBuilder.group({
    phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(AppConstants.REG_EXP.NUMBER)]],
  });
  
  get form() {
    return this.forgotPasswordForm.controls;
  }

  sendOtp(form){
    this._dialogRef.close(form);
  }

}
