export let menu = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    permissions: [
      'as-admin-investment-summary-list',
      'as-admin-customer-list',
      'ai-news-get-headlines-create',
      'ams-chat-list',
      'ats-tasks-list',
      'ams-message-count-list',
      'as-admin-portfolio-performance-list',
      'cms-admin-user-create',
      'cms-admin-user-config-detail',
      'alms-leads-list',
    ],
    slugName: 'Dashboard',
    access: [

    ]
  }, 

  {
    title: 'Reports',
    slugName: 'Report',
    link: '/reports',
    access: [
      "upireport-readonly",
      "casatocasareport-readonly",
      "ifsccreditreport-readonly",
      "dwdepositreport-readonly",
      "dwwithdrawalreport-readonly",
      "withdrawalbatchreport-readonly",
      "filepaymentreport-readonly",
      "fileconstituentreport-readonly",
      "distributor-customer-onboarding-report-readonly",
      "sacreationreport-readonly",
      "outwardremittancereport-readonly",
      "inwardremittancereport-readonly",

    ],
  },

  {
    title: 'CMS',
    access: ["cms-list"],
    children: [
      {
        title: 'Category',
        link: '/cms/cms-category',
        slugName: 'Category',
        permissions: [
          'cms-category-list'
        ],
        access: [
          "cms-category-readonly",
          "cms-category-write",
          "cms-category-fullaccess"
        ]
      },
      {
        title: 'Notifications',
        link: '/cms/notifications',
        slugName: 'Notifications',
        permissions: [
          'ns-template-list',
          'ns-channel-list',
          'ns-action-list',
        ],
        access: [
          "notification-template-readonly",
          "notification-template-write",
          "notification-template-fullaccess"
        ]
      },
      {
        title: 'Posts',
        link: '/cms/posts',
        slugName: 'Posts',
        permissions: [
          'cms-post-list',
        ],
        access: [
          "cms-post-readonly",
          "cms-post-write",
          "cms-post-fullaccess"

        ]
      },
    ],
  },
  {
    title: 'Staff Management',
    children: [
      {
        title: 'List of Staff',
        link: '/staff-management/staff-list',
        slugName: 'List_of_Staff',
        permissions: [
          'ads-staff-list',
        ],
        access: [
          "staffmg-readonly",
          "staffmg-write",
          "staffmg-fullaccess"
        ]
      },
      {
        title: 'List of Roles',
        link: '/staff-management/list-roles',
        slugName: 'List_of_Roles',
        permissions: [
          'rbac-role-list',
        ],
        access: [
          "rbac-role-mgmt-fullaccess",
          "rbac-role-mgmt-write",
          "rbac-role-mgmt-readonly",
        ]
      },
    ],
  },
  {
    title: 'Distributor Management',
    children: [
      {
        title: 'List of Distributors',
        link: '/distributors/list-of-distributors',
        slugName: 'List_of_Distributors',
        permissions: [
          'ads-distributor-list'
        ],
        access: [
          "distributormg-fullaccess",
          "distributormg-write",
          "distributormg-readonly",
        ]
      },
      {
        title: 'Messages',
        link: '/distributors/messages',
        slugName: 'Messages',
        permissions: [
          'us-admin-all-users-list',
          'ads-staff-list',
          'ams-chat-list',
          'ams-chat-message-list',
          'ams-read-message-list',
        ],
        access: [
          "messages-readonly",
          "messages-write",
          "messages-fullaccess",
          "dist-messages-readonly",
          "dist-messages-write",
          "dist-messages-fullaccess",
          "staff-messages-readonly",
          "staff-messages-write",
          "staff-messages-fullaccess"
        ]
      },
      {
        title: 'Distributor Tasks',
        link: '/distributors/tasks',
        slugName: 'Distributor_Tasks',
        permissions: [
          'ats-tasks-list',
        ],
        access: [
          "taskmg-readonly",
          "taskmg-write",
          "taskmg-fullaccess"
        ]
      },
    ],
  },
  {
    title: 'Customer Management',
    children: [
      {
        title: 'Onboard Customer',
        slugName: 'Onboard_Customer',
        link: '/customers',
        permissions: [
          // 'us-admin-all-users-list',
          'us-admin-user-create'

        ],
        access: [
          "customer_list",
          "customermg-readonly",
          "customermg-write",
          "customermg-fullaccess",

        ]

      },
      {
        title: 'Transact on behalf of a customer',
        link: '/transact',
        slugName: 'Transact_on_behalf_of_a_customer',
        permissions: [
          'us-admin-all-users-list'
        ],
        access: [
          "transact-otp-readonly",
          "transact-otp-fullaccess",
          "transact-otp-write",
          "transact-readonly",
          "transact-fullaccess",
          "transact-write"
        ]
      }

    ],
  },
  {
    title: 'Grants',
    link: '/grants',
    slugName: 'grants',
    access: [
      "cashdeposit-monitoring-readonly",
    ]
  },
  {
    title: 'Money Flow',
    // link: '/money-flow',
    // slugName: 'Money_Flow',
    // permissions: [
    //   'as-admin-reports-cash-deposit-list',
    //   'oms-admin-withdrawals-get-details',
    //   'ads-drive_wealth-settlement_list',
    //   'oms-fees-list'
    // ],
    // access: [
    //   "moneyflow-list",
    //   "settlements-monitoring-readonly",
    //   "fee-monitoring-readonly",
    //   "cashdeposit-monitoring-readonly",
    //   "withdrawl-monitoring-readonly"
    // ],  "upireport-readonly",

    children: [
      {
        title: 'Cash Deposit',
        slugName: 'Cash_Deposit',
        link: '/money-flow/cash-deposit',
        access: [
          "cashdeposit-monitoring-readonly",
        ],
      },
      {
        title: 'Cash Withdrawal',
        slugName: 'Cash_Deposit',
        link: '/money-flow/cash-withdrawal',
        access: [
          "withdrawl-monitoring-readonly"
        ],
      },
      {
        title: 'Settlement',
        slugName: 'Cash_Deposit',
        link: '/money-flow/settlement',
        access: [
          "settlements-monitoring-readonly",
        ],
      },
      {
        title: 'Fee',
        slugName: 'Cash_Deposit',
        link: '/money-flow/fee',
        access: [
          "fee-monitoring-readonly",
        ],
      },
     
      {
        title: 'Batch Withdrawal',
        slugName: 'Batch_Withdrawal',
        link: '/money-flow/batch-withdrawal',
        access: [
          "withdrawl-monitoring-readonly"
        ]
      },
    //   {
    //     title: 'Report',
    //     slugName: 'Report',
    //     link: '/money-flow/report',
    //     access: [
    //       "moneyflow-list",
    //     ],
    // }
    ]
  },
  {
    title: 'Rebalancing Monitoring',
    link: '/rebalancing-monitoring',
    slugName: 'Rebalancing_Monitoring',
    permissions: [
      'oms-rebalancing-list-update-rebuy_portfolios'
    ],
    access: [
      "rebalancing-fullaccess"
    ]
  },
  {
    title: 'Order Monitoring',
    link: '/order-monitoring',
    slugName: 'Order Monitoring',
    permissions: [
      'oms-admin-order_monitoring-order-listing'
    ],
    access: [
      "order-monitoring-readonly"

    ]
  },

  {
    title: 'App configuration',
    children: [
      {
        title: 'Risk assessment questionnaire',
        link: '/app-configuration/questionnaire',
        slugName: 'Risk_assessment_questionnaire',
        permissions: [
          'us-admin-questions-list',

        ],
        access: [
          "riskssessmentq-readonly",
          "riskassessmentq-write",
          "riskassessmentq-fullaccess"
        ]
      },

      {
        title: 'Advance Task Configuration',
        link: '/app-configuration/task',
        slugName: 'Advance_Task_Configuration',
        permissions: [
          'ats-status-list',
          'ats-categories-list',
        ],
        access: [
          "task-configuration-list",
          "task-categorymg-readonly",
          "task-categorymg-write",
          "task-categorymg-fullaccess",
          "task-configuration-list",
          "task-statusmg-readonly",
          "task-statusmg-write",
          "task-statusmg-fullaccess"
        ]
      },
    ],
  },
  {
    title: 'Lead Management',
    link: '/lead-management',
    slugName: 'Lead Management',
    permissions: [
      'alms-leads-list'
    ],
    access: [
      "lead_mgmt-readyonly",
      "lead_mgmt-write",
      "lead_mgmt-fullaccess"
    ]
  },
  {
    title: 'Profile',
    link: '/profile-details',
    slugName: 'Profile',

    permissions: [
      'ads-user-list',
      'ads-user-documents-list',

    ],
    access: [
      "profile-fullaccess",
      "profile-write",
      "profile-readonly"
    ]
  },
  {
    title: 'Submit a support ticket',
    link: '/support-tickets',
    slugName: 'Submit_a_support_ticket',

    permissions: [
      'si-admin-support-issues-list',
      'si-support-issues-list',
    ],
    access: [
      "support_issue_mgmt_fullaccess",
      "support_issue_mgmt_write",
      "support_issue_mgmt_readonly"
    ]
  },

  {
    title: 'Documents Verification',
    children: [
      {
        title: 'KYC Documents',
        link: '/document-verification/kyc-document-list',
        slugName: 'KYC_Documents',
        permissions: [
          'us-list-updated-kyc-documents',
        ],
        access: [
          "kycdoc-readonly",
          "kycdoc-fullaccess"
        ]
      },
      {
        title: 'Staff Documents',
        link: '/document-verification/staff-document-list',
        slugName: 'Staff_Documents',
        permissions: [
          'ads-documents-list'
        ],
        access: [
          "staffdoc-readonly",
          "staffdoc-fullaccess"
        ]
      },
    ],
  },
];
