// app imports
import { environment } from './../environments/environment';
let BASE_URL = environment.URL;

export class AppConstants {
  public static readonly ENVIRONMENT: any = environment;
  public static readonly API_BASE_URL: string = environment.baseUrl;

  public static readonly MIN_VALIDATION: number = 100
  public static readonly ENC_KEY: string = 'APPRECIATE';

  public static readonly TYPE = {
    TEST: 'TYPE1',
  };

  public static readonly ERROR_MESSAGE =
    'Something Went Wrong.Please Try again Later';

  public static readonly USER_ROLES = {
    SUPER_ADMIN: 'Super User',
    ADMIN: 'ADMIN',
    DISTRIBUTOR: 'DISTRIBUTOR',
    STAFF: 'STAFF',
  };



  public static readonly USER_TYPES = {
    DISTRIBUTOR: 'distributor',
    COMPANY: 'company',
    STAFF: 'staff',
  };

  public static readonly DOC_TYPES = {
    GST: 'gst',
    PAN: 'pan',
    TAN: 'tan',
    NDA: 'nda',
    AUTH_LETTER: 'authLetter',
    AADHAAR: 'aadhaar',
  };

  public static readonly DOC_UPLOAD_MESSAGES = {
    gst: 'GST document has been successfully uploaded.',
    pan: 'PAN document has been successfully uploaded.',
    TAN: 'TAN document has been successfully uploaded.',
    NDA: 'NDA document has been successfully uploaded.',
    AUTH_LETTER: 'Authorization letter has been successfully uploaded.',
    AADHAAR: 'Aadhaar document has been successfully uploaded.',
  };

  public static readonly APPRECIATE_ID = 'appreciate_id'

  public static readonly WEB_APP_APIS = {
    //support ticket related apis are found here
    market: {
      status: '/rkd/market_timing/'
    },
    settlements: '/drive_wealth/settlement_list/',

    investmentSummary: {
      getInvestmentSummary: '/admin/investment_summary/'
    },
    mis_customer_details:{
      misCustomerFunnel:'/mis_customer_details/user_conversion_funnel/'
    },
    commissions: '/admin/commission_count_list/',
    registerCustomer: 'admin/customers_list/',
    WITHDRAWALS: {
      getAllWithDrawals: '/admin/withdrawals/'
    },
    SUPPORT_TICKET: {
      getTicketsListUrlForSelf:
        '/support-issues/?pageNumber=1&pageSize=10&pageinateDisableOverride=true',
      getTicketsListUrlForCustomer:
        '/admin-support-issues/?pageNumber=1&pageSize=10&pageinateDisableOverride=true',
      getIssueListUrl:
        '/issue-types/?pageNumber=1&pageSize=10&pageinateDisableOverride=true',
      postRaiseTicketUrlForSelf: '/support-issues/',
      postRaiseTicketUrlForCustomer: '/admin-support-issues/',
      getCustomerForDistributor: '/distributor/',
    },
    Report: {
      getReport: 'admin/reports/onboarded_customer/'
    },

    //news api's are found here
    NEWS: {
      getNewsHeadline: '/news/get_headlines',
      getStoryDetail: '/news/get_story/?StoryId'
    },

    //transact on behalf of customer apis are found here
    TRANSACT_ON_BEHALF: {
      AGENT_NOTES: {
        getpostAgentNotes: '/agent_note/'
      },
      GOALS: {
        easySavings: '/easy_savings/savings_configuration/',
        easysavingTransaction: '/easy_savings/transactions/',
        upcomingPayments: '/easy_savings/upcoming_payment/',
        paymentConfirmation: '/easy_savings/payment_confirmation/',
        getListOfGoalCategory: '/list/goal/category/',
        calculateTargets: '/calculate/targets/',
        toCreateUpdateGoals: '/goal/get/create/update/',
        getUserInvestedGoalsList: '/goal/list/',
        goalCount: '/get/goal_count/',
        deleteGoals: '/delete/goals/',
        unfullfilled_schedule: '/create/unfulfilled_schedule/',
        checkout: '/order/checkout/',
        pauseResumegoal: '/pause/resume/goal/',
        resumegoal: '/resume_goal_recommendation/',
        getRiskList: '/risk/',
        cancelGoal: '/cancel/goal/',
        goalCategroryholding: '/goal-category-holdings/',
        rkdConversion: '/rkd/convertion_rates/',
        getOrderList: '/order/list/',
        getPaymentStatus: '/list/goal/payment_staus/',
        getPaymentScheduledList: '/list/goal/payment_schedule/',
        skipGoalPayment: '/skip/goal_payment/',

        getCollegeList: '/list/college_types/',
        postGoalCalculatorRetirement: '/goal-calculator/',
        redeemAmount: '/redeem_fund/',
        targetCurrent: '/current/target/values/',
        cashBalance: '/get-cash-balance/',
        investedUserGraph: '/portfolio/performance/',
        profileGraph: '/admin/portfolio_performance/',
        clearUnpaid: '/clear/unpaid_payments/'
      },

      PRO_FRACTIONS: {
        top_picks: '/top_picks/',
        getCashFlow: '/get-cash-balance/',
        company_info: '/rkd/company_info/',
        events: '/rkd/events/',
        today_performance: '/rkd/daily_performance/',
        research_pdf: '/rkd/research_pdf/',
        financialTrend: '/rkd/financial_trend/',
        company_stats: '/rkd/get_stats/',
        watchList_pro: '/watchlist/',
        watchListAddRemove: '/watchlist/addremove/',
        getLatestHighlights: '/rkd/highlights/',
        getHighlights: '/rkd/business_summary/',
        getAnalystRating: '/rkd/IBESMean/',
        getAverageScore: '/rkd/AverageScore/',
        getPeerData: '/rkd/peer_data/',
        getIndicatorData: '/rkd/indicator/',
        getPriceVolumeDataPoints: '/rkd/data_points/',
        getEarningGraph: '/rkd/earnings/',
        intraDayGraph: '/v5/intraday/',
        interDayGraph: '/v5/interday/',
        interDayCache: '/interday_cache/',
        getOrderList: '/order/list/',
        getHoldings: '/get-holdings',
        getQuotes: '/rkd/get_quotes/',
        openTrades: '/order/list/',
        cashBalance: '/get-cash-balance/',
        cashTransactions: '/order/list/',
        cancelOrder: '/cancel/order/',
        orderStatusList: '/order_status/list/',
        orderDetails: '/order/details/',
        stockDetails: '/rkd/company/',
        dayTrade: '/is-day-trade-applicable/'
      },
      MYBYO: {
        listOfPortfolio: '/list/portfolio/',
        listOfMyBYO: '/list/my_byo/',
        portFolioDetails: '/get/portfolio-details/',
        searchStock: '/rkd/search_company/',
        topPicks: '/top_picks/',
        cashBalance: '/get-cash-balance/',
        createPortfolio: '/create/portfolio/user/',
        conversion: '/rkd/convertion_rates/',
        getPrices: '/rkd/get_quotes/',
        marketTiming: '/rkd/market_timing/',
        availableMoney: '/get-cash-balance/?product=fraction',
        reviewAndBuy: '/order/checkout/',
        getOrderDetails: '/order/list/',
        getParticularOrderDetails: '/order/details/'
      },
      CHANGE_SAVINGS: {
        data: '/admin/user_change_config',
        saveEasySavings: '/easy_savings/savings_configuration/',
        getChangeAccumulated: '/change_accumulated_list',
        change_accumulated: '/change_accumulated',
        getFilters: '/filters',
        changeTransfer: '/change_transfer',
        changeSavingSummary: '/change_saving_summary',
        config: '/change_investment_config',
        bankDetails: '/bank_source',
        bankActivate: '/activate_bank_source',
        bankDeactivate: '/deactivate_bank_source'

      },
      COMMON: {
        filterList: '/rkd/instrument_list/',
        getFee: '/get-applicable-fee/',
        getHoldingTimeSeries: '/get-holdings-time-series/',
        intraday_cache: '/intraday_cache/',
        converation_rate: '/card_rate/'
      },
    },
    VIEWSTATEMENT: {
      pdfgenerate: '/pdf-generate',
    },
    TASKS: {
      status: '/status/',
      category: '/categories/'
    },
    USER: {
      login: '/login/',
      refresh: '/refresh/',
      countryList: '/country/list/',
      createUser: '/admin/create_user/',
      sendOTP: '/admin/send_otp/',
      verifyOtp: '/admin/verify_user_otp/',
      securityQuestions: '/security_questions/list/',
      userQuestions: '/user_security_questions/',
      genderList: '/gender/list/',
      maritalStatusList: '/marital_status/list/',
      employmentStatusList: '/employment/list/',
      employmentIndustryList: '/employment_industry_list/',
      setPassword: '/admin/set_password/',
      setSecretQA: '/set_password/',
      sendPasswordLink: '/admin/send_set_password_link/',
      setSecretQuestions: '/admin/set_security_question/',
      updateUserDetails: '/user_main_details/',
      updatePersonalDetails: '/user_personal_details/',
      updateEmail: '/update_email/',
      profileCountList: '/admin/profile_count_list/',
      kycList: '/kyc_component/list/',
      kycAccountList: '/kyc_account_list/',
      addressList: '/address_list/',
      residentialList: '/residential_list/',
      nationality: '/nationality',
      residenceType: '/residence_type',
      salutation: '/salutation',
      motherSalutation: '/mother_salutation/',
      placeOfBirth: '/place_of_birth',
      countryOfBirth: '/country_of_birth',
      relationshipList: '/relationship_list',
      guardianRelationshipList: '/list/minor_guardian_rel/',
      annualIncomeList: '/annual_income/list/',
      eduList: '/list/edu_qualification/',
      occupationTypeList: '/list/occupation_type/',
      subOccupationTypeList: '/list/sub_occupation/',
      sourceOfFundList: '/list/source_of_fund/',
      branchList: '/list/branch/',
      professionList: '/list/profession/',
      natureOfBusinessList: '/list/nature_of_business/',
      typeOfCompanies: '/list/type_of_company/',
      employer: '/list/employed_with/',
      typeOfIndustries: '/type_of_industry',
      customerAddress: '/user_address/',
      uploadKyc: '/update_kyc_document/',
      uploadYesBankKyc: '/yes_bank_kyc/',
      verifyKyc: '/verify_updated_document/',
      panApply: '/pan_mail',
      customers: '/admin/user_list/',
      allCustomers: '/admin/all_users_list/',
      customerConfig: '/admin/user_config/',
      customerPreview: '/user_info_preview/',
      validatePincode: '/validate_pincode/',
      resendEmailLink: '/admin/send_verification_link/',
      customerDetails: '/admin/user_details/',
      assignDistributor: '/assign_distributor/',
      validateUserQuestionsAnswer: '/admin/validate_security_ques/',
      noOfChildren: '/number_of_children/list/',
      docuementVerification: '/admin/verify_user_document/',
      userBankAccounts: '/user_bank_accounts/',
      customerGlobalSearch: '/customer_global_search/',
      updateCustomer: {
        profile: '/user_profile_details/',
        personalDetails: '/admin/update_user_profile/',
        income: '/update/annual_income',
        maritalStatus: '/update/marital_status',
        employmentStatus: '/update/employment_status',
        noOfChildren: '/update/number_of_children',
        email: '/admin/update_user_email/',
        phone: '/admin/update_user_mobile/',
        secretQuestion: '/admin/update_user_security_ques/',
        secretQuestionUpdated: '/update/secret_question/',
        mobile: '/update/mobile_number/',
        emailId: '/update/email_id/',
      },
      resendLink: '/resend/verify-email',
      terms_acceptance: '/create/user/terms/acceptance/',
      storeQuestionAnswer:'/store/question/answer/'
    },
    MESSAGES: {
      read: '/read-message/'
    },
    DISTRIBUTOR: {
      states: '/states/',
      staff: '/distributor/',
      users: '/users/',
      user: '/user/',
      userDetails: '/user_details/',
      bulkDelete: '/user/bulk_delete/',
      bulkDisable: '/user/bulk_disable/',
      updateDocument: '/update/documents/',
      companyDetails: '/company-details/',
      userDocuments: '/user-documents/',
      id: '/get-distributor-id/',
      details: '/distributor-details/',
      verifyPhone: '/verify_phone/',
      forgotPassword: '/staff/forgot-password/',
    },
    ROLES: {
      roles: '/roles/',
      users: '/users/',
    },
    RESEARCH: {
      researchAndPerspective: '/post'
    },

    DEV: BASE_URL,
    OTP: {
      sendOTP: '/send_otp/',
      verifyOTP: '/verify_otp/',
      resendOTP: '/resend_otp/'
    },

    riskAssessment: {
      questions: '/admin/questions/',
      answer: '/admin/options/',
      riskAssessment: '/risk_assessment/',
      order_details: '/order/details/',
      order_list: '/order/list/'
    },
    messages: {
      count: '/analytics/messages-count/'
    },
    PAYMENTS: {
      paymentLink: '/send/payment_link',
      distributorAssign: '/send/distributor_assign_link/'

    },
    REBALANCING: {
      rebalancing_list: '/rebalancing/list/update/rebuy_portfolios/'
    },
    ORDER_MONITORING: {
      orderMonitoring_list: '/admin/reports/cash_deposit/'
    },
    CHANGE_SAVINGS: {
      change_investment_config: '/change_investment_config',
      saving_detail: '/saving_details',
      send_link: "/admin/send_sms_permission_link"
    }
  };

  public static readonly COMMON_PORTS = {
    TICKETS: `${AppConstants.WEB_APP_APIS.DEV[5030]}/v1`,
    DISTRIBUTOR: `${AppConstants.WEB_APP_APIS.DEV[5021]}/v1`,
    DISTRIBUTOR_V2: `${AppConstants.WEB_APP_APIS.DEV[5021]}/v2`,
    GOALS: `${AppConstants.WEB_APP_APIS.DEV[5016]}/v1`,
    GOALS_V2: `${AppConstants.WEB_APP_APIS.DEV[5016]}/v2`,
    GOALS_V3: `${AppConstants.WEB_APP_APIS.DEV[5016]}/v3`,
    USER: `${AppConstants.WEB_APP_APIS.DEV[5003]}/v1`,
    USER_V2: `${AppConstants.WEB_APP_APIS.DEV[5003]}/v2`,
    USER_V3: `${AppConstants.WEB_APP_APIS.DEV[5003]}/v3`,
    USER_V4: `${AppConstants.WEB_APP_APIS.DEV[5003]}/v4`,
    ORDERS: `${AppConstants.WEB_APP_APIS.DEV[5006]}/v1`,
    ORDERS_V1: `${AppConstants.WEB_APP_APIS.DEV[5006]}/v1`,
    ORDERS_V2: `${AppConstants.WEB_APP_APIS.DEV[5006]}/v2`,
    ORDERS_V3: `${AppConstants.WEB_APP_APIS.DEV[5006]}/v3`,
    GOAL_CATEGORY_HOLDINGS: `${AppConstants.WEB_APP_APIS.DEV[5006]}/v2`,
    CONVERTION: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v1`,
    INSTRUMENT: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v2`,
    INSTRUMENT_V3: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v3`,
    INTRADAY: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v3`,
    INSTRUMENT_V4: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v4`,
    BYO: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v1`,
    BYO_V2: `${AppConstants.WEB_APP_APIS.DEV[5004]}/v2`,
    PAN_APPLY: `${AppConstants.WEB_APP_APIS.DEV[5022]}/v1`,
    OTP_v1: `${AppConstants.WEB_APP_APIS.DEV[5012]}`,
    OTP_v2: `${AppConstants.WEB_APP_APIS.DEV[5012]}/v2`,
    GRAPHS: `${AppConstants.WEB_APP_APIS.DEV[5031]}/v1`,
    CHANGE_SAVINGS: `${AppConstants.WEB_APP_APIS.DEV[5009]}/v1`,
    CHANGE_SAVINGS_V2: `${AppConstants.WEB_APP_APIS.DEV[5009]}/v2`,
    AUTH: `${AppConstants.WEB_APP_APIS.DEV[5020]}/v1`,
    ROLES: `${AppConstants.WEB_APP_APIS.DEV[5017]}/rbac`,
    RKD_USERS_API: `${AppConstants.WEB_APP_APIS.DEV[5014]}/v1`,
    RKD_USERS_API_V2: `${AppConstants.WEB_APP_APIS.DEV[5014]}/v2`,
    POSTS: `${AppConstants.WEB_APP_APIS.DEV[5011]}/v1`,
    TASKSTATUS: `${AppConstants.WEB_APP_APIS.DEV[5019]}/v1`,
    MESSAGESERVICE: `${AppConstants.WEB_APP_APIS.DEV[5018]}/v1`,
    MESSAGES: `${AppConstants.WEB_APP_APIS.DEV[5018]}/v1`,
    VIEWSTATEMENT: `${AppConstants.WEB_APP_APIS.DEV[5026]}/v1`,
    REBALANCING: `${AppConstants.WEB_APP_APIS.DEV[5032]}/v1`,
    LOGS:`${AppConstants.WEB_APP_APIS.DEV['logs']}/v1`,
  };

  public static readonly ROLES = {
    ADMIN: ['super user', 'super admin', 'admin'],
    ADMIN_AND_STAFF: [
      'super user',
      'super admin',
      'admin',
      'super admin staff',
      'admin staff',
    ],
    ADMIN_DISTRIBUTOR: ['super user', 'super admin', 'admin', 'distributor'],
    DISTRIBUTOR: ['distributor'],
    DISTRIBUTOR_AND_STAFF: ['distributor', 'distributor staff'],
  };

  public static readonly TOASTER_STATUS = {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    ERROR: 'Failed',
  };
  public static readonly REG_EXP = {
    GST: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
    PAN: '^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$',
    TAN: '^[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}$',
    AADHAAR: '^[0-9]{4}?[0-9]{4}?[0-9]{4}$',
    MASKED_AADHAAR: '^[0-9*]{4}?[0-9*]{4}?[0-9]{4}$',
    TEXT: /^[a-zA-Z ]*$/,
    TEXT_STATE: /^[a-zA-Z &]*$/,
    TEXTWITHDOT: /^[a-zA-Z. ]*$/,
    ALPHANUMERIC: /^[A-Za-z. 0-9-]*$/,
    ALPHANUMERIC_ADV: /^[A-Za-z 0-9]*$/,
    NUMBER: /^\d+$/,
    FRACTION: /^\d*\.?\d*$/,
    EMAIL: '(?=^.{6,40}$)[a-z0-9._%+-]+@[a-z0-9.]+[.][a-z]{2,}$',
    EMAIL_CUSTOMER: '(?=^.{6,40}$)[a-z0-9._%-]+@[a-z0-9.]+[.][a-z]{2,}$',
    // EMAIL: '[a-z0-9._%+-]+@[a-z0-9.]+[.]+[com|org]{3,}|[cc]{2}$',
    MOBILE: '^[6-9]{1}[0-9]{9}$',
    PASSWORD: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$',
    // Mobilewith91: /^([+]{0,2}(91))[6-9]\d{9}$/
    Mobilewith91: '^((\\+91-?)|0)?[6-9]{1}[0-9]{9}$'
  };

  public static readonly CUSTOMER_VALIDATIONS = {
    GENDER: {
      MALE: {
        NOT_ALLOWED_SALUTATION_LIST: ['ms', 'mrs', 'smt']
      },
      FEMALE: {
        NOT_ALLOWED_SALUTATION_LIST: ['mr', 'master', 'shri']
      }
    },
    NOMINEE: {
      ALLOWED_SAME_DOB_RELATION_LIST: ['brother', 'sister', 'husband', 'wife', 'friend', 'cousin', 'niece', 'nephew'],
      ELDER_LIST: ['father', 'mother', 'grandfather', 'grandmother', 'mother-in-law', 'father-in-law'],
      UNMARRIED_RELATION_LIST: ['husband', 'wife', 'mother-in-law', 'father-in-law', 'brother-in-law', 'sister-in-law']
    }
  }

  public static readonly UPLOAD_KYC_PAYLOAD = {
    TYPE: {
      'aadhaar': 'Adhar',
      'pan': 'PAN',
      'address': 'POA',
      'sign': 'signature',
      'photo': 'photo',
      'video': 'video_verification',
      'bank': 'bankAccount',
      'personalInfo': 'personal_info',
      'savings': 'sa_account',
      'nominee': 'nominee_details',
      'vpa': 'vpa_details',
      'vpaConfirmation': 'vpa_confirmation',
      'initialDeclaration': 'initial_declaration',
      'dataDeclaration': 'data_declaration',
      'addressUpdate': 'additional_info_update',
      'funding': 'funding',
      'upi_confirmation': 'upi_confirmation',
      'ybl_payment_confirmation': 'ybl_payment_confirmation',
      'yes_bank_confirmation': 'yes_bank_confirmation',
      'sa_creation': 'sa_creation',
      'account_creation': 'account_creation',
      'collect': 'CollectRequest'
    },
    SCREEN_TYPES: {
      PAN: 'pan_upload',
      PAN_DW: 'dw_pan_upload',
      PAN_UPDATE: 'pan_update',
      PAN_UPDATE_DW: 'dw_pan_update',
      INIT: 'initial_declaration',
      AADHAAR_DECLARE: 'aadhaar_declaration',
      AADHAAR: 'aadhaar_upload',
      ETB_AADHAAR: 'etb_aadhaar_upload',
      ETB_AADHAAR_UPDATE: 'etb_aadhaar_update',
      OAUTH: 'oauth',
      AADHAAR_UPDATE: 'aadhaar_update',
      AADHAAR_VERIFY: 'aadhaar_verification',
      PERSONAL_INFO: 'personal_info_update',
      POA: 'poa_upload',
      // POA_UPDATE: 'poa_update',
      POA_UPDATE: 'additional_info_update',
      NOMINEE: 'nominee_details',
      VPA: 'vpa_details',
      DATA_DECLATE: 'data_declaration',
      SIGN: 'signature_upload'
    },
    HEADERS: {
      'Content-Type': 'multipart/form-data'
    },
    CALLING_SEQUENCE: '3',
    PAN: {
      component_id: '75b4081f-4b29-48f4-8234-5df6b60978d1',
      component_name: 'PAN',
      screen_type: 'PAN_upload',
      yesbank_screen_type: 'pan_upload',
      UPDATE: {
        screen_type: 'PAN_update',
      },
      new_document: 'individualPan',
    },
    AADHAAR: {
      component_id: '12df5116-a730-4dd7-b7b6-942a4c1622c2',
      component_name: 'Adhar',
      screen_type: 'ADHAR_upload',
      yesbank_screen_type: 'aadhaar_upload',
      yesbank_screen_type_update: 'aadhaar_update',
      verification: 'aadhaar_verification',
      UPDATE: {
        screen_type: 'ADHAR_update',
      },
      new_document: 'aadhaar',
      declaration: 'aadhaar_declaration',
      resend: {
        'screen_type': 'resend_aadhaar_otp'
      }
    },
    ADDRESS: {
      component_id: '5fccfe6a-19ca-4d1c-a1b5-37c78f122d9d',
      component_name: 'POA',
      screen_type: {
        DL: 'DrivingLicense_upload',
        PP: 'Passport_upload'
      },
      UPDATE: {
        screen_type: {
          DL: 'DrivingLicense_update',
          PP: 'Passport_update'
        },
      },
      new_document: {
        DL: 'drivingLicence',
        PP: 'passport'
      },
      document: 'poa_upload'
    },
    SIGN: {
      component_id: 'c2af2651-2ffc-4144-a5ee-cd8d3b9b7153'
    },
    INIT: {
      document: 'initial_declaration'
    }
  };
  public static readonly AUTH_MESSAGES = {
    SUCCESS: {
      LOGIN: 'Congratulations! Login Successful.',
      FORGOT_PASSWORD:
        "Congratulation! You'll receive an email to reset your password.",
      RESET_PASSWORD: 'Congratulations! Your password resets successfully.',
    },
    ERROR: {
      INVALID_LOGIN_DETAILS: 'Login details are not valid.',
      AUTH_ERROR: 'Access denied. Please login again.',
      CONNECTION_FAILED: 'Please check your internet connection.',
    },
  };

  public static readonly API_RESPONSE_MESSAGES = {
    SOMETHING_WENT_WRONG: 'Sorry, something went wrong. Please try again.',
    LOGIN: {
      SUCCESS: 'User logged in successfully.',
      INVALID_DETAILS: 'Invalid user credentials.',
    },
    FORGOT_PASSWORD: {
      INVALID_DETAILS: 'Invalid user details.',
      SUCCESS_INFO:
        'OTP has been sent to registered email or phone number.',
    },
    RESEND_OTP: 'OTP has been sent to registered email or phone number',
    ROLES: {
      ROLES_NOT_FOUND: 'Role has not been assigned for this user.',
      PERMISSIONS_NOT_FOUND: 'Permissions are not added.',
    },
    GOALS: {
      MINUSAMOUNT: 'Amount should be greater than 0'
    },
    CONFIG: {
      KYC_NOT_COMPLETED: 'Your KYC is not completed.'
    }
  };

  public static readonly LOCAL_STORAGE = {
    USER_INFO: 'USER_INFO',
    ROLES: 'ROLES',
    PERMISSIONS: 'PERMISSIONS',
    USER_CONFIG: 'USER_CONFIG',
  };

  public static readonly PERMISSIONS = {
    TASKS: {
      LIST: 'tasks-list',
      STATUS_LIST: 'task-status-list',
      CATEGORIES_LIST: 'task-categories-list',
      PRIORITIES_LIST: 'task-priorities-list',
      CREATE: 'task-create',
      UPDATE: 'task-update',
      UPDATE_STATUS: 'task-update-status',
      DELETE: 'task-delete',

    },
    MESSAGES: {
      CHATS: 'chats',
      SEND_MESSAGE: 'send-message',
    },
    LEADS: {},
  };

  public static readonly NOTIFICATION_TEXT_LENGTH_DISPLAY_TIME_CONFIG = [
    { lengthkey: '0-15', displaytime: 3000 },
    { lengthkey: '15-25', displaytime: 4000 },
    { lengthkey: '25-35', displaytime: 6000 },
    { lengthkey: '35 and more', displaytime: 7000 },
  ];

  public static readonly ONBOARD_ERROR_MESSAGES = {
    SIGNUP: {
      10001: 'User is already registered with these details. Please login to continue.',
    },
    SIGNUP_OTP_VERIFICATION: {
      30004: 'OTP expired, please try again.',
      30002: 'Something went wrong. Please reattempt signup.'
    },
    BASIC_DETAILS: {
      10017: 'Email ID already exists.',
    },
    PERSONAL_DETAILS: {

    }

  }
}
