import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customMasking',
})
export class CustomMaskingPipe implements PipeTransform {
  transform(value: any, type: any): any {
    if (value) {
      if (type === 'email') {
        return value.replace(
          /^(.)(.*)(.@.*)$/,
          (_, a, b, c) => a + b.replace(/./g, '*') + c
        );
      }
      if (type === 'phone') {
        return value.replace(/\d(?=\d{4})/g, '*');
      }
      if (type === 'pan') {
        return (value = `${value.substr(0, 2)}${'*'.repeat(6)}${value.substr(
          value.length - 2,
          value.length
        )}`);
      }
      if (type === 'aadhaar') {
        return (value = `${'*'.repeat(8)}${value.substr(
          value.length - 4,
          value.length
        )}`);
      }
      if (type === 'dl') {
        return (value = `${'*'.repeat(6)}${value.substr(
          value.length - 6,
          value.length
        )}`);
      }
      if (type === 'account') {
        return value.replace(/.(?=.{4})/g, 'x')
      }

      if (type === 'sign') {
        return Math.abs(value)
      }
      return value;
    }

    return value;
  }
}
