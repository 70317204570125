// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IMqttServiceOptions } from "ngx-mqtt";

export const environment = {
  production: false,
  // baseUrl:"http://appreciate.com",
  baseUrl: "http://13.235.156.97",
  MQTT_SERVICE_OPTIONS:{
    hostname: 'appreciate-mqtt-ws.pacewisdom.in',
    port: 8083,
    path: '',
    clean: true, // Retain session
    connectTimeout: 4000, // Timeout period
    reconnectPeriod: 10000, // Reconnect period
    protocol: 'ws',
  }as IMqttServiceOptions,
  mqttBaseUrl:'appreciate-mqtt-ws.pacewisdom.in',
  URL: {
    5000: 'https://appreciate-user-social-services-dev.pacewisdom.in',
    5001: 'https://',
    5002: 'https://appreciate-signzy-wrappers-dev.pacewisdom.in',
    5003: 'https://appreciate-user-services-dev.pacewisdom.in',
    5004: 'https://appreciate-app-instruments-dev.pacewisdom.in',
    5005: 'https://appreciate-drive-wealth-wrappers-dev.pacewisdom.in',
    5006: 'https://appreciate-order-management-dev.pacewisdom.in',
    5007: 'https://appreciate-pro-order-service-dev.pacewisdom.in',
    5008: 'https://appreciate-ephemeral-link-dev.pacewisdom.in',
    5009: 'https://appreciate-change-investment-app-dev.pacewisdom.in',
    5010: 'https://appreciate-notification-app-dev.pacewisdom.in',
    5011: 'https://appreciate-cms-module-dev.pacewisdom.in',
    5012: 'https://appreciate-otp-module-dev.pacewisdom.in',
    5013: 'https://appreciate-user-social-invite-dev.pacewisdom.in',
    5014: 'https://appreciate-rkd-user-app-dev.pacewisdom.in',
    5015: 'https://appreciate-static-assets-application-dev.pacewisdom.in',
    5016: 'https://appreciate-goal-management-dev.pacewisdom.in',
    5017: 'https://appreciate-rbac-dev.pacewisdom.in',
    5018: 'https://appreciate-admin-messaging-service-dev.pacewisdom.in',
    5019: 'https://appreciate-admin-task-service-dev.pacewisdom.in',
    5020: 'https://appreciate-uim-dev.pacewisdom.in',
    5021: 'https://appreciate-admin-distributor-service-dev.pacewisdom.in',
    5022: 'https://appreciate-pancard-application-dev.pacewisdom.in',
    5024: 'https://appreciate-admin-lead-management-dev.pacewisdom.in',
    5030: 'https://appreciate-sifi-dev.pacewisdom.in/',
    5031: 'https://appreciate-performance-api-dev.pacewisdom.in',
    5026: 'https://appreciate-pdf-generator-backend-dev.pacewisdom.in',
    logs: 'https://appreciate-audit-logs-api-dev.pacewisdom.in'

  },
  env: 'dev',
  //for admin
  isAdmin:
    [
      {
        title: 'Dashboard',
        link: '/dashboard',
      },

      // {
      //   title: 'Find Order',
      //   link: '/find-order',
      // },
      // {
      //   title: 'Advance Configuration',
      //   children: [
      //     {
      //       title: 'Task',
      //       link: '/advance-configuration/task',

      //     },
      //     {
      //       title: 'Category',
      //       link: '/advance-configuration/category',
      //     },

      //   ],
      // },
      {
        title: 'CMS',
        children: [
          {
            title: 'Category',
            link: '/cms/cms-category',

          },
          {
            title: 'Notifications',
            link: '/cms/notifications',
          },
          {
            title: 'Posts',
            link: '/cms/posts',
          },
        ],
      },
      {
        title: 'Staff Management',
        children: [
          {
            title: 'List of Staff',
            link: '/staff-management/staff-list',
          },
          {
            title: 'List of Roles',
            link: '/staff-management/list-roles',
          },
          {
            title: 'Role Mapping',
            link: '/role-mapping',
          },
          {
            title: 'Staff Activity',
            link: '/staff-management/staff-activity',
          },
          {
            title: 'Approval Queue',
            // link: '/staff-management/approval-queue',

          },
        ],
      },
      {
        title: 'Distributor Management',
        children: [
          {
            title: 'List of Distributors',
            link: '/distributors/list-of-distributors',
          },
          {
            title: 'Messages',
            link: '/distributors/messages',
          },
          {
            title: 'Distributor Tasks',
            link: '/distributors/tasks',
          },
        ],
      },

      {
        title: 'Customer Management',
        children: [
          {
            title: 'Onboard Customer',
            link: '/customers/'
          },
          {
            title: 'Transact on behalf of a customer',
            link: '/transact',
          },
          {
            title: 'Messages',
            link: '/customerManagement/messages',
          },
        ]
      },

      {
        title: 'Money Flow',
        link: '/money-flow',
      },
      {
        title: 'Rebalancing Monitoring',
        link: '/rebalancing-monitoring',
      },
      {
        title: 'Order Monitoring',
        link: '/order-monitoring',
      },
      // {
      //   title: 'View Portfolio and Customers',
      //   link: '/view-portfolio-and-customers'
      // },
      {
        title: 'App configuration',
        children: [
          {
            title: 'Risk assessment questionnaire',
            link: '/app-configuration/questionnaire',
          },
          {
            title: 'Stock & Industrial list',
            link: '/app-configuration/stock-list',
          },
          {
            title: 'Risk score',
            link: '/app-configuration/risk-score',
          },
          {
            title: 'Risk score to grow portfolio',
            link: '/app-configuration/risk-score-grow',
          },
          {
            title: 'Threshold for change transfer',
            link: '/app-configuration/threshold-changetransfer',
          },
          {
            title: 'Legal policies',
            link: '/app-configuration/legal-policies',
          },
          {
            title: 'Appreciate contact details',
            link: '/app-configuration/appretiate-contact-details',
          },
          {
            title: 'Advance Task Configuration',
            link: '/app-configuration/task',
          },
        ],
      },
      {
        title: 'Securities configuration',
        // children: [
        //   {
        //     title: 'List of allowed fraction stocks',
        //     link: '/security configuration/allowed-fraction-stock-list',
        //   },
        //   {
        //     title: 'List of allowed pro stocks',
        //     link: '/security configuration/list-allowed',
        //   },
        //   {
        //     title: 'Tags for Securities',
        //     link: '/security configuration/tags-for-securities',
        //   },
        //   {
        //     title: 'Securities held by customers',
        //     link: '/security configuration/securities-customer',
        //   },
        // ],
      },
      // {
      //   title: ' Settlements',
      //   link: '/settlement',
      // },
      {
        title: 'Profile',
        link: '/profile-details'
      },
      {
        title: 'Submit a support ticket',
        link: '/support-tickets',
      },
      {
        title: 'Withdrawals',
        children: [
          {
            title: 'All Withdrawals',
            link: '/withdrawals/all-withdrawals'
          }
        ]
      },
      {
        title: 'Documents Verification',
        children: [
          {
            title: 'KYC Documents',
            link: '/document-verification/kyc-document-list',
          },
          {
            title: 'Staff Documents',
            link: '/document-verification/staff-document-list',
          },
        ],
      },
      {
        title: 'Lead Management',
        // link: '/lead-management',
      },
      {
        title: 'Alerts',
        // link: '/alerts',
      },
      {
        title: 'Customer info management',
        link: '/customer-info',
      },
      {
        title: 'Transactions and activity',
        link: '/transactions',
      },
      {
        title: 'Fee and tax',
        // children: [
        //   {
        //     title: 'Fee management',
        //     link: '/fee-tax-management/fee-management',
        //   },
        //   {
        //     title: 'Tax management',
        //     link: '/fee-tax-management/tax-management',
        //   },
        // ],
      },
      {
        title: `Rewards / coupons`,
        children: [
          {
            title: 'List of Rewards',
            link: '/rewards-coupons/rewards'
          },
          {
            title: 'Rewards Management',
            link: '/rewards-coupons/rewards-management'
          },
          {
            title: 'Template management',
            link: '/template',
          },
          {
            title: 'Campaign management',
            link: '/campaign',
          },
          {
            title: 'Target customer group Management',
            link: 'target',
          },
        ],
      },

      {
        title: ' Notification And Alerts',
        link: '/notification-alert',
      },
      {
        title: 'Products',
        children: [
          {
            link: '/goals',
            title: 'Goals',
          },
          {
            title: 'Pro',
            link: '/pro',
          },
          {
            title: 'Fractions',
            link: '/fractions',
          },
          {
            title: 'Change round-up',
            link: '/change-round-up',
          },
        ],
      },
      // {
      //   title: 'Trade on behalf of a customer',
      //   link: '/trade',
      // },
      {
        title: ' Notifications',
        children: [
          {
            link: '/notifications',
            title: 'Notification Templates',
          },
          {
            title: 'Ad hoc notification broadcast',
            link: '/ad',
          },
          {
            title: 'Automated notifications',
            link: '/automated',
          },
          {
            title: 'Notification activity',
            link: '/notification',
          },
        ],
      },
    ],
  //for distributor
  notAdmin:
    [{
      title: 'Dashboard',
      link: '/dashboard'
    },
    // {
    //   title: 'Find Order',
    //   link: '/find-order',
    // },
    // {
    //   title: 'Staff Management',
    //   link: '/staff-list'
    // },
    {
      title: 'Messages',
      link: '/messages'
    },
    // {
    //   title: 'Tasks',
    //   link: '/tasks'
    // },
    {
      title: 'Onboard Customer',
      link: '/customers/'
    },
    {
      title: 'Transact on behalf of a customer',
      link: '/transact',
    },
    // {
    //   title: 'Lead Management',
    //   link: '/lead-management'
    // },
    // {
    //   title: 'View Portfolio and Customers',
    //   link: '/view-portfolio-and-customers'
    // },
    // {
    //   title: 'Recommendations Generator',
    //   link: '/recommendations-generator'
    // },
    {
      title: 'Submit a support ticket',
      link: '/support-tickets'
    },
    {
      title: 'Profile',
      link: '/profile'
    },
      //  {
      //   title: 'Send alerts and notifications',
      //   link: '/send-alert'
      // }
    ]


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stac k frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
