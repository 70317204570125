import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomAlertBoxComponent } from 'src/app/components/custom-alert-box/custom-alert-box.component';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';

@Injectable({
  providedIn: 'root'
})
export class AllowExitGuard implements CanDeactivate<any>{

  constructor(private _dialog: MatDialog, private _storage: SessionStorageService) {
  }

  canDeactivate( component: any, currentRoute: ActivatedRouteSnapshot,currentState: RouterStateSnapshot,nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this._storage.getItem('CUSTOMER_INFO')?.user_id) {
      if(
        nextState?.url.includes('transact-behalf-of-customer') ||
        nextState?.url.includes('customers') ||
        nextState?.url.includes('onboard-customer?userId') ||
        nextState?.url.includes('login')
      ) {
        return true;
      } else {
        return this.callPopup().pipe(
          tap((res) => {
            res && this._storage.removeItem('CUSTOMER_INFO')
            res && this._storage.removeItem('INDIAN_RUPEE')
          }),
          map(res => res ? res : false))
      }
    } else {
      return true;
    }
  }

  callPopup(): Observable<any> {
    return this._dialog.open(CustomAlertBoxComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        content: 'You are about to exit from this customer’s profile, you will have to reauthenticate to access it.',
        title: 'Are you sure?',
        successButton:'okay',
        cancelButton:'cancel'
      }
    }).afterClosed()
  }

}
