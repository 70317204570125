import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { HeaderProvider } from 'src/app/services/providers/headers';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { AppConstants } from 'src/app/app.constants';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.scss']
})
export class SecurityQuestionComponent implements OnInit {
  secretQADetails: any;
  isLoading: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private header: HeaderProvider,
    private apiService: CrudService,
    private toaster: ToastrService,
  ) { 

  }
  ngOnInit(): void {
    let fpData = this.sessionStorage.getItem('forgotPasswordData');
    console.log("fpdata", fpData);
    this.secretQADetails = fpData && fpData['2'] && fpData['2']?.security_questions && fpData['2']?.security_questions[0]?.question_id ? fpData['2']?.security_questions : '';
    if (!fpData || !fpData['1'] || !fpData['1']?.request_id){
      this.router.navigateByUrl('/login');
    }
  }

  secretQAForm = this.formBuilder.group({
    secretQ1: ['', []],
    secretA1: ['', [Validators.required]],
    secretQ2: ['', []],
    secretA2: ['', [Validators.required]],
  });

  get form() {
    return this.secretQAForm.controls;
  }

  goBack() {
    this.location.back();
  }

  verifySecretQA(form){
    console.log("form", form);
    let data = {};
    // this.sessionStorage.setItem('SET_PASSWORD', data);
    let fpData = this.sessionStorage.getItem('forgotPasswordData')
    console.log("fpdata", fpData)
    let payload = {};
    payload['calling_sequence'] = 3;
    payload['one_fa_mode'] = fpData['1']?.one_fa_mode;
    payload['auth_type'] = fpData['1']?.auth_type;
    payload['request_id'] = fpData['1']?.request_id;
    payload['secret_question1'] = fpData['2']?.security_questions[0]?.question_id;
    payload['secret_question2'] = fpData['2']?.security_questions[1]?.question_id;
    payload['answer1'] = form?.value?.secretA1;
    payload['answer2'] = form?.value?.secretA2;
    this.apiService.postData(`${AppConstants.COMMON_PORTS.DISTRIBUTOR_V2}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.forgotPassword}`, payload)
    .pipe(tap(() => this.isLoading = false))
    .subscribe(res => {
      console.log(res)
      if (res && res.status) {
        this.toaster.success(res?.message);
        let obj = {};
        obj['secret_question1'] = fpData['2']?.security_questions[0]?.question_id;
        obj['secret_question2'] = fpData['2']?.security_questions[1]?.question_id;
        obj['answer1'] = form?.value?.secretA1;
        obj['answer2'] = form?.value?.secretA2;
        obj = Object.assign(obj);
        let temp = {3: obj};
        temp = Object.assign(temp, fpData);
        this.sessionStorage.setItem("forgotPasswordData", temp);
        this.router.navigateByUrl('/set-password');
      } else {
        if (res?.error_status?.error_code == '30002') {
          this.toaster.error(res?.message);
          this.sessionStorage.removeItem('forgotPasswordData');
          this.router.navigateByUrl('/forgot-password');
        } else if (res?.error_status?.error_code == '40003') {
          this.header.fpOpenInfoDialog('fpIncorrectAns');
        } else {
          this.toaster.error(res?.message);
        }
      }
    }, err => {
      try {
        if (err && err?.error?.error_status?.error_code == '11429') {
          this.header.fpOpenInfoDialog('fpManyAttempts');
        } else {
          this.toaster.error(err.error.message, 'Error');
        }
      } catch (e) {
        console.log("error", err);
      }
    })
  }

  openInfoDialog(key){
    this.header.fpOpenInfoDialog(key);
  }

}