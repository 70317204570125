export class RolesAndPermissions {
    public static readonly PERMISSIONS = [
        "rbac-module-list",
        "rbac-module-create",
        "rbac-module-update",
        "rbac-module-delete",
        "rbac-grant-retrieve",
        "rbac-grant-list",
        "rbac-grant-create",
        "rbac-grant-update",
        "rbac-grant-delete",
        "rbac-role-retrieve",
        "rbac-role-list",
        "rbac-role-create",
        "rbac-role-update",
        "rbac-role-delete",
        "rbac-user-permission-retrieve-self",
        "rbac-user-permission-list-self",
        "rbac-user-permission-create-self",
        "rbac-user-permission-update-self",
        "rbac-user-permission-delete-self",
        "rbac-user-permission-retrieve",
        "rbac-user-permission-list",
        "rbac-user-permission-create",
        "rbac-user-permission-update",
        "rbac-user-permission-delete",
        "rbac-permission-retrieve",
        "rbac-permission-list",
        "rbac-permission-create",
        "rbac-permission-update",
        "rbac-permission-delete",
        "rbac-module-grant-retrieve",
        "rbac-module-grant-list",
        "rbac-module-grant-create",
        "rbac-module-grant-update",
        "rbac-module-grant-delete",
        "rbac-grant-permission-retrieve",
        "rbac-grant-permission-list",
        "rbac-grant-permission-create",
        "rbac-grant-permission-update",
        "rbac-grant-permission-delete",
        "rbac-role-grant-retrieve",
        "rbac-role-grant-list",
        "rbac-role-grant-create",
        "rbac-role-grant-update",
        "rbac-role-grant-delete",
        "rbac-user-role-retrieve",
        "rbac-user-role-list",
        "rbac-user-role-create",
        "rbac-user-role-update",
        "rbac-user-role-delete",
        "rbac-endpoint-retrieve",
        "rbac-endpoint-list",
        "rbac-endpoint-create",
        "rbac-endpoint-update",
        "rbac-endpoint-delete",
        "rbac-module-retrieve",
        "rbac-user-retrieve",
        "rbac-user-list",
        "rbac-user-create",
        "rbac-user-update",
        "rbac-user-delete",
        "rbac-user-retrieve-self",
        "task-categories-list",
        "task-priorities-list",
        "task-status-list",
        "task-update",
        "task-delete",
        "task-update-status",
        "tasks-list",
        "task-create",
        "add-distributor",
        "approve-admin-document",
        "get-distributor-info",
        "list-active-distributor",
        "update-distributor",
        "deactivate-distributor",
        "list-contact-person",
        "save-contact-person",
        "update-contact-person",
        "admin-kyc-documents",
        "admin-kyc-document-detail",
        "get-distributor-id",
        "reject-kyc-document",
        "admin-list-states",
        "admin-list-states-for-country",
        "admin-update-documents",
        "get-user-documents",
        "get-admin-user-info",
        "update-admin-user-info",
        "update-admin-user-status",
        "delete-admin-user",
        "list-admin-users",
        "save-admin-users",
        "list-company-documents",
        "upload-company-documents",
        "list-lead-sources",
        "list-lead-statuses",
        "list-leads",
        "create-lead",
        "assign-lead",
        "lead-details",
        "lead-update",
        "update-lead-status",
        "send-message",
        "chats",
        "message-list"
    ]
}