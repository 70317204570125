<div class="main-container">
  <div class="login_form position-relative">
    <label (click)="goBack()" class="goBack"><img src="assets/images/leftArrow.svg" alt=""
        class="backArrowImage" /></label>
    <div class="logo-container">
      <!-- <img src="../../../assets/images/appretiate_logo.png" alt="" /> -->
      <!-- <img src="../../../assets/images/appreciateLogoHorizontal.png" alt="" /> -->
      <img src="../../../assets/images/logo.png" alt="logo" />
    </div>
    <hr />
    <h3 class="text-center">{{ "security_questions.admin_portal" | translate }}</h3>
    <form [formGroup]="secretQAForm" class="w-100" (submit)="verifySecretQA(secretQAForm)">
      <p class="text-indigo fw-800">{{ "security_questions.secret_questions" | translate }}
        <span class="ml-1 cursor-pointer" (click)="openInfoDialog('secretQA')"><img src="assets\images\info.svg" class="pb-1" alt="" /></span>
      </p>
      <div class="mb-3">
        <small class="fs-14 text-indigo">{{ "security_questions.please_answer_your_secret_questions_to_proceed" | translate }}</small>
      </div>
      <mat-form-field class="login_input">
        <mat-label>{{ "security_questions.secret_question" | translate }} 1 </mat-label>
        <input type="text" matInput placeholder="" formControlName="secretQ1" readonly [ngModel]="secretQADetails[0]?.question_text"/>
      </mat-form-field>
      <mat-form-field class="login_input">
        <mat-label>{{ "security_questions.secret_answer" | translate }} </mat-label>
        <input type="text" matInput placeholder="" formControlName="secretA1"/>
        <small *ngIf="
            form.secretA1.invalid &&
            form.secretA1.touched &&
            form.secretA1.errors.required
          " class="text-danger">{{ "security_questions.secret_answer" | translate }} 1 {{ "common.is_required" | translate }}</small>
        <small *ngIf="
            form.secretA1.invalid &&
            form.secretA1.touched &&
            form.secretA1.errors.pattern
          " class="text-danger">{{ "security_questions.please_enter_valid_answer" | translate }}</small>
      </mat-form-field>
      <mat-form-field class="login_input">
        <mat-label>{{ "security_questions.secret_question" | translate }} 2 </mat-label>
        <input type="text" matInput placeholder="" formControlName="secretQ2" readonly [ngModel]="secretQADetails[1]?.question_text"/>
      </mat-form-field>
      <mat-form-field class="login_input">
        <mat-label>{{ "security_questions.secret_answer" | translate }} </mat-label>
        <input type="text" matInput placeholder="" formControlName="secretA2"/>
        <small *ngIf="
            form.secretA2.invalid &&
            form.secretA2.touched &&
            form.secretA2.errors.required
          " class="text-danger">{{ "security_questions.secret_answer" | translate }} 2 {{ "common.is_required" | translate }}</small>
        <small *ngIf="
            form.secretA2.invalid &&
            form.secretA2.touched &&
            form.secretA2.errors.pattern
          " class="text-danger">{{ "security_questions.please_enter_valid_answer" | translate }}</small>
      </mat-form-field>
      <button [ngClass]="secretQAForm.valid ? 'active_btn' : 'disabled_div'" class="submit-btn" mat-raised-button
        color="primary">
        {{ "security_questions.confirm_and_next" | translate }}
      </button>
    </form>
  </div>
  <div></div>
</div>
