import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-common-loader',
  templateUrl: './common-loader.component.html',
  styleUrls: ['./common-loader.component.scss']
})
export class CommonLoaderComponent implements OnInit {


  options: AnimationOptions = {
    path:'/assets/animation_1.json',
  };
  styles: Partial<CSSStyleDeclaration> = {

  };
  constructor() { }

  ngOnInit() {
  }

  animationCreated(animationItem: AnimationItem): void {

  }

}
