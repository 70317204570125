<div style="max-width: 420px" *ngIf="data?.key !== 'existing_user'">
  <div class="d-flex justify-content-end">
    <span class="cursor-pointer" *ngIf="data?.key !== 'emailVerified' && data?.key !== 'wrong_resendOtp' && data?.key !== 'terms_acceptance' && data?.key !== 'passwordSet'" mat-dialog-close (click)="exit()">
      <img src="assets/images/Close_Square.svg" alt="" />
    </span>
  </div>
  <div class="modal-body p-0 text-center">
    <div>
      <img
        [src]="
            data?.imgSrc 
            ? 'assets/images/'+data?.imgSrc
            : data.imgSrc==='none'
            ? ''
            : declarations
            ? 'assets/images/yesb/logo.png'
            : data?.onboard
            ? 'assets/images/icon-why-PAN.svg'
            : data?.reupload
            ? 'assets/images/document_reupload.svg' 
            : data?.recall
            ? 'assets/images/50x.svg'
            : 'assets/images/announcement.svg'
        "
        alt=""
        width="100px"
      />
    </div>
    <div class="anouncement">
      <h3>{{ data?.header }}</h3>
      <p
        class="fs-12 mb-3"
        [innerHtml]="data?.message"
        *ngIf="data?.message !== 'yesb' && data?.message !== 'drivewealth' && data?.message !== 'yesbOD' && data?.message !== 'terms_acceptance'"
        [class]="data.from == 'goalcalculator' ? 'allignment' : ''"
        >
      </p>
      <div *ngIf="data?.message == 'yesbOD'" class="mb-3 fs-12 text-left" [style]="data?.message == 'yesbOD' ? 'max-height:500px;overflow-y:auto;' : ''">
        <ol class="pl-4 pr-2">
          <li>Customer understands that his personal / KYC details may be shared with 
            Central KYC Registry. The customer consents for receiving information for 
            Central KYC Registry through SMS/ Email on the registered number/email 
            address provided in this application or already registered with the Bank.</li>
          <li>The customer understands that the information requirements of this Form as per 
            the CBDT notified Rules 114F to 114H and hereby confirm that the information 
            provided on this Form are true, correct, and complete. The Customer further 
            confirms that he has read and understood the Terms and conditions as displayed 
            on www.yesbank.in and hereby accept the same. The Customer understand that 
            his/her personal details as provided/available in the bank records will be used for 
            CBDT reporting /Central KYC Registry.</li>
          <li>To be eligible to apply of online account opening offered by YES Bank Ltd (“YES 
            Bank” and/or “Bank”), Customer should be a citizen of India as per FEMA 
            guidelines & who is above 18 years. ”Customer” for the purpose of these terms 
            and conditions is the person who is applying/has applied for online account 
            opening at YES BANK.</li>
          <li>Customer, by providing his personal/demographic details, hereby agree for 
            opening an account with YES BANK LTD.</li>
          <li>Customer acknowledges that he/she has reviewed and verified the details 
            entered by him/her in the online application form and confirm the same to be 
            true, correct and updated and the reference number mentioned with respect to 
            the online application form has been generated by the Bank post his/her review, 
            verification and confirmation of the details filled in the application and submission 
            of the form.</li>
          <li>Customer undertake to inform the Bank of any changes in any of the details filled 
            in the online application form immediately on such change. In case any of the 
            above information is found to be false or untrue or misleading or misrepresenting, 
            Customer is aware that Customer may be held liable for it.</li>
          <li>Customer acknowledges that the account shall be opened in the same name as 
            appearing in the Aadhaar/PAN card of the Customer.</li>
          <li>Customer confirms that he/she has read, understood all the Terms & Conditions 
            as displayed on www.yesbank.in (hereinafter referred to as the ‘T&Cs’) which 
            govern/ shall govern, all of his/her accounts, present, past and future, 
            maintained/ opened/ to be maintained/ to be opened with the Bank from time to 
            time, and also the provisions of the various services/ facilities provided at 
            present/ that may be provided in future and agree to abide and be bound by all 
            the said terms and conditions. Customer understand that the Bank may, at its 
            sole discretion subject to applicable regulatory/ statutory/ internal guidelines, at 
            any time, and from time to time, add to, alter or modify any of the terms and 
            conditions of the T&Cs and that customer hereby agree to abide and be bound 
            by all such changes as if they form part of the T&Cs as at present and that any 
            transaction in his/her account(s) with the Bank and/ or usage of any services by 
            him/her subsequent to such change shall be deemed and tantamount to his/her 
            acceptance of all such changes.</li>
          <li>The Customer acknowledges that the Bank shall not be liable for any signal/ 
            data/connectivity issues leading to non-completion or wrong/false/incomplete 
            information being provided by the Customer leading to submission of incomplete 
            application or rejection of application nor shall the Bank be liable to make any 
            payments to the customer for the costs that he may incur/may have incurred (call 
            plan/data plan or any other charges) for completion of this online application 
            and/or the video call (Applicable for Video KYC account opening only).</li>
          <li>Customer understands and acknowledges that the Bank shall have the right to 
            reject the application if there is any erroneous or incorrect information provided 
            by the Customer or for any other reason including incomplete or unclear video 
            recording or for any other technical reason whatsoever.</li>
          <li>The Bank reserves to cancel the account opening reference number in the event 
            of failure of the Customer to complete the KYC in person or through Video call 
            within the stipulated time period, as the case may be.</li>
          <li>The Customer hereby agrees, declares and confirms, that the Bank shall have 
            the right to mark Debit freeze or close the Bank account/s of the Customer even 
            after account activation in case of any discrepancy found as part of regular 
            monitoring and document verification activities.</li>
          <li>Initial funding amount will be credited in the account only on activation of the 
            account with date of credit as date of activation. Incase where the account 
            cannot be activated, the initial funding amount will be refunded back to the 
            source account. The bank will not be liable to pay any interest on the same.</li>
          <li>The Customer hereby agree and confirm that, all the information provided by him 
            in the online Application Form (including personal & sensitive information) can be 
            shared by the Bank with its subsidiaries/associates/agencies/service providers 
            who have an agreement with the BANK for business purpose and on need to 
            know basis.</li>
          <li>The Customer hereby authorizes the Bank to debit his account opened/to be 
            opened with the Bank with any amount due and payable by him to the Bank in 
            relation to costs, charges, expenses, debts due under any / all facilities / services 
            granted by the Bank and set off or appropriate the balance in any of his accounts 
            with the amount due to the Bank.</li>
          <li>Standing Instructions, ECS mandates can be maintained on the account upon 
            submission of a request letter duly signed as per mandate. The request must be 
            submitted at the branch.</li>
          <li>The Customer understands that, International Transactions would be disabled if 
            Remittance limit as prescribed under Liberalized Remittance Scheme is 
            breached in a financial year (April-March)</li>
          <li>YES BANK shall always strive to comply with the rules and regulations as 
            applicable from time to time on this context in accordance with the Bank’s 
            Privacy policy. The Customer agrees that in the event he/she intends to revoke 
            the consent to the sharing of the data, the products/services available to the 
            Customer, pursuant to the consent provided earlier, shall no longer be available, 
            and the Customer would be required to initiate closure of such products/services 
            as per the terms and conditions applicable on such Products/ Services.</li>
          <li>Bank may disclose information about customer’s account, if required or permitted 
            by law, rule and regulations, or at the request of any public or regulatory authority 
            or if such disclosure is required for the purpose of preventing frauds, or in public 
            interest, without specific consent of the account holder. Please refer 
            www.yesbank.in for the complete Privacy Policy.</li>
          <li>Customer authorize the bank to access his/her CIBIL records, whenever needed 
            for any product offered by the bank.</li>
          <li>The customer herewith agrees that he has provided his/her valid proof of identity 
            document number, proof of address document number and valid PAN card 
            number voluntarily for the purpose of account opening.</li>
          <li>Customer give consent to the bank to fetch his/her demographic details from 
            Unique Identification Authority of India (UIDAI), using biometric/OTP 
            authentication which will be used for KYC purposes. The demographic details 
            include name, DOB, gender, address and photograph.</li>
          <li>The Customer understands that Chequebook services will not be available till the 
            signature is not updated in bank records. Customer further agrees that he shall 
            visit the Branch for updation of his signature and agrees to provide his identity 
            documents for the said purpose.</li>
          <li>The Customer understands that physical debit card will not be allotted at the time 
            of account opening and that he/she has to apply for physical debit card through 
            branch/digital channels once the account is activated.</li>
          <li>Customer declares and confirms that the bank shall be entitled to rely on all/any 
            communication, information and details provided on the electronic form and all 
            such communications, information and details shall be final and legally binding 
            on the customer.</li>
          <li>The customer herewith agrees to be contacted by the bank to receive information 
            in respect of account maintenance, alerts, payments due, updates on existing 
            and new products, servicing of account for sales, marketing or servicing their 
            relationship with YES Bank through telephone/Mobile/SMS/E-mail etc. Further 
            he/she understands that the consent to receive calls/communication shall be 
            valid and shall prevail over their current or any subsequent registration of their 
            mobile number for NDNC and shall continue to be treated as customer 
            consent/acceptance.</li>
          <li>Further, Customer is aware that the activation of his/her account will be 
            communicated to him/her through SMS.</li>
          <li><span class="fw-500"> In our endeavor to bring comprehensive financial solutions & superior experience 
            to you, YES BANK needs to contact customer over phone, SMS or Email with 
            latest innovations, exciting offers & matters relating to servicing his/her account. 
            Customer gives a consent on the same and on the below SMS/Email alerts:</span>
            <ul class="pl-3" style="list-style-type: lower-alpha;">
              <li>E-statements: E-statements for all accounts linked to the Customer ID of
                the 1st applicant will be sent on the email ID registered as per the Bank’s
                records.</li>
              <li>SMS and Email Alerts: Subscription to SMS & Email Alerts and
                subsequent modifications of the threshold limits can also be done through
                NetBanking.</li>
              <li> Default Alerts: Any Debit / Credit of Rs. 5,000 or above, Weekly Account
                Balance, Salary Credit & Overdraft Alerts will be communicated to A/c
                Holder(s). To set higher threshold amount/frequency please communicate
                the same to bank.</li>
              <li>YES BANK LIMITED will send SMS/ Email for your account on the mobile
                number & email ID details shared in the Customer Information Form. If
                customer wish to discontinue the services, please login to YES BANK
                NetBanking/ visit YES BANK branch.
              </li>
            </ul>
          </li>
          <li>Please Refer our Website www.yesbank.in or contact nearest branch for latest 
            schedule of charges applicable to savings accounts variants.</li>
          <li><span class="fw-500">Please find Basic guidelines for customer:</span>
            <ul class="pl-3" style="list-style-type: lower-alpha;">
              <li>The amounts can be deposited/withdrawn from these accounts by way of 
                Internet Banking/Mobile Banking</li>
                <li>Interest is presently paid on quarterly basis depending on daily closing 
                balance, provided it works out to minimum Re. 1/-. For details, refer to our 
                website www.yesbank.in</li>
                <li>No customer-initiated transactions routed through the account for a 
                continued period of 2 years shall be treated as a Dormant Account</li>
                <li>The account holder is required to maintain certain minimum Average 
                Balance in the account, as specified in the Schedules of Charges of the 
                Bank from time to time. Noncompliance of which would attract service 
                charges Average Balance is calculated as -Average of End of Day (EOD) 
                balances in the account for the concerned period/number of days in 
                concerned period</li>
                <li>In event of no Salary Credits in Salary Accounts for any continuous three 
                months, the Salary Account will be converted to a Savings Advantage 
                Account and the Minimum Average Balance requirement of Savings 
                Advantage Account will thereafter be applicable</li>
                <li>For availing passbook facility, please visit your home branch. Number of 
                past entries in Passbook/Statement may be provided upon customer 
                request.</li>
                <li>Net Relationship Value (NRV) is an aggregate value of your Deposits and 
                other relationships with YES BANK. For further details and calculation of 
                NRV, kindly refer Schedule of Charges.</li>
                <li>The Bank will notify the Account holder, 30 days in advance for any 
                changes in T&Cs and Schedule of Charges appropriately.</li>
                <li>The deposit kept in different branches of the YES BANK are aggregated 
                for the purpose of insurance cover and a maximum amount upto Rupees 5 
                Lakhs is paid (including either the principal on account or both principal 
                and accrued interest) under the scheme offered by Deposit Insurance and 
                Credit Guarantee Corporation of India (DICGC)</li>
                <li>All relevant policies including Code of Commitments to Customers and 
                Grievance Redressal Policy are available at Bank’s website 
                (WWW.YESBANK.IN) and at the Branches.</li>
                <li>Please refer to Schedule of Charges (SOC) for various charges and 
                facilities.</li>
                <li> While opening an account, the Bank shall satisfy itself about the address, 
                identity of a person (s) seeking to open an account, to assist in protecting 
                the prospective customer/s, members of the public and ourselves against 
                fraud and other misuses of the banking system and the customer hereby 
                agrees and consents to in-person verification or to initiate video call for 
                completion of the in-person verification.</li>
                <li>The customer hereby provides his consent to the Bank to obtain his 
                photograph in person OR during the video call for the purpose of 
                opening/operating the account</li>
                <li>Nomination can be done in favour of one person only.</li>
                <li>Nomination can be made, cancelled or varied by the account holder 
                anytime during his/her life time. While making nomination, cancellation or 
                variation, witness signatures are required only for cases where the 
                customer is providing thumb impression. Permanent Account Number 
                (PAN) or General Index Register Number (GIRN) of his father or mother or 
                Guardian as the case may be at the time of opening an account with the 
                Bank.</li>
                <li> Nomination can be made in favour of a minor also</li>
                <li>A copy of ‘YES BANK Citizen’s Charter’ and ‘Schedule of charges’ for key 
                information on the usage and benefits of various services/facilities offered 
                by the Bank is available on request and on Bank’s website. In case of any 
                complaint relating to features of any of the product, the Grievance 
                Redressal Cell within the bank can be approached for a resolution at, 
                yestouch@yesbank.in and if not resolved satisfactorily within 30 days the 
                Ombudsman appointed by the Reserve Bank of India in charge of the 
                region, may be approached.</li>
            </ul>
          </li>
        </ol>
      </div>
      <div
        class="fs-12 mb-3 text-left"
        *ngIf="data?.message == 'yesb' || data.message == 'drivewealth'">
        <h4 class="fw-500">Abiding rules and regulations</h4>
        <ol class="pl-2">
          <li>
            I agree to abide by Partner’ s Terms and Conditions and rules in
            force and the changes thereto in Terms and Conditions from time to
            time relating to my account as communicated and made available on
            Partner’s website/App.
          </li>
          <li>
            I agree that the opening and maintenance of the account is subject
            to rules and regulations introduced or amended from time to time by
            Partner and/or Partner Bank.
          </li>
          <li>
            I agree that Partner and/or Partner Bank before opening any deposit
            account, will carry out a due diligence as required under Know Your
            Customer guidelines of partner Bank. I would be required to submit
            necessary documents or proofs, such as identity, address, photograph
            and any such information to meet with KYC, AML or other
            statutory/regulatory requirements. Further, after the account is
            opened, in compliance with the extant regulatory guidelines, I agree
            to submit the above documents again at periodic intervals, as may be
            required by the Partner Bank.
          </li>
          <li>
            I agree that the Partner Bank may, at its discretion, engage the
            services of Business Facilitators (hereinafter referred to as BF)
            and Business Correspondents (hereinafter referred to as BC) for
            extension of banking and financial services so as to ensure greater
            financial and increasing the outreach of Partner Bank. Partner Bank,
            however, shall be responsible for the acts and omission of such BC
            and BF.
          </li>
        </ol>
      </div>
      <div *ngIf="data?.key == 'terms_acceptance'">
        <ul style="list-style-type: none;">
          <li class="mb-2" *ngFor="let term of data?.terms">
            <a class="text-blue" *ngIf="(term?.terms_content_type == 'link');else other_terms" href="{{term?.terms_text}}" target="_blank">{{term?.terms_title}}</a>
            <ng-template #other_terms>
              <span class="text-blue cursor-pointer" (click)="openInfoDialog(term)">{{term?.terms_title}}</span>
            </ng-template>
          </li>
        </ul>
        <div class="mt-2 mb-4 p-2 br-8" style="background-color: #E6EFFC;">
          <p class="mb-0">It is mandatory to accept these updates to continue using Appreciate. Please make sure to read through the updated document/s before you click accept. </p>
        </div>
      </div>
      <div *ngIf="data?.key == 'fpSendOtp'">
        <form [formGroup]="forgotPasswordForm" class="w-100" (submit)="sendOtp(forgotPasswordForm)">
          <mat-form-field class="mw-100 w-75 mb-4">
            <mat-label>Registered Phone</mat-label>
            <input type="text" matInput placeholder="Enter Registered Phone Number" formControlName="phone" mask="0000000000" />
            <small *ngIf="
                      form.phone.invalid &&
                      form.phone.touched &&
                      form.phone.errors.required
                    " class="text-danger">Phone number is required.</small>
            <small *ngIf="
                      form.phone.invalid &&
                      form.phone.touched &&
                      form.phone.errors.pattern
                    " class="text-danger">Please Enter valid phone number.</small>
            <small *ngIf="
                      form.phone.invalid &&
                      form.phone.touched &&
                      form.phone.errors.minLength &&
                      form.phone.errors.maxLength
                    " class="text-danger">Phone number should have 10 digits.</small>
          </mat-form-field>
          <button [ngClass]="forgotPasswordForm.valid ? 'active_btn' : 'disabled_div'" class="btn btn-primary custom-submit-button w-75" mat-raised-button
            color="primary">
            send me an OTP
          </button>
        </form>
      </div>
      <button
        class="btn btn-primary custom-okay-button w-75"
        mat-dialog-close
        (click)="close()"
        *ngIf="data?.key !== 'fpSendOtp'"
      >
        {{ data?.buttonText ? data?.buttonText : "okay" }}
      </button>
      <div class="mt-3">
        <p class="text-center text-blue cursor-pointer" *ngIf="data?.skipNominee"><span (click)="exit()">no, add now</span></p>
        <div *ngIf="data?.reupload || data?.recall || data?.noBranch">
          Have issues? <strong class="text-blue cursor-pointer" (click)="supportTicket()"> submit a support ticket </strong>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="max-width: 420px" *ngIf="data?.key === 'existing_user'">
  <div class="d-flex justify-content-end">
    <span class="cursor-pointer" mat-dialog-close (click)="exit()">
      <img src="assets/images/Close_Square.svg" alt="" />
    </span>
  </div>
  <div class="modal-body p-0">
    <p class="text-indigo fs-18 fw-700 mw-90">We have retrieved the following details from our system</p>
    <p class="text-indigo">You can now continue onboarding... </p>
    <div class="mt-5 status_div onboard_menu">
      <ul>
        <li class="done">
          <p class="fw-700 text-indigo mb-0"> Mobile No verified </p>
          <p class="fs-12 text-light">+91 {{ data?.config?.mobile_number | customMasking : "phone" }}  has been verified</p>
        </li>
        <li *ngIf="!data?.signComps?.security_questions_set">
          <p class="fw-700 text-indigo mb-0"> Secret Question verification </p>
          <p class="fs-12 text-light">You need to set your secret questions</p>
        </li>
        <li class="done" *ngIf="data?.signComps?.security_questions_set">
          <p class="fw-700 text-indigo mb-0"> Secret Question verified </p>
          <p class="fs-12 text-light">You have already set your secret questions</p>
        </li>
        <li *ngIf="!data?.signComps?.password_set">
          <p class="fw-700 text-indigo mb-0"> Password verification </p>
          <p class="fs-12 text-light">You need to set your password</p>
        </li>
        <li class="done" *ngIf="data?.signComps?.password_set">
          <p class="fw-700 text-indigo mb-0"> Password already set </p>
          <p class="fs-12 text-light">You have already set your password</p>
        </li>
        <li *ngIf="!data?.signComps?.email_verified">
          <p class="fw-700 text-indigo mb-0"> Email ID verification </p>
          <p class="fs-12 text-light">You need to verify your Email ID</p>
        </li>
        <li class="done" *ngIf="data?.signComps?.email_verified">
          <p class="fw-700 text-indigo mb-0"> Email ID verified </p>
          <p class="fs-12 text-light">{{ data?.config?.email | customMasking : "email" }}  has been verified</p>
        </li>
      </ul>
    </div>
    <div class="text-center mt-3">
      <button class="btn btn-primary custom-okay-button w-75" mat-dialog-close (click)="close()">continue onboarding</button>
    </div>
  </div>
</div>
