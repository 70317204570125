<div class="otp__container">
  <div class="w-100">
    <div class="onboarding_template text-center">
      <div class="close mt-2 pr-3" aria-label="Close" (click)="close()">
        <!-- <span aria-hidden="true">&times;</span> -->
        <img
          src="assets/images/Close_Square.svg"
          class="custom-cursor"
          alt=""
          role="button"
        />
      </div>

      <div class="page-header popup-sideline-inside m-2">
        <h2 class="modal-title m-0" id="modal-basic-title" mat-dialog-title>
          <span class="fs-20 pl-3">{{ data?.title }}</span>
        </h2>
      </div>
    </div>
    <div class="content forTransact mt-4 d-flex" mat-dialog-content>
      <div class="mt-3 mr-3 ml-3" style="margin-bottom: 50px; width: 319px">
        {{ data?.content }}
      </div>
    </div>

    <div class="" mat-dialog-actions>
      <div class="hr"></div>
      <div class="text-right justify-content-end actionButton">
        <button
          class="submit-btn mt-2 m-3 custom-text-blue text-center"
          mat-raised-button
          color="primary"
          (click)="onOk()"
        >
          {{ data?.successButton }}
        </button>
        <button
          type="button"
          mat-stroked-button
          class="submit-btn text-center"
          color="primary"
          (click)="onNo()"
        >
          {{ data?.cancelButton }}
        </button>
      </div>
    </div>
  </div>
</div>
