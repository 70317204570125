import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  OnInit
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app/app.constants';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HeaderProvider } from 'src/app/services/providers/headers';
import { CryptoProvider } from 'src/app/services/providers/crypto';
import { ListOfUsersService } from 'src/app/services/admin-apis/staff-management-apis/list-of-users/list-of-users.service';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { DistributorManagementService } from 'src/app/services/admin-apis/distributor-management/distributor-management.service';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { tap } from 'rxjs/operators';
import { menu } from './sidemenu'
import { environment } from 'src/environments/environment';

interface RootNavLink {
  title: string;
  link?: string;
  children?: RootChildren[] | [];
  permissions?: [],
  slugName?: string
}

interface RootChildren {
  title: string;
  link?: string;
  permissions?: [],
  slugName?: string
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  panelOpenState = false;
  role: string;
  loggedIn: boolean;
  closeResult = '';
  appConstants: any = AppConstants;
  userDetails: any = {};
  navBarList: any[] = [];
  roles: any = '';
  isAdmin: boolean = false;
  isStaff: boolean = false;
  logo_url = ""
  isLoading: boolean;
  hamClicked: string='show';
  mobHeight: any;
 mobWidth: any;
  visible: boolean;
  firsttime: boolean=true;
  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private _service: ListOfUsersService,
    private authService: AuthService,
    private route: Router,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private header: HeaderProvider,
    private crypto: CryptoProvider,
    private _storage: SessionStorageService,
    private disService: DistributorManagementService,
    private crudService: CrudService,
  ) {
    this.mobHeight = (window.screen.height) ;
    this.mobWidth = (window.screen.width);
    // debugger
  }

  async ngOnInit() {
    this.translate.setDefaultLang('en');

    if(this.mobWidth>=767){
      // debugger
      localStorage.setItem('hamburgerclicked', 'show');
    }
    else{
      localStorage.setItem('hamburgerclicked', 'hide');
    }
    let roles = localStorage.getItem('ROLES');
    this.userDetails['roles'] = this.crypto.decryptObj(roles);
    this.isAdmin = this.header.isAdmin();
    this.isStaff = this.header.distributorStaffStatus();
    if (!this.isAdmin) {
      this.getDistributorId();
    }

    this.navBarList = this._storage.getItemLocal('navBarList')

    let userRoles = this.userDetails['roles'];
    console.log(this.navBarList);


    if (this.header.isParentDistributor()) {
      var index = this.navBarList.findIndex(x => x.title.toLocaleLowerCase() == "sub distributors");
      let grantList=this._storage.getItemLocal('grantList')
      if(grantList.includes('subdistributor-fullaccess')){
      index === -1 ? this.navBarList.splice(3, 0, {
        title: 'Sub Distributors',
        link: '/distributors/list-of-subdistributors',
        acesss:[
          "subdistributor-fullaccess"
        ]
      }) : ''
    }
    
  }
  if(this.isAdmin){
    var index = this.navBarList.findIndex(x => x.title === "Profile");
    index != -1 ?'':
    this.navBarList.push(     {
      title: 'Profile',
      link: '/profile-details'
    })
    
  }
  if (!this.header.isAdmin()) {
    var index = this.navBarList.findIndex(x => x.title === "Profile");
    index != -1 ? this.navBarList[index].link = '/profile' : this.navBarList.push(
      {
        title: 'Profile',
        link: '/profile'
      },
    )
  }
  let userDetail = this.crypto.decryptObj(localStorage.getItem('USER_DETAILS'))
  console.log(userDetail);
    }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  open_permission(content_permission) {
    this.modalService
      .open(content_permission, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'sm',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {

    this.authService.logout().subscribe(
      (res: any) => {

        if (res && res['data']) {
          this.toaster.success(`${res['message']}`, 'Done');
          this.route.navigateByUrl('/login');
          localStorage.clear();
          sessionStorage.clear();
        }

      },
      (err) => {

        console.log('logout error', err);
        this.route.navigateByUrl('/login');
      }
    );

  }
  clicked(data) {

    if (environment.env != 'prod') {
      if (
        data == 'Securities configuration' ||
        data == 'Alerts' ||
        data == 'Fee and tax' ||
        data == 'Approval Queue'
      ) {
        this.toaster.info(this.translate.instant('ts_changes.coming_soon'));
      }
    }
  }

  getDistributorId() {
    let companyId = localStorage.getItem('distributorId');
    this.logo_url = this._storage.getItem('logo_url');
    if (!companyId || !this.logo_url) {
      this.isLoading = true
      this.crudService.getData(`${AppConstants.COMMON_PORTS.DISTRIBUTOR}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.id}`, this.crudService.params)
        .pipe(tap(() => this.isLoading = false))
        .subscribe(res => {
          // console.log(res)
          if (res && res?.data) {
            localStorage.setItem('distributorId', res?.data?.distributor_id);
            this._storage.setItem('logo_url', res?.data?.logo_url);
            this.logo_url = res?.data?.logo_url;
            console.log("company id", companyId, "logo url", this.logo_url);
          }
        }, err => {
          try {
          } catch (e) {
            console.log("error", err);
          }
        })
    }
  }
 loadmethod(){
  // debugger
  this.hamClicked=localStorage.getItem('hamburgerclicked')
  if(this.hamClicked=='show'){
    // debugger
   return true
  }
  else{
    return false
  }

 }
 hamburgerclick(){
  this.hamClicked=='show'?this.hamClicked='hide':this.hamClicked='show'
  localStorage.setItem('hamburgerclicked', this.hamClicked);

}
// checkvisible(){

//   if(this.mobWidth>=768){
//     this.visible=true
//   }
// }

}
