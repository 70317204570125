// Core imports
import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { Location } from '@angular/common';
// App imports
import { CryptoProvider } from './crypto';
import { RolesAndPermissions } from './roles-permissions';
import { SessionStorageService } from './customSessionStorage.service';
import { CrudService } from '../apis/Crud.service';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AnnouncementComponent } from 'src/app/pages/transact/transct-on-behalf-customer/shared/announcement/announcement.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable()

export class HeaderProvider {
  isLoading: boolean;
  constructor(
    private crypto: CryptoProvider,
    private location: Location,
    private _storage: SessionStorageService,
    private _service: CrudService,
    private http: HttpClient,
    private _dialog: MatDialog,
    private router: Router,
  ) { }
  /*
      Service is used to return basic header data.
      1. Fetch User data from local storage
      2. Decrypt the user data
      3. Fetch Authentication Token of a user
      4. Set and Return
          a. access token
          b. refresh token
  */
  fetchHeaders = () => {
    const USER_INFO = localStorage.getItem('USER_INFO');
    const USER_DETAILS = localStorage.getItem('USER_DETAILS')
    const ORIGINAL_USER_INFO = USER_INFO ? this.crypto.decryptObj(USER_INFO) : {};
    const ORIGINAL_USER_DETAILS = USER_DETAILS ? this.crypto.decryptObj(USER_DETAILS) : {}
    // console.log("original user info", ORIGINAL_USER_INFO)
    if (ORIGINAL_USER_INFO) {
      let tokens = {
        'ACCESS_TOKEN': '',
        'REFRESH_TOKEN': '',

        USER_DETAILS: ORIGINAL_USER_DETAILS,
      }
      if (ORIGINAL_USER_INFO && ORIGINAL_USER_INFO.access_token) {
        tokens['ACCESS_TOKEN'] = ORIGINAL_USER_INFO.access_token;
      }
      if (ORIGINAL_USER_INFO && ORIGINAL_USER_INFO.refresh_token) {
        tokens['REFRESH_TOKEN'] = ORIGINAL_USER_INFO.refresh_token;
      }
      return tokens;
    }
    return;
  }

  fetchEmail = () => {
    const USER_INFO = localStorage.getItem('USER_INFO');
    const ORIGINAL_USER_INFO = USER_INFO ? this.crypto.decryptObj(USER_INFO) : {};
    return {
      'Email': ORIGINAL_USER_INFO.email,
    };
  }

  fetchUserDetails = () => {
    const USER_INFO = localStorage.getItem('USER_INFO');
    let ORIGINAL_USER_INFO: any = USER_INFO ? this.crypto.decryptObj(USER_INFO) : {};
    return ORIGINAL_USER_INFO;
  }

  fetchPermissions() {
    let result = [];
    let permissionsFromLS = localStorage.getItem(AppConstants.LOCAL_STORAGE.PERMISSIONS);
    if (permissionsFromLS) {
      try {
        let permissionsData = this.crypto.decryptObj(permissionsFromLS), list = [];
        permissionsData.map((v, k) => {
          if (RolesAndPermissions.PERMISSIONS.indexOf(v.name)) {
            list.push(v.name);
          }
        })
        // console.log("permissions : ", list);
        result = list;
      } catch (e) {
        console.log("error while decrypting the permissions data from local storage", e);
      }
    }
    return result;
  }

  distributorStaffStatus() {
    let roles = localStorage.getItem('USER_DETAILS'),
      userRoles = [this.crypto.decryptObj(roles)];
    let roleIndex = userRoles.findIndex(v => v?.group.toLowerCase() === "distributor"  &&  v?.distributor_details?.role === 'agent');
    // console.log("role index", roleIndex, userRoles)
    if (roleIndex >= 0) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin() {
    // this.crypto.decryptObj(user);
    let roles = localStorage.getItem('USER_DETAILS'),
      userRoles = [this.crypto.decryptObj(roles)];
      console.log(userRoles,"USER ROLE");
      
    let roleIndex = userRoles.findIndex(v => v.group.toLowerCase() === "super admin" || v.group.toLowerCase() === "super user" || v.group.toLowerCase() === "admin" || v.group.toLowerCase() === "Super Admin");
    console.log("role index in testing", roleIndex, userRoles)
    if (roleIndex >= 0) {
      return true;
    } else {
      return false;
    }
  }

  isDistributor() {
    let roles = localStorage.getItem('USER_DETAILS'),
      userRoles = [this.crypto.decryptObj(roles)];
    let roleIndex = userRoles.findIndex(v => v.group.toLowerCase() === "distributor" && v.distributor_details.role != 'agent');
    console.log("role index in testing", roleIndex, userRoles)
    if (roleIndex >= 0) {
      return true;
    } else {
      return false;
    }
  }

  isParentDistributor() {
    let roles = localStorage.getItem('ROLES'),
      userDetails = localStorage.getItem('USER_DETAILS');
    let temp = this.crypto.decryptObj(userDetails)
    if (temp && temp['is_parent']) {
      return true;
    } else {
      return false;
    }
  }

  goBack() {
    this.location.back();
  }

  dobMax() {
    let date = new Date();
    return new Date(date.setFullYear(date.getFullYear() - 18));
  }

  rupeeConversion(value: any) {
    let usd_to_inr = this._storage.getItem('INDIAN_RUPEE');
    let indianCurreny = value * usd_to_inr;

    let val = Math.abs(indianCurreny);

    let result;
    if (val > 1000 && val <= 999999) {
      result = (val / 1000).toFixed(2) + ' ' + 'K';
    } else if (val > 999999 && val <= 999999999) {
      result = (val / 1000000).toFixed(2) + ' ' + 'M';
    } else if (val > 999999999 && val <= 999999999999) {
      result = (val / 1000000000).toFixed(2) + ' ' + 'B';
    } else if (val > 999999999999) {
      result = (val / 1000000000000).toFixed(2) + ' ' + 'T';
    } else {
      result = val.toLocaleString('en-In', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return result;
  }

  rupeeConversion_v2(value: any) {

    let val = Math.abs(value);
    let result;

    if (val > 999 && val <= 999999) {

      result = (val / 1000).toPrecision(3).includes('e') ? (val / 1000).toPrecision(3).split('e')[0] + ' ' + 'k' : (val / 1000).toFixed(2) + ' ' + 'k';
    } else if (val > 999999 && val <= 999999999) {
      result = (val / 1000000).toPrecision(3).split('e')[0] + ' ' + 'M';
    } else if (val > 999999999 && val <= 999999999999) {
      result = (val / 1000000000).toPrecision(3).split('e')[0] + ' ' + 'B';
    } else if (val > 999999999999 && val <= 999999999999999) {
      result = (val / 1000000000000).toPrecision(3).split('e')[0] + ' ' + 'T';
    }
    else if (val > 999999999999999 && val <= 999999999999999999) {
      result = (val / 1000000000000000).toPrecision(3).split('e')[0] + ' ' + 'P'
    }
    else if (val > 999999999999999999) {
      result = (val / 1000000000000000000).toPrecision(3).split('e')[0] + ' ' + 'E'
    } else {
      result = val.toLocaleString('en-In', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return result;
  }

  numberFormat(value, key?: any) {
    key = key ? key : 'US'
    return Number(value)?.toLocaleString(`en-${key}`, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  stockResponseModifier(watchlist, data, status?, usd_to_inr?) {
    return data.map((resMap: any) => {
      // console.log(resMap)

      let watchList;
      let percentage = null;
      let price = null;
      let color;
      if (watchlist) {
        let index = resMap && resMap._source ?
          watchlist.findIndex(item => item.appreciate_id == resMap._source.appreciate_id) :
          watchlist.findIndex(item => item.appreciate_id == resMap.appreciate_id);
        // console.log('the index', index);
        if (index == -1) {
          watchList = false
        } else {
          watchList = true
        }
      }

      let marketStatus = status ? status : (resMap && resMap?.values ? resMap?.values?.market_status : resMap?._source?.market_status)
      let cfNet = resMap?.values ? resMap?.values?.CF_NETCHNG : resMap?.price?.CF_NETCHNG
      let cfPct = resMap?.values ? resMap?.values?.PCTCHNG : resMap?.price?.PCTCHNG
      let temp = resMap && resMap?.values ? resMap.values : (resMap?._source ? resMap._source : '');
      let cfOpen = temp ? temp?.CF_OPEN : resMap?.price?.CF_OPEN;
      let cfClose = temp ? temp?.CF_CLOSE : resMap?.price?.CF_CLOSE;
      let prevClose1 = temp ? temp?.PREV_CLOSE_1 : resMap?.price?.PREV_CLOSE_1;
      let prevClose2 = temp ? temp?.PREV_CLOSE_2 : resMap?.price?.PREV_CLOSE_2;
      let cfLast = temp ? temp?.CF_LAST : resMap?.price?.CF_LAST
      let positive;
      console.log("*** test", prevClose1, prevClose2,price, marketStatus)
      if (marketStatus == "market_close") {
        // if (cfOpen != 0 && cfClose != 0) {
        //   price = cfClose - cfOpen;
        //   if (cfOpen > cfClose) {
        //     percentage = (cfOpen - cfClose) / cfOpen; // Red
        //     color = '#EA3636';
        //     positive = false;
        //     price = Math.abs(price);
        //   } else {
        //     percentage = (cfClose - cfOpen) / cfOpen; // Green
        //     color = '#00B871';
        //     positive = true;
        //   }
        // }
        if (prevClose1 != 0 && prevClose2 != 0) {
          price = prevClose1 - prevClose2;
          if (prevClose1 <= prevClose2) {
            percentage = (prevClose2 - prevClose1) / prevClose1; // Red
            color = '#EA3636';
            positive = false;
            price = Math.abs(price);
          } else {
            percentage = (prevClose1 - prevClose2) / prevClose1; // Green
            color = '#00B871';
            positive = true;
          }
        }
        console.log("*** test", prevClose1, prevClose2,price)
      } else {
        if (Math.abs(cfNet) && Math.abs(cfPct)) {
          if (cfNet < 0) {
            color = '#EA3636';
            positive = false;
          } else {
            color = '#00B871';
            positive = true;
          }
          price = Math.abs(cfNet);
          percentage = Math.abs(cfPct);
        } else {
          if (cfLast != 0 && cfClose != 0) {
            price = cfLast - cfClose;
            if (cfClose > cfLast) {
              percentage = (cfClose - cfLast) / cfClose; // Red
              color = '#EA3636';
              positive = false;
              price = Math.abs(price);
            } else {
              percentage = (cfLast - cfClose) / cfClose; // Green
              color = '#00B871';
              positive = true;
            }
          }
        }
      }

      marketStatus !== "market_close" && Math.abs(cfNet) && Math.abs(cfPct) ? percentage = Math.abs(percentage).toFixed(2) + '%' : (percentage = percentage ? (percentage * 100).toFixed(2) + '%' : null);
      price = price ? (Math.abs(price * usd_to_inr).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : null;
      return { ...resMap, stock_sign: positive, stock_price: price, color: color, percentage: percentage, watchList: watchList }
    });
  }

  stockPriceCalculator(data, marketStatus, usd_to_inr) {
    let cfNet = data?.values ? data?.values?.CF_NETCHNG : data?.price?.CF_NETCHNG
    let cfPct = data?.values ? data?.values?.PCTCHNG : data?.price?.PCTCHNG
    let cfOpen = data?.values ? data?.values?.CF_OPEN : data?.values?.CF_OPEN
    let cfClose = data?.values ? data?.values?.CF_CLOSE : data?.values?.CF_CLOSE
    let cfLast = data?.values ? data?.values?.CF_LAST : data?.values?.CF_LAST
    let prevClose1 = data?.values ? data?.values?.PREV_CLOSE_1 : data?.values?.PREV_CLOSE_1;
    let prevClose2 = data?.values ? data?.values?.PREV_CLOSE_2 : data?.values?.PREV_CLOSE_2;
    let positive, price, percentage, color;
    if (marketStatus == "market_close") {
      // if (cfOpen != 0 && cfClose != 0) {

      if (prevClose1 != 0 && prevClose2 != 0) {
        price = prevClose1 - prevClose2;
        if (prevClose1 <= prevClose2) {
          percentage = (prevClose2 - prevClose1) / prevClose1; // Red
          color = '#EA3636';
          positive = false;
          price = Math.abs(price);
        } else {
          percentage = (prevClose1 - prevClose2) / prevClose1; // Green
          color = '#00B871';
          positive = true;
        }
      }
    } else {
      if (Math.abs(cfNet) && Math.abs(cfPct)) {
        if (cfNet < 0) {
          color = '#EA3636';
          positive = false;
        } else {
          color = '#00B871';
          positive = true;
        }
        price = Math.abs(cfNet);
        percentage = Math.abs(cfPct);
      } else {
        if (cfLast != 0 && cfClose != 0) {
          price = cfLast - cfClose;
          if (cfClose > cfLast) {
            percentage = (cfClose - cfLast) / cfClose; // Red
            color = '#EA3636';
            positive = false;
            price = Math.abs(price);

          } else {
            percentage = (cfLast - cfClose) / cfClose; // Green
            color = '#00B871';
            positive = true;
          }
        }
      }
    }

    marketStatus !== "market_close" && Math.abs(cfNet) && Math.abs(cfPct) ? percentage = Math.abs(percentage).toFixed(2) : (percentage = percentage ? (percentage * 100).toFixed(2) : null);
    price = price ? (price * usd_to_inr) : null;
    return {
      percentage: percentage,
      priceDiff: price,
      color: color,
      positive: positive
    }
  }

  stockInvestedPriceCalculator(totalInvestments?, stockPrice?) {
    let positive, price, percentage, color;
    price = Math.abs(totalInvestments - stockPrice);
    percentage = (Math.abs(totalInvestments - stockPrice) / stockPrice)
    if (totalInvestments > stockPrice) {
      color = '#EA3636';
      positive = false;
    } else {
      color = '#00B871';
      positive = true;
    }
    percentage = Math.abs(percentage).toFixed(2);
    price = price ? (price) : null;
    return {
      percentage: percentage,
      priceDiff: price,
      color: color,
      positive: positive
    }
  }

  getDistributorId() {
    let companyId = localStorage.getItem('distributorId');
    let logo_url = this._storage.getItem('logo_url');
    if (!companyId) {
      this.isLoading = true
      this._service.getData(`${AppConstants.COMMON_PORTS.DISTRIBUTOR}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.id}`)
        .pipe(tap(() => this.isLoading = false))
        .subscribe(res => {
          // console.log(res)
          if (res && res?.data) {
            localStorage.setItem('distributorId', res?.data?.distributor_id);
            this._storage.setItem('logo_url', res?.data?.logo_url);
            companyId = res?.data?.distributor_id;
            logo_url = res?.data?.logo_url;
            console.log("company id", companyId, "logo url", logo_url);
          }
        }, err => {
          try {
            // this.toaster.error(err.error.message, 'Error')
          } catch (e) {
            console.log("error", err);
          }
        })
    }
    return { id: companyId, logo: logo_url }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          console.log("err location", err)
          reject(err);
        });
    });

  }
  getKycStatus(config) {
    // yes bank kyc statuses to show update kyc button and other banners
    let etbUser = config?.is_etb;
    let yesBankSteps = config['yes_bank_kyc'];
    let yesBankKyc: any = {};
    let signupComponents = config['sign_up'];
    let banner: any = {};
    yesBankSteps.map((v, k) => {
      v && v.doc_name ? yesBankKyc[v.doc_name] = v : '';
    })
    let dwArr = config['user_dw'];
    let dwStatus: any = {};
    dwArr.map((v, k) => {
      v && v.dw_component ? dwStatus[v.dw_component] = v : '';
    })
    let riskAssessment = signupComponents.filter(item => item.signup_component == "risk_assement_complete")[0]?.status
    // hide update kyc button if below statement is true
    if ((yesBankKyc && yesBankKyc?.vkyc?.status && yesBankKyc?.vkyc?.status_text == 'approved' && riskAssessment) || etbUser) {
      banner['showUpdateKycBtn'] = false;
    } else {
      banner['showUpdateKycBtn'] = true;
    }
    if ((yesBankKyc && yesBankKyc?.vkyc?.status && yesBankKyc?.vkyc?.status_text == 'approved')) {
      banner['transact'] = true;
    }
    // banners text
    if (yesBankKyc && yesBankKyc?.sa_creation && yesBankKyc?.sa_creation?.status) {
      let sa_creation_time = new Date(yesBankKyc?.nominee_details?.updated_at).getTime();
      let time_24_hours_from_now = new Date().getTime() + (1 * 24 * 60 * 60 * 1000);
      // console.log(sa_creation_time, time_24_hours_from_now, Math.abs(time_24_hours_from_now - sa_creation_time))
      if (yesBankKyc && yesBankKyc?.nominee_details && yesBankKyc?.nominee_details?.status && Math.abs(time_24_hours_from_now - sa_creation_time) <= 86400000) {
        banner['header'] = 'Account Creation Pending';
        banner['content'] = 'Your account creation is in progress. We will notify you once your account is ready.';
        banner['red'] = false;
        banner['key'] = 'sa_creation_inprogress';
        banner['transact'] = false;
      } else if (yesBankKyc && yesBankKyc?.vkyc?.status && yesBankKyc?.vkyc?.status_text == 'pending_approval') {
        banner['header'] = 'VKYC Pending';
        banner['content'] = 'Click to complete VKYC. Please ignore if VKYC already submitted.';
        banner['showUpdateKycBtn'] = true;
        banner['showBannerNextIcon'] = true;
        banner['key'] = 'pending_approval';
        banner['red'] = false;
        banner['transact'] = false;
      } else if ((yesBankKyc && yesBankKyc?.vkyc?.status && dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status) ||
        ((yesBankKyc && yesBankKyc?.vkyc?.status && dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status) && (dwStatus?.dw_kyc?.dw_status == 'KYC_NOT_READY' || dwStatus?.dw_kyc?.dw_status == 'KYC_DOC_REQUIRED' || dwStatus?.dw_kyc?.dw_status == 'KYC_DENIED')) ||
        ((yesBankKyc && yesBankKyc?.oauth?.status && dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status) && (dwStatus?.dw_kyc?.dw_status == 'KYC_NOT_READY' || dwStatus?.dw_kyc?.dw_status == 'KYC_DOC_REQUIRED' || dwStatus?.dw_kyc?.dw_status == 'KYC_DENIED')) ||
        (yesBankKyc && yesBankKyc?.oauth?.status && dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status)
      ) {
        banner['header'] = 'PAN Resubmission Required';
        banner['content'] = 'Click to resubmit PAN card for KYC verification.';
        banner['red'] = false;
        banner['pan_resubmit'] = true;
        banner['transact'] = false;
        banner['showBannerNextIcon'] = true;
      } else if (yesBankKyc && yesBankKyc?.vkyc?.status && yesBankKyc?.vkyc?.status_text == 'in_progress') {
        banner['header'] = 'Account Creation Pending';
        banner['content'] = 'Your account creation is in progress. We will notify you once your account is ready.';
        banner['red'] = false;
        banner['key'] = 'vkyc_inprogress';
        banner['transact'] = false;
      } else if (yesBankKyc && yesBankKyc?.vkyc?.status_text == 'rejected') {
        banner['header'] = 'VKYC Rejected';
        banner['content'] = 'There was a problem with your VKYC verification. Please visit the nearest YES Bank branch to complete VKYC.';
        banner['red'] = true;
        banner['showUpdateKycBtn'] = false;
        banner['transact'] = false;
      } else if ((yesBankKyc && yesBankKyc?.vkyc?.status && !dwStatus?.dw_user_creation?.status) ||
        (yesBankKyc && yesBankKyc?.vkyc?.status && dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status) ||
        (yesBankKyc && yesBankKyc?.oauth?.status && !dwStatus?.dw_user_creation?.status)
      ) {
        // broker account creation in progress
        banner['header'] = 'Account Creation Pending';
        banner['content'] = 'Your account creation is in progress. We will notify you once your account is ready.';
        banner['red'] = false;
        banner['key'] = 'dw_kyc_false';
        banner['transact'] = false;
      } else if ((yesBankKyc && yesBankKyc?.vkyc?.status && !dwStatus?.dw_user_creation?.status) ||
        (yesBankKyc && yesBankKyc?.vkyc?.status && dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status) ||
        (yesBankKyc && yesBankKyc?.oauth?.status && !dwStatus?.dw_user_creation?.status)
      ) {
        // broker account creation in progress
        banner['header'] = 'Account Creation Pending';
        banner['content'] = 'Your account creation is in progress. We will notify you once your account is ready.';
        banner['red'] = false;
        banner['key'] = 'dw_user_creation_false';
        banner['transact'] = false;
      } else {
        // banner['showUpdateKycBtn'] = false;
        // banner['transact'] = true;
      }
    } else {
      if (etbUser) {
        if (!yesBankKyc?.oauth?.status) {
          banner['header'] = 'Existing YES Bank account';
          banner['content'] = 'Looks like you already have a YES Bank account. Please download the Appreciate app on your android device and authenticate yourself via Net Banking login or Debit Card details to complete onboarding.';
          banner['false'] = true;
          banner['transact'] = false;
        } else {
          if (!dwStatus?.dw_user_creation?.status || 
              (dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status && dwStatus?.dw_kyc?.dw_status == null) ||
              (dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status && (dwStatus?.dw_kyc?.dw_status == 'KYC_PROCESSING' || dwStatus?.dw_kyc?.dw_status == 'KYC_MANUAL_REVIEW' || dwStatus?.dw_kyc?.dw_status == 'KYC_INFO_REQUIRED'))
          ) {
            banner['header'] = 'Account Creation Pending';
            banner['content'] = 'Your account creation is in progress. We will notify you once your account is ready.';
            banner['red'] = false;
            banner['key'] = 'sa_creation_inprogress';
            banner['transact'] = false;
          } else if ((dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status) || 
                      (dwStatus?.dw_user_creation?.status && !dwStatus?.dw_kyc?.status && (dwStatus?.dw_kyc?.dw_status == 'KYC_NOT_READY' || dwStatus?.dw_kyc?.dw_status == 'KYC_DOC_REQUIRED' || dwStatus?.dw_kyc?.dw_status == 'KYC_DENIED'))
                    ) {
            banner['header'] = 'PAN Resubmission Required';
            banner['content'] = 'Click to resubmit PAN card for KYC verification.';
            banner['red'] = false;
            banner['pan_resubmit'] = true;
            banner['transact'] = false;
            banner['showBannerNextIcon'] = true;
          } else {
            banner['header'] = 'Complete your KYC ';
            banner['content'] = 'Click here to complete';
            banner['transact'] = false;
            banner['showUpdateKycBtn'] = false;
            banner['showBannerNextIcon'] = true;
            banner['key'] = 'completeKyc';
          }
        }
      } else { 
        if (yesBankKyc?.sa_creation?.status_text == "pending") {
          banner['header'] = 'Complete your KYC ';
          banner['content'] = 'Click here to complete';
          banner['transact'] = false;
          banner['showUpdateKycBtn'] = false;
          banner['showBannerNextIcon'] = true;
          banner['key'] = 'completeKyc';
        } else if (yesBankKyc?.nominee_details?.status) {
          banner['header'] = 'Account Creation Pending';
          banner['content'] = 'Your account creation is in progress. We will notify you once your account is ready.';
          banner['red'] = false;
          banner['key'] = 'sa_creation_inprogress';
          banner['transact'] = false;
        }
      }
    }
    if (config && config?.appreciate_blocked || (dwStatus && dwStatus?.create_dw_accounts?.dw_status == 'CLOSED')) {
      banner['header'] = 'Account Blocked!';
      banner['content'] = 'Please click to reach out to us if you have questions.'
      banner['red'] = true;
      banner['showUpdateKycBtn'] = false;
      banner['showBannerNextIcon'] = true;
      banner['key'] = 'account_blocked';
      banner['transact'] = false;
    }
    if (dwStatus && dwStatus?.dw_user_creation?.dw_status == 'CLOSED') {
      banner['header'] = 'Account Closed!';
      banner['content'] = 'Please click to reach out to us for more details.'
      banner['red'] = true;
      banner['showUpdateKycBtn'] = false;
      banner['showBannerNextIcon'] = true;
      banner['key'] = 'account_closed';
      banner['transact'] = false;
    }
    if (dwStatus && dwStatus?.create_dw_accounts?.dw_status == 'OPEN_NO_NEW_TRADES') {
      banner['header'] = 'New Trades Restricted';
      banner['content'] = 'You cannot place new trades, you can only liquidate your account. Please click to reach out to us for more details.';
      banner['red'] = true;
      banner['showUpdateKycBtn'] = false;
      banner['showBannerNextIcon'] = true;
      banner['key'] = 'new_trades_restricted';
      banner['transact'] = true;
    }
    if (dwStatus && dwStatus?.dw_user_creation?.dw_status == 'REVOKED') {
      banner['header'] = 'Account Revoked';
      banner['content'] = 'Please click to reach out to us for more details.';
      banner['key'] = 'account_revoked';
      banner['transact'] = false;

    }
    return banner;
  }

  getTermsStatus(config) {
    let terms = config['terms_acceptance_details'];
    let content: any = [];
    terms.map((v, k) => {
      v && !v.status ? content.push(v) : '';
    })
    return content;
  }



  downloadFile(data, filename,type?) {
    let heading = Object.keys(data[0]);
    let csvData = this.ConvertToCSV(data, heading);
    if(type==='customer'){
     data.forEach( item => {delete item.user_id;});
     heading=Object.keys(data[0]);
     csvData = this.ConvertToCSV(data, heading);
     }
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }


  disableButtonSubject = new BehaviorSubject<boolean>(false);
  disableButton$ = this.disableButtonSubject.asObservable();

  // file validators
  fileTypeSizeValidator(file, allowedFileExtension, limit?){
    // file type and size validations
    let fileName: any = file?.name.toLowerCase();
    let fileType = fileName.substr(fileName?.lastIndexOf('.')+1);
    let fileSizeInMB = Number(file.size) / (1024*1024);
    // console.log("fileSizeInMB", fileSizeInMB)
    if (allowedFileExtension.indexOf(fileType) == -1 || fileSizeInMB > (limit ? limit : 2)) {
      let msg = allowedFileExtension.indexOf(fileType) == -1 ? `File type "${fileType}" is not allowed` : `File size should be less than ${limit ? limit : 2} MB`;
      return msg;
    }
    return null;
  }

  fpOpenInfoDialog(key, event?: any) {
    let header, message, buttonText, img;
    if (key === 'fpSendOtp') {
      header =  `Your email is not verified yet. Please authenticate using Phone OTP`;
      buttonText = 'send me an OTP';
    } else if (key == 'secretQA') {
      header = `Why secret questions?`;
      message = `This is an additional level of security to ensure that you are in total control of your account. Make sure this id personal to you and can’t be guessed by anyone.`;
    } else if (key == 'fpIncorrectAns') {
      header = `Incorrect answer!`;
      message = `The answer to your secret question was incorrect we would need to ask you a few more details before you can reset your password`;
      img = 'message_failed.svg';
      buttonText = 'contact support';
    } else if (key == 'fp2StepFailed') {
      header = `Two-step security failed`;
      message = `We need some additional information for you for your account's security.  Please provide these details to access your account.`;
      buttonText = 'retry';
      img = 'twoStepVerification.svg';
    } else if (key == 'fpManyAttempts') {
      header = `Too many attempts`;
      message = `You have been locked out please contact us`;
      buttonText = 'contact support'
      img = 'locked_out.svg';
    } else if (key == 'passwordSet') {
      header = `New password set!`;
      message = `Please sign-in again to access the Appreciate Admin Portal`;
      buttonText = 'sign in'
      img = 'password_set.svg';
    } 
    const dialog = this._dialog.open(AnnouncementComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        header: header,
        message: message,
        buttonText: buttonText,
        imgSrc: img ? img : null,
        key: key,
      }, disableClose: true
    })

    dialog.afterClosed().subscribe((res) => {
      console.log(res);
      if(key == 'passwordSet') {
        this.router.navigateByUrl('/login');
      }
      if (key == 'fpIncorrectAns' || key == 'fpManyAttempts') {
        this._storage.removeItem('forgotPasswordData');
        this.router.navigateByUrl('/forgot-password');
      }
    })
  }

}
