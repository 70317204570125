<div class="search-customers">
    <div class="w-100 p-3">
      <div class="page-header popup-sideline-inside">
        <h2 class="modal-title m-0" id="modal-basic-title">
          <span class="fs-20"> Search customers</span>
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <img src="assets/images/Close_Square.svg" alt="" />
        </button>
      </div>
      <div class="ml-4">
        <div>
          <mat-form-field class="search-box w-50" appearance="outline">
            <label matPrefix><img class="searchIcon" src="assets/images/search.svg" /></label>

            <input matInput placeholder=" Search with name or mobile or email" #input #filterCustomers class="px-2"/>
          </mat-form-field>
        </div>
      </div>
      <div class="modal-fornewchat" *ngIf="customers">
        <div class="w-100" *ngFor="let user of customers">
          <div class="colhover" *ngIf="user">
            <div class="newcard">
              <img [src]="user?.profile_image ? user?.profile_image : 'assets/images/blank-profile.svg'" width="44px" height="44px" class="mt-2"/>
              <span class="ml-2 mt-4">{{
                user?.full_name }} | Mobile:
                {{ user?.mobile | slice: 0:10 }}</span>
              <button mat-raised-button color="primary" class="text-center mt-3" (click)="transact(user)">
                <span class="text-center"> Transact</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center text-primary" *ngIf="!customers || !customers.length">
        Customer not found
      </div>
      <div class="text-center"></div>
    </div>
  </div>