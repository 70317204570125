import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/app.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HeaderProvider } from '../../providers/headers';

@Injectable({
  providedIn: 'root'
})
export class DistributorManagementService {
  // apiUrl = AppConstants.API_BASE_URL+':5021';
  // mainUrl = AppConstants.API_BASE_URL+':5003';
  // roleApiUrl = AppConstants.API_BASE_URL+':5017/rbac';
  apiUrl = AppConstants.WEB_APP_APIS.DEV[5021];
  mainUrl = AppConstants.WEB_APP_APIS.DEV[5003];
  roleApiUrl = AppConstants.WEB_APP_APIS.DEV[5017]+'/rbac';
  reqHeaders = {
    "cache-control": "no-cache",
    "accept": "application/json",
  };
  constructor(
    private http: HttpClient,
    private _header: HeaderProvider
    ) { }

  fetchCountries() {  
    const url: string = `${this.mainUrl}/v1/country/list/`;
    return this.http.get<any>(url).toPromise();
  } 

  fetchStates(country_id) {  
    const url: string = `${this.apiUrl}/v1/states/${country_id}/`;
    return this.http.get<any>(url).toPromise();
  }

  fetchDistributors(data,search) {  
    const url: string = `${this.apiUrl}/v1/distributor/?query=${search}`;
    return this.http.get<any>(url, {params: data}).toPromise();
  } 
  fetchSubDistributors(data) {  
    const url: string = `${this.apiUrl}/v1/sub-distributor/`;
    return this.http.get<any>(url, {params: data}).toPromise();
  } 

  fetchDistributorDetails(id) {
    const url: string = `${this.apiUrl}/v1/distributor-details/${id}/`;
    return this.http.get<any>(url).toPromise();
  } 

  fetchDistributorsDocs(data) {  
    const url: string = `${this.apiUrl}/v1/${data?.id}/company-details/`;
    return this.http.get<any>(url).toPromise();
  }

  fetchContactPerson(data) {  
    const url: string = `${this.apiUrl}/v1/distributor/${data?.id}/contact-person/`;
    return this.http.get<any>(url).toPromise();
  } 

  fetchContactPersonDocs(data?: any) {  
    let isAgent = this._header.distributorStaffStatus();
    let query = {}
    data && data.distributor_id ? query['distributor_id'] = true : '';
    data && data?.user_id ? query['user_id'] = data?.user_id : '';
    isAgent ? query['role'] = 'agent' : '';
    const url: string = `${this.apiUrl}/v1/user-documents/`;
    return this.http.get<any>(url, {params: query}).toPromise();
  }

  verifyDocument(data: FormData, userId){
    // let userId = data.get('user_id')
    const url: string = `${this.apiUrl}/v1/user-documents/${userId}/`;
    return this.http.post(url, data, {
      headers: this.reqHeaders
    }).toPromise();
  }

  updateDocument(data){
    const url: string = `${this.apiUrl}/v1/update/documents/`;
    return this.http.post(url, data, {
      headers: this.reqHeaders
    }).toPromise();
  }

  addDistributor(data){
    const url: string = `${this.apiUrl}/v1/distributor/`;
    return this.http.post(url, data, {
      headers: {
        "mimeType": "multipart/form-data",
        "Accept": "*/*",
      }
    }).toPromise();
  }

  addDistributorDocs(data, id){
    const url: string = `${this.apiUrl}/v1/${id}/company-details/`;
    return this.http.post(url, data, {
      headers: {
        "mimeType": "multipart/form-data",
        "Accept": "*/*",
      }
    }).toPromise();
  }

  editDistributor(data, id){
    const url: string = `${this.apiUrl}/v1/distributor/${id}/`;
    return this.http.put(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  deactivateDistributor(data){
    const url: string = `${this.apiUrl}/v1/distributor/${data?.id}/`;
    return this.http.patch(url, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  addDistributorContactDetails(data){
    const url: string = `${this.apiUrl}/v1/distributor/${data?.distributor_id}/contact-person/`;
    return this.http.post(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  addContactPerson(data){
    const url: string = `${this.apiUrl}/v1/users/`;
    return this.http.post(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }
  
  editContactPerson(data, userId){
    data['user_id'] = userId;
    this._header?.distributorStaffStatus() ? data['role'] = 'agent' : '';
    const url: string = `${this.apiUrl}/v1/user/`;
    return this.http.post(url, data, {
      headers: this.reqHeaders,
    }).toPromise();
  }

  getRoleByName(data) {
    const url = `${this.roleApiUrl}/roles/`
    return this.http.get(url, {params: data, headers: this.reqHeaders }).toPromise();
  }

  addRole(data) {
    const url = `${this.roleApiUrl}/users/${data.id}/`
    return this.http.put(url, data, { headers: this.reqHeaders }).toPromise();
  }

  fetchDistributorId() {  
    const url: string = `${this.apiUrl}/v1/get-distributor-id/`;
    return this.http.get<any>(url).toPromise();
  } 

  fetchStaff(userId?: any) {  
    let isAgent = this._header.distributorStaffStatus();
    let params = {}
    userId ? params['user_id'] = userId : '';
    isAgent ? params['role'] = 'agent' : '';
    const url: string = `${this.apiUrl}/v1/user/`;
    return this.http.get<any>(url, {params: params}).toPromise();
  } 

  fetchDistributorStaff(id) {
    const url: string = `${this.apiUrl}/v1/distributor/${id}/`;
    return this.http.get<any>(url).toPromise();
  }
}
