<div class="main-container">
  <div class="login_form">
    <div
      class="d-flex justify-content-center align-items-center"
      style="width: 100%"
    >
      <img src="assets/images/logo.png" alt="logo" width="100px" class="mr-2" />
      <img *ngIf="partnerLogo" [src]="partnerLogo" width="118px" class="ml-2" />
    </div>
    <hr />
    <select
      (change)="changedLang($event)"
      #langSelect
      (change)="translate.use(langSelect.value)"
    >
      <option
        *ngFor="let lang of translate.getLangs()"
        [value]="lang"
        [selected]="lang"
      >
        {{ lang }}
      </option>
    </select>
    <h3 class="text-center">{{ "login.header" | translate }}</h3>
    <p>{{ "login.welcomeMessage" | translate }}</p>
    <form [formGroup]="userForm" (submit)="login(userForm)" class="w-100">
      <mat-form-field>
        <mat-label>{{ "login.select_profile" | translate }}</mat-label>
        <mat-select formControlName="group" required>
          <mat-option value="ADMIN">{{
            "login.appreciate_team" | translate
          }}</mat-option>
          <mat-option value="DISTRIBUTOR">{{
            "login.distri_agent" | translate
          }}</mat-option>
        </mat-select>
        <small
          *ngIf="
            form.group.invalid &&
            form.group.touched &&
            form.group.errors.required
          "
          class="text-danger"
          >{{ "login.please_select_profile" | translate }}</small
        >
      </mat-form-field>
      <mat-form-field class="login_email" *ngIf="loginInput === 'email'">
        <mat-label>{{ "login.email" | translate }}</mat-label>
        <input
          type="email"
          matInput
          [placeholder]="'login.enter_email_address' | translate"
          formControlName="email"
        />
        <small
          *ngIf="
            form.email.invalid &&
            form.email.touched &&
            form.email.errors.required
          "
          class="text-danger"
          >{{ "login.emailRequired" | translate }}</small
        >
        <small
          *ngIf="
            form.email.invalid &&
            form.email.touched &&
            form.email.errors.pattern
          "
          class="text-danger"
          >{{ "login.email_adress_valid" | translate }}</small
        >
      </mat-form-field>
      <mat-form-field class="countryCode" *ngIf="loginInput === 'phone'">
        <mat-select
          class="countryCode"
          formControlName="countryCode"
          [ngModel]="
            generalData?.countries && generalData?.countries[0]
              ? generalData?.countries[0]?.country_phone_code
              : ''
          "
        >
          <mat-option
            *ngFor="let country of generalData?.countries"
            [value]="country.country_phone_code"
          >
            {{ country?.country_phone_code }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="login_phone" *ngIf="loginInput === 'phone'">
        <mat-label>{{ "login.phoneNum" | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="Enter Phone number"
          formControlName="phone"
          mask="0000000000"
        />
        <small
          *ngIf="
            form.phone.invalid &&
            form.phone.touched &&
            form.phone.errors.required
          "
          class="text-danger"
          >{{ "common.phone_number_required" | translate }}</small
        >
        <small
          *ngIf="
            form.phone.invalid &&
            form.phone.touched &&
            form.phone.errors.pattern
          "
          class="text-danger"
          >{{ "common.phone_number_invalid" | translate }}</small
        >
        <small
          *ngIf="
            form.phone.invalid &&
            form.phone.touched &&
            form.phone.errors.minLength &&
            form.phone.errors.maxLength
          "
          class="text-danger"
          >{{ "login.phone_number_should_have" | translate }}</small
        >
      </mat-form-field>
      <mat-form-field class="example-full-width login_password">
        <input
          matInput
          [placeholder]="'login.password' | translate"
          [type]="toggle.type"
          formControlName="password"
          required
        />
        <mat-pass-toggle-visibility
          #toggle
          matSuffix
        ></mat-pass-toggle-visibility>
        <small
          *ngIf="
            form.password.invalid &&
            form.password.touched &&
            !form.password.errors.pattern
          "
          class="text-danger"
          >{{ "login.password_field_required" | translate }}</small
        >
        <small
          *ngIf="
            form.password.invalid &&
            form.password.touched &&
            form.password.errors.pattern
          "
          class="text-danger"
          >{{ "login.please_enter_valid_password" | translate }}</small
        >
      </mat-form-field>
      <p class="text-left">
        <a
          [routerLink]="['/forgot-password']"
          class="login_passforgot"
          rel="noopener noreferrer"
          >{{ "login.forgotPass" | translate }}</a
        >
      </p>
      <button
        class="submit-btn"
        mat-raised-button
        color="primary"
        [ngClass]="{ active_btn: userForm.valid }"
      >
        {{ "login.signIn" | translate }}
      </button>
      <p class="text-left mt-3">
        <a href="javascript:void(0)" (click)="loginInputChange()">{{
          loginInput === "email"
            ? ("login.login_with_phone" | translate)
            : ("login.login_with_email" | translate)
        }}</a>
      </p>
    </form>
  </div>
  <div></div>
</div>
