<div class="main-div"[ngStyle]="{'display': loadmethod() ? 'block' : 'none' }">
  <img (click)="hamburgerclick()" class="hamburger p-2" src="assets/images/hamburger.svg" alt="" />
  <div class="text-center image-logo">
    
    <ng-container *ngIf="!logo_url || logo_url == 'null'">
      <img src="assets/images/path.png" alt="" />
      <h2 class="text-white">{{ "side_menu.appreciate" | translate }}</h2>
    </ng-container>
    <ng-container *ngIf="logo_url && logo_url != 'null'">
      <img [src]="logo_url" alt="" />
    </ng-container>
  </div>
  <div>
    <mat-accordion>
      <mat-expansion-panel class="no-dropdowns" *ngFor="let nav of navBarList">
        <mat-expansion-panel-header
          style="padding-right: 12px"
          [routerLink]="nav?.link"
          routerLinkActive="{{ nav?.link ? 'is-active' : '' }}"
          *ngIf="!nav?.hidden"
        >
          <mat-panel-title (click)="clicked(nav?.title)">
            {{
              nav?.title == "Dashboard"
                ? ("side_menu.dashboard" | translate)
                : nav?.title == "Reports"
                ? ("side_menu.reports" | translate)
                : nav?.title == "CMS"
                ? ("side_menu.cms" | translate)
                : nav?.title == "Distributor Management"
                ? ("side_menu.distributor_management" | translate)
                : nav?.title == "Customer Management"
                ? ("side_menu.customer_management" | translate)
                : nav?.title == "Money Flow"
                ? ("side_menu.money_flow" | translate)
                : nav?.title == "Rebalancing Monitoring"
                ? ("side_menu.rebalancing_monitoring" | translate)
                : nav?.title == "Order Monitoring"
                ? ("side_menu.order_monitoring" | translate)
                : nav?.title == "App configuration"
                ? ("side_menu.app_configuration" | translate)
                : nav?.title == "Lead Management"
                ? ("side_menu.lead_management" | translate)
                : nav?.title == "Submit a support ticket"
                ? ("side_menu.submit_a_support_ticket" | translate)
                : nav?.title == "Documents Verification"
                ? ("side_menu.documents_verification" | translate)
                : nav?.title == "Profile"
                ? ("side_menu.profile" | translate)
                : nav?.title == "Staff Management"
                ? ("side_menu.staff_management" | translate)
                : nav?.title
            }}
            <img
              class="arrow"
              *ngIf="nav?.children?.length"
              src="assets/images/arrowrightcircle.svg"
              alt=""
            />
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          [routerLink]="subNav?.link"
          routerLinkActive=" subNav?.title!='Approval Queue' ?is-active:!is-active"
          (click)="clicked(subNav?.title)"
          *ngFor="let subNav of nav?.children"
          >{{
            subNav?.title == "Category"
              ? ("side_menu.sub_menu.category" | translate)
              : subNav?.title == "Notifications"
              ? ("side_menu.sub_menu.notifications" | translate)
              : subNav?.title == "Posts"
              ? ("side_menu.sub_menu.posts" | translate)
              : subNav?.title == "List of Staff"
              ? ("side_menu.sub_menu.list_of_staffs" | translate)
              : subNav?.title == "List of Roles"
              ? ("side_menu.sub_menu.list_of_roles" | translate)
              : subNav?.title == "List of Distributors"
              ? ("side_menu.sub_menu.list_of_distributors" | translate)
              : subNav?.title == "Messages"
              ? ("side_menu.sub_menu.messages" | translate)
              : subNav?.title == "Distributor Tasks"
              ? ("side_menu.sub_menu.distributor_tasks" | translate)
              : subNav?.title == "Onboard Customer"
              ? ("side_menu.sub_menu.onboard_customer" | translate)
              : subNav?.title == "Transact on behalf of a customer"
              ? ("side_menu.sub_menu.tobc" | translate)
              : subNav?.title == "Cash Withdrawal"
              ? ("side_menu.sub_menu.cash_withdrawal" | translate)
              : subNav?.title == "Settlement"
              ? ("side_menu.sub_menu.settlement" | translate)
              : subNav?.title == "Fee"
              ? ("side_menu.sub_menu.fee" | translate)
              : subNav?.title == "Batch Withdrawal"
              ? ("side_menu.sub_menu.batch_withdrawal" | translate)
              : subNav?.title == "Risk assessment questionnaire"
              ? ("side_menu.sub_menu.risk_assessment_questionnaire" | translate)
              : subNav?.title == "Advance Task Configuration"
              ? ("side_menu.sub_menu.advance_task_configuration" | translate)
              : subNav?.title == "KYC Documents"
              ? ("side_menu.sub_menu.kyc_documents" | translate)
              : subNav?.title == "Staff Documents"
              ? ("side_menu.sub_menu.staff_documents" | translate)
              : subNav?.title == "Cash Deposit"
              ? ("side_menu.sub_menu.cash_deposit" | translate)
              : subNav?.title
          }}</a
        >
        <!-- <a
          [routerLink]="subNav?.link"
          routerLinkActive="is-active"
          *ngFor="let subNav of nav?.children"
          >{{ subNav?.title }}</a
        > -->
      </mat-expansion-panel>

      <mat-expansion-panel
        class="no-dropdowns"
        [ngStyle]="{ 'margin-bottom': !isAdmin ? '110px' : '0px' }"
      >
        <mat-expansion-panel-header
          (click)="logout()"
          style="padding-right: 12px"
        >
          <mat-panel-title>
            {{ "side_menu.logout" | translate }}
            <!-- <img class="arrow" src="assets/images/arrowrightcircle.svg" alt="" /> -->
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="!isAdmin" class="footer">
    <div class="d-flex justify-content-center">
      <div>
        <img src="assets/images/path.png" alt="" />
        <h3 class="text-white">{{ "side_menu.appreciate" | translate }}</h3>
      </div>

      <div>
        <img src="assets/images/samhitha-transparent.png" alt="" />
      </div>
    </div>
  </div>
</div>
