import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { AppConstants } from 'src/app/app.constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncementComponent } from 'src/app/pages/transact/transct-on-behalf-customer/shared/announcement/announcement.component';
import { HeaderProvider } from 'src/app/services/providers/headers';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  selectedInput: any;
  isLoading: boolean;
  emailNotVerified: boolean;
  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private toaster: ToastrService,
    private _dialog: MatDialog,
    private header: HeaderProvider,
    private apiService: CrudService,
    private sessionStorage: SessionStorageService
  ) { }
  forgotPasswordForm = this.formBuilder.group({
    // email: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.EMAIL)]],
    phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(AppConstants.REG_EXP.NUMBER)]],
  });
  defaultZone: any;
  lang: any;
  zones: any = [
    {
      value: 'en', viewValue: 'English'
    },
    {
      value: 'hin', viewValue: 'Hindi'
    }
  ]
  ngOnInit(): void {
    let zone: any
    if (!localStorage.getItem('langSelected')) {
      debugger;
      zone = 'en'
      this.lang = 'en'
      localStorage.setItem('langSelected', 'en')
    } else {
      zone = (localStorage.getItem('langSelected'))
      this.lang = zone
      debugger
    }
    this.defaultZone = zone
    this.translate.addLangs(['en', 'hin']);
    this.translate.setDefaultLang(zone);
    this.selectedInput = 'phone';
  }

  get form() {
    return this.forgotPasswordForm.controls;
  }

  emailOrMobile(event) {
    let val = event.value;
    this.forgotPasswordForm.reset();
    if (val == 1) {
      this.selectedInput = 'email'
      this.forgotPasswordForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.EMAIL)]],
        phone: [''],
        // password: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.PASSWORD)]]
      });
    } else {
      this.selectedInput = 'phone'
      this.forgotPasswordForm = this.formBuilder.group({
        email: [''],
        phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(AppConstants.REG_EXP.NUMBER)]],
        // password: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.PASSWORD)]]
      });
    }
    // console.log("selectedInput", this.selectedInput)
  }

  sendOtp(form: any) {
    // console.log("form", form, form.valid);
    if (form && form.valid) {
      let val = form.value, payload = {};
      if (val && val.phone) {
        payload['mobile_number'] = '+91' + val.phone;
        payload['email'] = ""
      }
      if (val && val.email) {
        payload['email'] = val.email;
        payload['mobile_number'] = ""
      }
      payload['calling_sequence'] = 1;
      if (this.selectedInput == 'email' && !this.emailNotVerified) {
        payload['one_fa_mode'] = val?.email;
        payload['auth_type'] = 'email';
      } else {
        payload['one_fa_mode'] = '+91' + val?.phone;
        payload['auth_type'] = 'sms';
      }
      this.emailNotVerified = false;
      this.apiService.postData(`${AppConstants.COMMON_PORTS.DISTRIBUTOR_V2}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.forgotPassword}`, payload)
        .pipe(tap(() => this.isLoading = false))
        .subscribe(res => {
          console.log(res)
          if (res && res.status) {
            this.toaster.success(res?.message);
            let obj = {};
            obj['one_fa_mode'] = payload['one_fa_mode'];
            obj['auth_type'] = payload['auth_type'];
            obj = Object.assign(obj, res.data)
            console.log("obj", obj)
            let temp = { 1: obj }
            this.sessionStorage.setItem("forgotPasswordData", temp);
            this.router.navigateByUrl('/otp');
          } else {
            if (res?.error_status?.error_code == '40002') {
              this.emailNotVerified = true;
              this.openInfoDialog('fpSendOtp');
            } else if (res?.error_status?.error_code == '40009') {
              this.openInfoDialog('40009',res?.message);
            } else
              if (res?.error_status?.error_code == '40010') {
                this.openInfoDialog('40010',res?.message);
              }
              else {
                this.toaster.error(res?.message);
              }
          }
        }, err => {
          try {
            if (err && err?.error?.error_status?.error_code == '11429') {
              this.header.fpOpenInfoDialog('fpManyAttempts');
            } else {
              this.toaster.error(err.error.message, 'Error');
            }
          } catch (e) {
            console.log("error", err);
          }
        })
      // this.router.navigate(['/send-otp']);
    }
  }

  goBack() {
    this.location.back();
  }

  openInfoDialog(key, msg?: any) {
    let header, message, buttonText, img;
    if (key === 'fpSendOtp') {
      header = `Your email is not verified yet. Please authenticate using Phone OTP`;
      buttonText = 'send me an OTP';
    } else if (key == 'secretQA') {
      header = `Why secret questions?`;
      message = `This is an additional level of security to ensure that you are in total control of your account. Make sure this id personal to you and can’t be guessed by anyone.`;
    } else if (key == 'fpIncorrectAns') {
      header = `Incorrect answer!`;
      message = `The answer to your secret question was incorrect we would need to ask you a few more details before you can reset your password`;
      img = 'message_failed.svg';
    } else if (key == 'fp2StepFailed') {
      header = `Two-step security failed`;
      message = `We need some additional information for you for your account's security.  Please provide these details to access your account.`;
      buttonText = 'retry';
      img = 'twoStepVerification.svg';
    } else if (key == 'fpManyAttempts') {
      header = `Too many attempts`;
      message = `You have been locked out please contact us`;
      buttonText = 'contact support'
      img = 'locked_out.svg';
    } else if (key == 'passwordSet') {
      header = `New password set!`;
      message = `Please sign-in again to access the Appreciate Admin Portal`;
      buttonText = 'sign in'
      img = 'password_set.svg';
    }
    else if (key == '40009') {
      header = `Onboarding Incomplete`;
      message = msg? msg:`You have not set security questions to retrieve password. Please contact for assistance.`;
      buttonText = 'contact support'
      img = 'onboardIncomplete.svg';
    } else if (key == '40010') {
      header = `Onboarding Incomplete`;
      message = msg? msg:`Please complete onboarding journey first.`;
      buttonText = 'contact support',
      img = 'onboardIncomplete.svg';
    }
    const dialog = this._dialog.open(AnnouncementComponent, {
      panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      data: {
        header: header,
        message: message,
        buttonText: buttonText,
        imgSrc: img ? img : null,
        key: key,
      }, disableClose: true
    })

    dialog.afterClosed().subscribe((res) => {
      console.log(res);
      if (key == 'fpSendOtp') {
        this.sendOtp(res);
      }
    })
  }
}
