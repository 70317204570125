import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { SetPasswordComponent } from './account/set-password/set-password.component';
import { OtpSendComponent } from './account/otp-send/otp-send.component';
import { SecurityQuestionComponent } from './account/security-question/security-question.component';
import { EmailOrNumberComponent } from './account/email-or-number/email-or-number.component';
import { SessionGuard } from './core/guard/auth.guard';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { CryptoProvider } from './services/providers/crypto';

const routes: Routes = [

  {
    path:"login",
    component:LoginComponent
  },
  {
    path:"set-password",
    component:SetPasswordComponent
  },
  {
    path:"forgot-password",
    component:ForgotPasswordComponent
  },
  {
    path:"otp",
    component:OtpSendComponent
  },
  {
    path:"email-or-number",
    component:EmailOrNumberComponent
  },

  {
    path:"security-question",
    component:SecurityQuestionComponent
  },
  {
    path: '',
    loadChildren:()=>
    import("./pages/pages.module").then(m=>m.PagesModule),
    canActivate:[SessionGuard],
   },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(   private crypto: CryptoProvider){
    console.log()
  }
}
