import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/app.constants';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	formDirective: FormGroupDirective;
	generalData: any = {};
	isLoading: boolean;
	loginInput: string = 'phone';
	partnerLogo: string = "";
	routeDatas: any = [{
		name: "samitha_cgf",
		icon: "assets/images/logo/Samhita New Final Logo_04-01.jpg"
	}];
	submitted: boolean = false;
	userForm = this.formBuilder.group({
		group: [''],
		countryCode: [''],
		phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(AppConstants.REG_EXP.NUMBER)]],
		password: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.PASSWORD)]]
	});
	defaultZone: any;
	lang: any;
	zones: any = [
		{
		  value: 'en', viewValue: 'English'
		},
		{
		  value: 'hin', viewValue: 'Hindi'
		}
	  ]
	selectedUserGroup: string;

	constructor(
		public translate: TranslateService,
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private apiService: CrudService,
		private router: Router,
		private toaster: ToastrService,
		private activatedRoute: ActivatedRoute,
	) {

   
	 }

	ngOnInit(): void {
		let zone: any
		if (!localStorage.getItem('langSelected')) {
			debugger;
			zone = 'en'
			this.lang = 'en'
			localStorage.setItem('langSelected', 'en')
		  } else {
			zone = (localStorage.getItem('langSelected'))
			this.lang = zone
			debugger
		  }
		  this.defaultZone = zone
		  this.translate.addLangs(['en', 'hin']);
		  this.translate.setDefaultLang(zone);
		this.activatedRoute.queryParams.subscribe((res) => {
			console.log(this.routeDatas);
			this.routeDatas.map((el) => {
				if (el.name == res.name) {
					this.partnerLogo = el.icon;
					return
				}
			});
		});
		this.fetchCountries();
	}

	changedLang(event){
		// 
		localStorage.setItem('langSelected', event.target.value)
		this.lang = event.target.value
		this.translate.use(event.target.value)
	}

	zoneChange(language: string) {
		localStorage.setItem('langSelected', language)
		this.translate.use(language);
	}

	/**
	 * Get all country code for mobile login
	 */
	fetchCountries() {
		this.isLoading = true
		this.apiService.getData(`${AppConstants.COMMON_PORTS.USER}${AppConstants.WEB_APP_APIS.USER.countryList}`)
			.pipe(tap(() => this.isLoading = false))
			.subscribe(res => {
				console.log(res)
				if (res && res.status) {
					this.generalData['countries'] = res.data;
				}
			}, err => {
				try {
					this.toaster.error(err.error.message, 'Error')
				} catch (e) {
					console.log("error", err);
				}
			})
	}

	get form() {
		return this.userForm.controls;
	}

	/**
	 * change login type from email or mobile number
	 * or
	 * vice versa
	 */
	loginInputChange() {
		this.userForm.reset();
		if (this.loginInput === 'phone') {
			this.loginInput = 'email'
			this.userForm = this.formBuilder.group({
				group: [''],
				email: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.EMAIL)]],
				password: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.PASSWORD)]]
			});
		} else {
			this.loginInput = 'phone'
			this.userForm = this.formBuilder.group({
				group: [''],
				countryCode: [''],
				phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(AppConstants.REG_EXP.NUMBER)]],
				password: ['', [Validators.required, Validators.pattern(AppConstants.REG_EXP.PASSWORD)]]
			});
		}
	}

	/**
	 * Login to application
	 * @param form email and password or mobile number and password
	 */
	login(form) {
		let val = form.value;
		console.log("form data", val);
		let data = {
			'password': val.password,
			'group': val.group
		}
		if (this.loginInput === 'phone') {
			if (val && val.countryCode) {
				data['username'] = val.countryCode + val.phone;
			}
			data['auth_type'] = 'phone';
		} else {
			data['username'] = val.email;
			data['auth_type'] = 'email';
		}
		sessionStorage.clear();
		localStorage.clear();
		this.authService.login(data).subscribe((res: any) => {
			if (res && res['data']) {
				if (res && res['data'] && res['data']['request_id']) {
					this.router.navigateByUrl('/otp', {
						state: {
							'requestId': res['data']['request_id'],
							'userName': data['username']
						}
					})
				}
			}
		}, (error) => {
			console.log("error", error)
			if (error || error.status == 401) {
				this.router.navigateByUrl('/login');
			}
		})
	}

	changeGroup(e) {
		// console.log("e", e?.value);
		if (e?.value == 'ADMIN') {
			this.selectedUserGroup = 'Admin';
		} else {
			this.selectedUserGroup = 'Distributor'
		}
	}

}

