import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, Route, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CryptoProvider } from 'src/app/services/providers/crypto';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/app.constants';
import { Location } from '@angular/common';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { menu as menus } from 'src/app/layout/navbar/sidemenu';
import { tap } from 'rxjs/operators';
import { HeaderProvider } from 'src/app/services/providers/headers';
@Component({
  selector: 'app-otp-send',
  templateUrl: './otp-send.component.html',
  styleUrls: ['./otp-send.component.scss'],
})
export class OtpSendComponent implements OnInit {
  activateButton: boolean = false;
  routeParams: any;
  loginInput: any;
  otp: number;
  otpVerified: boolean = false;
  public settings = {
    length: 6,
    numbersOnly: true,
    timer: 30,
    timerType: 0,
    btnClass: 'resend',
  };
  userForm = this.formBuilder.group({});
  @ViewChild('otpbox') input;
  isLoading: boolean;
  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private crypto: CryptoProvider,
    private _route: ActivatedRoute,
    private toaster: ToastrService,
    private loader: NgxUiLoaderService,
    private location: Location,
    private sessionStorage: SessionStorageService,
    private apiService: CrudService,
    private header: HeaderProvider,
  ) {
    this.routeParams = this.router.getCurrentNavigation().extras.state;
    console.log(this.routeParams);
    let routeParams = this.routeParams;
    console.log('route params', routeParams);
    let fpData = this.sessionStorage.getItem("forgotPasswordData");
    if (fpData && fpData['1'] && fpData['1'].request_id) {
      // routeParams['email'] ? (this.loginInput = routeParams['email']) : '';
      // routeParams['phone'] ? (this.loginInput = routeParams['phone']) : '';
      // this.settings['length'] = 6;
    } else {
      if (routeParams && routeParams['userName'] && routeParams['requestId']) {
        this.loginInput = routeParams['userName'];
      } else {
        this.router.navigate(['/login']);
      }
    }
    console.log('login input', this.loginInput);
  }

  public onInputChange(e) {
    this.activateButton = false;
    // console.log(e);
    if (e.length == this.settings.length) {
      // e will emit values entered as otp and,
      console.log('otp is', e);
      this.otp = e;
      this.activateButton = true;
    } else if (e == -1) {
      // if e == -1, timer has stopped
      console.log(e, 'resend button enables');
    } else if (e == -2) {
      this.otp = null;
      // e == -2, button click handle
      console.log('Resend code', this.otp);
      this.input.otpForm.reset();
      this.resendOtp();
    }
  }

  ngOnInit(): void {
    this.sessionStorage.removeItem('navBarList')
    this.sessionStorage.removeItem('grantList')
  }

  signin: FormGroup = new FormGroup({});

  get form() {
    return this.userForm.controls;
  }

  submitOtp(form: any) {
    let data = {};
    let routeParams = this.routeParams;
    let fpData = this.sessionStorage.getItem("forgotPasswordData");
    console.log("fp data", fpData)
    if (form && form.valid && this.activateButton) {
      if (fpData && fpData['1'] && fpData['1'].request_id) {
        let payload = {};
        payload['calling_sequence'] = 2;
        payload['request_id'] = fpData['1'].request_id;
        payload['one_fa_mode'] = fpData['1']?.one_fa_mode;
        payload['auth_type'] = fpData['1']?.auth_type;
        payload['otp'] = this.otp;
        this.apiService.postData(`${AppConstants.COMMON_PORTS.DISTRIBUTOR_V2}${AppConstants.WEB_APP_APIS.DISTRIBUTOR.forgotPassword}`, payload)
        .pipe(tap(() => this.isLoading = false))
        .subscribe(res => {
          console.log(res)
          if (res && res.status) {
            this.toaster.success(res?.message);
            let temp = {2: res?.data};
            temp = Object.assign(temp, fpData);
            this.sessionStorage.setItem("forgotPasswordData", temp);
            this.router.navigateByUrl('/security-question');
          } else {
            if (res?.error_status?.error_code == '30002') {
              this.toaster.error(res?.message);
              this.sessionStorage.removeItem('forgotPasswordData');
              this.router.navigateByUrl('/forgot-password');
            } else {
              this.toaster.error(res?.message);
            }
          }
        }, err => {
          try {
            if (err && err?.error?.error_status?.error_code == '11429') {
              this.header.fpOpenInfoDialog('fpManyAttempts');
            } else {
              this.toaster.error(err.error.message, 'Error');
            }
          } catch (e) {
            console.log("error", err);
          }
        })
      } else {
        data['otp'] = this.otp;
        data['calling_sequence'] = 2;
        data['mobile_number'] = "";
        data['email'] = "";
        data['group'] = "";
        if (routeParams && routeParams['requestId']) {
          data['request_id'] = routeParams.requestId;
        }
        this.authService.login(data).subscribe(
          (res: any) => {
            this.loader.stop();
            if (res && res['data']) {
              let userInfo = res.data;
              localStorage.setItem(
                'USER_INFO',
                this.crypto.encryptObj(userInfo)
              );
              this.toaster.success(this.translate.instant('ts_changes.user_otp_has_been_verified_successfully'), 'Done'
              );
              this.userRole(userInfo);
            }
          },
          (err) => {
            this.loader.stop();
            console.log('Error while verifying the OTP', err);
            this.toaster.error(err['error']['message'], 'Error');
          }
        );
      }
    } else {
      this.toaster.error(this.translate.instant('ts_changes.invalid_form'), 'Error');
    }
  }

  resendOtp() {
    let routeParams = this.routeParams, data = {};
    let fpData = this.sessionStorage.getItem("forgotPasswordData");
    if (routeParams && routeParams['requestId'] || (fpData && fpData['1'] && fpData['1']?.request_id)) {
      data['request_id'] = routeParams?.requestId || fpData['1']?.request_id;
      this.loader.start();
      this.authService.resendOtp(data).subscribe(
        (res: any) => {
          this.loader.stop();
          this.toaster.success(res?.message);
          if (res && res['status']) {
            let loginInputString = Number(this.loginInput)
              ? 'phone number'
              : 'email';
            this.toaster.success(
              this.translate.instant('ts_changes.link_send_successfully')` ${loginInputString}.`,
              'Done'
            );
          } else {
            this.toaster.error(res?.message);
            this.goBack();
          }
        },
        (err) => {
          this.loader.stop();
          console.log('Error while sending the OTP', err);
          this.toaster.error(err['error']['message'], 'Error');
        }
      );
    } else {
      this.toaster.error(
        AppConstants.API_RESPONSE_MESSAGES.SOMETHING_WENT_WRONG,
        AppConstants.TOASTER_STATUS.ERROR
      );
    }
  }

  userRole(userInfo) {
    this.authService.getSelfRoles(userInfo).subscribe(
      async (res: any) => {
        if (res && res.data && res.data.roles && res.data.roles.length > 0) {
          localStorage.setItem(
            AppConstants.LOCAL_STORAGE.ROLES,
            this.crypto.encryptObj(res.data.roles)
          );
          await this.getUserDetails(userInfo);
          // await this.getUserPermissions(userInfo);
        } else {
          this.redirectToLogin(
            '',
            AppConstants.API_RESPONSE_MESSAGES.ROLES.ROLES_NOT_FOUND
          );
        }
      },
      (error) => {
        this.redirectToLogin(error, '');
      }
    );
  }

  getUserDetails(userInfo) {
    let role = '';
    this.authService.userDetails(userInfo)
      .subscribe(
        async (res: any) => {
          this.loader.stop();
          try {
            if (res && res['data'] && res['data']['group']) {
              role = res.data.group;
              localStorage.setItem(
                'USER_DETAILS',
                this.crypto.encryptObj(res.data)
              );
              // localStorage.setItem('role', btoa(role));
              console.log("user Roles");
              // await this.getUserPermissions(userInfo);
              this.getUserConfig(userInfo);

            } else {
              this.toaster.error(
                this.translate.instant('ts_changes.you_are_not_an_authorised_user'),
                'Error'
              );
            }
          } catch (e) { }
        }, err => {
          this.loader.stop();
          console.log("error while fetching distributors", err);
        });
  }

  getUserPermissions(info) {
    console.log("user Permision");
    this.authService.getSelfPermissions(info).subscribe(
      (res: any) => {
        if (res && res.data && res.data.length > 0) {
          localStorage.setItem(
            AppConstants.LOCAL_STORAGE.PERMISSIONS,
            this.crypto.encryptObj(res.data)
          );
          console.log("user Permision");
          this.getUserConfig(info);
        } else {
          this.redirectToLogin(
            '',
            AppConstants.API_RESPONSE_MESSAGES.ROLES.PERMISSIONS_NOT_FOUND
          );
        }
      },
      (error) => {
        this.redirectToLogin(error, '');
      }
    );
  }

  getUserConfig(info) {
    this.getGrantList(info)


    // this.router.navigate(['/'], { relativeTo: this._route });


  }

  async getGrantList(info) {
    let menu = []
    menu = JSON.parse(JSON.stringify(menus))
    let url = `${AppConstants.COMMON_PORTS.ROLES}/grants/user_grants/`
    this.authService.getUserGrant(url, info).subscribe((res: any) => {

      let grantList = res?.data || [];

      let otp_enabled = res.data.filter(el => el.name.startsWith('TransactOnBehalfOfCustomerAdminAgent'))

      this.sessionStorage.setItemLocal('otp_enabled', otp_enabled?.length > 0 ? otp_enabled[0]?.otp_enabled : false)

      let apiList = grantList?.map(el => el?.menu_item).flat();
      this.sessionStorage.setItemLocal('grantList', apiList)

      menu.map(async (el) => {
        if (el?.children) {
          (el?.children as Array<any>)
            .map((e2) => {
              let found = e2?.access?.filter(e3 => apiList.includes(e3)) || []
              if (found?.length > 0) {
                console.log(e2.title, e2.permissions);
                el['enable'] = true;
                e2['enable'] = true;
              }
            });
        } else {
          let found = el?.access?.filter(e3 => apiList.includes(e3)) || []
          if (found?.length > 0) {
            el['enable'] = true;
          }
        }
      });
      let temp = []
      temp = menu.filter((el) => el['enable']);
      let tempt = temp.map((e) => {
        if (e?.children) {
          return (e.children = (e?.children as Array<any>).filter((e2) => e2['enable']));
        } else {
          return e;
        }
      });
      console.log(temp, "NEW GRANT", menu, tempt);
      temp.unshift({
        title: 'Dashboard',
        link: '/dashboard'
      })

      this.sessionStorage.setItemLocal('navBarList', temp)

      if (temp[0]?.children) {
        this.router.navigate([temp[0].children[0].link], { relativeTo: this._route });
      } else {
        this.router.navigate([temp[0].link], { relativeTo: this._route });
      }
    })    
  }


  redirectToLogin(error, msg) {
    this.userForm.reset();
    localStorage.removeItem('USER_INFO');
    if (error) {
      if (error && error.status == 401) {
        this.toaster.error(
          AppConstants.API_RESPONSE_MESSAGES.LOGIN.INVALID_DETAILS,
          AppConstants.TOASTER_STATUS.ERROR
        );
      }
    } else {
      this.toaster.error(msg, AppConstants.TOASTER_STATUS.ERROR);
    }
    this.router.navigateByUrl('/login');
  }

  goBack() {
    this.location.back();
  }
}
