import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AppConstants } from 'src/app/app.constants';
import { CustomAlertBoxComponent } from 'src/app/components/custom-alert-box/custom-alert-box.component';
import { CrudService } from 'src/app/services/apis/Crud.service';
import { CryptoProvider } from 'src/app/services/providers/crypto';
import { SessionStorageService } from 'src/app/services/providers/customSessionStorage.service';
import { HeaderProvider } from 'src/app/services/providers/headers';
import { AuthenticateOtpDialogComponent } from 'src/app/shared/dialog-components/authenticate-otp-dialog/authenticate-otp-dialog.component';

@Component({
  selector: 'app-search-customers',
  templateUrl: './search-customers.component.html',
  styleUrls: ['./search-customers.component.scss']
})

export class SearchCustomersComponent implements OnInit {
  customers: any;
  filterSubscription: Subscription;
  @ViewChild("filterCustomers") filterCustomers;
  isLoading: boolean;
  searchTerm: string;
  closeResult: string;
  userDetails: any;
  loggedIn: any;
  isAdmin: boolean;
  isDistributor: boolean;
  isAgent: boolean;
  constructor(
    private translate: TranslateService,
    private crypto: CryptoProvider,
    private _header: HeaderProvider,
    private route: Router,
    private modalService: NgbModal,
    private headerProvider: HeaderProvider,
    private apiService: CrudService,
    private router: Router,
    private _storage: SessionStorageService,
    private _dialog: MatDialog,
    private toaster: ToastrService,
    public dialogRef: MatDialogRef<SearchCustomersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.routeParams = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    let user = localStorage.getItem('USER_DETAILS');
    this.userDetails = this.crypto.decryptObj(user);
    this.loggedIn = this.crypto.decryptObj(localStorage.getItem('USER_INFO'));
    this.isAdmin = this.headerProvider.isAdmin();
    this.isDistributor = this.headerProvider.isDistributor();
    this.isAgent = this.headerProvider.distributorStaffStatus();
    this.fetchCustomers();
  }


  ngAfterViewInit() {
    try {
      this.filterSubscription = fromEvent(this.filterCustomers.nativeElement, "keyup")
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => (this.isLoading = true)),
          map((event: Event) => (<HTMLInputElement>event.target).value),
          map((val) => {
            console.log("val", val);
            this.searchTerm = val;
            this.fetchCustomers();
          })
        )
        .subscribe(() => {
          this.isLoading = false;
        });
    } catch (e) {
      console.log("err", e);
    }
  }

  ngOnDestroy() {
    if (this.filterSubscription) this.filterSubscription.unsubscribe();
    filterSubscription: Subscription;
  }

  close() {
    this.dialogRef.close()
  }

  fetchCustomers() {
    this.isLoading = true;
    let query = {};
    if (this.searchTerm) {
      query['search'] = this.searchTerm;

    }
    let disId = localStorage.getItem('distributorId');
    if (this.isDistributor) {
      query['role'] = 'distributor';
      query['distributor_company'] = disId;
    }
    if (this.isAgent) {
      query['role'] = 'staff';
    }
    let urlString = this.isAdmin ? 'allCustomers' : 'customers';
    this.apiService.getDataByQuery(`${AppConstants.COMMON_PORTS.USER_V4}${AppConstants.WEB_APP_APIS.USER[urlString]}`, query)
      .pipe(tap(() => this.isLoading = false))
      .subscribe(res => {
        // console.log("kyc list",res)
        if (res.status) {
          try {
            // this.toaster.success(res.message);
            let customers = res.data;
            this.customers = customers;
          } catch (e) {
            console.log("error", e);
          }
        }
      }, err => {
      })
  }

  transact(row) {
    let userDataFromApi = {}
    this.apiService.getData(`${AppConstants.COMMON_PORTS.USER}${AppConstants.WEB_APP_APIS.USER.customerConfig}?user_id=${row?.user_uuid}`).pipe(
      concatMap(res => {
        if (res.status) {
          userDataFromApi = res.data
          let signupComponents = userDataFromApi['sign_up'];
          let index = signupComponents.findIndex(x => x.signup_component === 'email_verified');
          if (!signupComponents[index]['status']) {
            this.toaster.error(this.translate.instant('ts_changes.email_id_is_not_verified_yet'), 'Error');
            return of(false)
          }
          else {
            if (this.isAdmin || this.isDistributor) {
              return of(true);
            } else {
              return this._dialog.open(AuthenticateOtpDialogComponent, {
                panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
                data: {
                  user_id: row?.user_uuid,
                  request_purpose: 'transact'
                },
                disableClose: true
              }).afterClosed()
            }
          }
        }
        else return of(false)
      }),
      // concatMap(otpRes => {
      //   if (otpRes) {
      //     if (userDataFromApi['yes_bank_kyc_status']) {
      //       return this._dialog.open(CommonSecurityQuestionComponent, {
      //         panelClass: ['custom-container', 'animate__animated', 'animate__fadeInDown', 'animate__fast'],
      //         data: { user_id: row?.user_uuid }
      //       }).afterClosed()
      //     }
      //     else {
      //       this.toaster.error('Please complete your KYC before proceeding further', AppConstants.TOASTER_STATUS.ERROR)
      //       return of(false)
      //     }
      //   }
      //   return of(false)
      // }),
      concatMap(res => {
        if (res) {
          return this.apiService.getData(`${AppConstants.COMMON_PORTS.USER}${AppConstants.WEB_APP_APIS.USER.customerDetails}?user_id=${row?.user_uuid}`)
        }
        return of(false)
      }),
    )
      .subscribe(res => {
        if (res) {
          console.log(res.data)
          this._storage.setItem('CUSTOMER_INFO', { user_id: row?.user_uuid, ...res.data })
          // this._storage.setItem('CUSTOMER_INFO', { user_id: row?.user_uuid })
          this.router.navigate(['/transact/transact-behalf-of-customer/select-product'])
        }
      })

    // this.apiService.getData(`${AppConstants.COMMON_PORTS.USER}${AppConstants.WEB_APP_APIS.USER.customerDetails}?user_id=${staff?.user_uuid}`).pipe(
    // )
    //   .subscribe(res => {
    //     if (res) {
    //       console.log(res.data)
    //       this._storage.setItem('CUSTOMER_INFO', { user_id: staff?.user_uuid, ...res.data })
    //       this.router.navigate(['/transact/transact-behalf-of-customer/select-product'])
    //     }
    //   })
    this.modalService.dismissAll()
    this.close();
    this.searchTerm = '';
  }

}
